<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid header-wrapper">
        <div class="navbar-wrapper">
            <div class=" navbar-toggle d-inline">
                <button class="navbar-toggler" type="button" (click)="sidebarToggle()">
                    <span   class=" navbar-toggler-bar bar1"></span>
                    <span   class=" navbar-toggler-bar bar2"></span>
                    <span   class=" navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle() | translate}}</a>
        </div>
      <button class="dropdown-mobile" aria-controls="collapseExample" aria-label="Toggle navigation" class=" navbar-toggler" (click)="dropdownToggle()"  type="button" aria-expanded="false">
        <span class=" navbar-toggler-bar navbar-kebab"></span>
        <span class=" navbar-toggler-bar navbar-kebab"></span>
        <span class=" navbar-toggler-bar navbar-kebab"></span>
      </button>
      <div class="header-partner" *ngIf="displayPartnerSection">
        {{currentPartner}}, {{"header.section_partner" | translate}}
      </div>
      <div class="collapse navbar-collapse justify-content-end {{ dropshow }}" >
            <ul class="navbar-nav">
              <li  class="nav-item {{ dropshow }} dropdown" >
                <a  aria-haspopup="true" class=" nav-link dropdown-toggle" (click)="dropdownToggle()" >
                  <div  class=" photo"><img  alt="Profile Photo" src="assets/img/anime3.png"></div>
                  <strong class="username" *ngIf="jwtUser">{{jwtUser}}</strong>
                  <b  class=" caret d-none d-lg-block d-xl-block"></b>
                </a>
                <ul  class="dropdown-navbar dropdown-menu"  x-placement="bottom-left">
                  <li  class=" nav-link" (click)="dropdownToggle()"><a  class="nav-item dropdown-item" routerLink="/profil/edit" > {{ 'header.profil' | translate }} </a></li>
                  <li  class=" nav-link" (click)="dropdownToggle()"><a  class="nav-item dropdown-item" routerLink="/profil/change-password" > {{'header.change_password' | translate}}</a></li>
                  <li  class=" dropdown-divider"></li>
                  <li  class=" nav-link"><a  class="nav-item dropdown-item" routerLink="/auth/login" (click)="logout()" >  {{'header.deconnect' | translate }} </a></li>

                </ul>
              </li>
            <li><select class="browser-default custom-select" style="color: black;" (click)="changeLanguage($event.target.value)"(change)="translate.use($event.target.value)" [value]="getLanguage()">
                <option value="fr" >FR</option>
                <option value="en">EN</option>
              </select></li>
            </ul>
        </div>
    </div>
</nav>
