<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon" style="min-height: 176px;" >
            <div class="card-icon">
              <span class="material-icons-outlined">info</span>
              <span class="card-category"> {{"list.titles.file_detail"|translate}}</span>
            </div>
            <div *ngIf="fileIinfo">
              <p class="card-category">{{"Nom"|translate}} : <b>{{fileIinfo.fileName}}</b></p>
              <p class="card-category" *ngIf="fileIinfo.status === true">{{"list.columns.statut"|translate}} : <b>{{"state.valid"|translate}}</b></p>
              <p class="card-category" *ngIf="fileIinfo.status === false">{{"list.columns.statut"|translate}} : <b>{{"state.no_valid"|translate}}</b></p>
              <p class="card-category" *ngIf="fileIinfo.status === null">{{"list.columns.statut"|translate}} : <b>{{"state.in_progress"|translate}}</b></p>
              <p class="card-category">{{"list.columns.loaded_in"|translate}} : <b>{{fileIinfo.statusChangedAt}}</b></p>
              <p class="card-category">{{"list.columns.loaded_by"|translate}} : <b>{{fileIinfo.statusChangedBy}}</b></p>
              <p class="card-category">{{"list.columns.loaded_in"|translate}} : <b>{{fileIinfo.loadedAt}}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-danger card-header-icon">
            <div class="card-icon">
              <span class="material-icons-outlined">check_circle</span>
              <span class="card-category"> {{'list.columns.valid_account'|translate}}</span>
            </div>
            <h3 class="card-title">{{validRec}}</h3>
          </div>
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <span class="material-icons-outlined">error_outline</span>
              <span class="card-category"> {{"list.columns.invalid_account"|translate}}</span>
            </div>
            <h3 class="card-title">{{invalidRec}}</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-danger card-header-icon">
            <div class="card-icon">
              <span class="material-icons-outlined">add_circle_outline</span>
              <span class="card-category"> {{'list.columns.added_account'|translate}}</span>
            </div>
            <h3 class="card-title">{{accountAdded}}</h3>
          </div>
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <span class="material-icons-outlined">remove_circle_outline</span>
              <span class="card-category"> {{"list.columns.deleted_account"|translate}}</span>
            </div>
            <h3 class="card-title">{{accountDeleted}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"list.titles.list_records"|translate}} </h5>
          </div>
          <div class=" card-body">
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" >
                <ng-container matColumnDef="errors">
                  <th mat-header-cell *matHeaderCellDef> {{"list.columns.detail"|translate}} </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" >
<!--                    <div  *ngIf="!element.hasError">-->
<!--                      <p><i  class="fa fa-circle" aria-hidden="true" style="color: #00b290" matTooltip="{{'state.valid'|translate}}"></i> {{"state.valid"|translate}}</p>-->
<!--                    </div>-->
                    <div *ngIf="element.hasError">
                      <p ><i  class="fa fa-circle" aria-hidden="true" style="color: #fb6340" matTooltip="{{'state.no_valid'|translate}}"></i> {{"state.no_valid"|translate}}</p>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.nom"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}">
                      {{element.firstName}}
                    </td>
                </ng-container>
                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.prenom"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}"  >
                        {{element.lastName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.mail"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" >
                        {{element.email}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.phone"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" > {{element.phone}} </td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.adress"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" > {{element.address}} </td>
                  </ng-container>
                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.city"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" > {{element.city}} </td>
                  </ng-container>
                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.country"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" > {{element.country}} </td>
                  </ng-container>
                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef> {{"forms.champs.opening_date"|translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'haveError': element.hasError}" > {{element.dueDate}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row  [matMenuTriggerFor]="menu"  *matRowDef="let row; columns: displayedColumns;" >
                  <mat-menu #menu="matMenu" xPosition="before">
                    <div style="margin: 5px" *ngIf="row.hasError">
                      <p *ngFor="let err of row.errors.error">
                        <i class="fa fa-exclamation-circle" aria-hidden="true" style="color: #fb6340" ></i> <b> {{err.message | translate}}</b>
                      </p>
                    </div>
                    <div style="margin: 5px" *ngIf="!row.hasError">
                      <p><i class="fa fa-check-circle" aria-hidden="true" style="color: #00b290" ></i> {{"state.valid"|translate}}</p>
                    </div>
                  </mat-menu>
                </tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
          <div class=" card-footer">
            <div align="end" *ngIf="fileIinfo">
              <button mat-raised-button color="warn" (click)="changeStatusOfLoading(false)" *ngIf="!fileIinfo.status">{{"list.button.give_up"|translate}}</button>
              <button mat-raised-button color="primary" (click)="changeStatusOfLoading(true)" *ngIf="invalidRec===0 && fileIinfo.status === null" >{{"forms.button.to_validate"|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
