import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  langue : string = sessionStorage.getItem('lang');
  languageChange: Subject<string> = new Subject<string>();
  constructor() {
    this.languageChange.subscribe((value) => {
      this.langue = value
    });
  }
   toggleLanguageChange(val: string) {
    this.languageChange.next(val);
   }
}
