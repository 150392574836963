<div class="alert alert-warning" *ngFor="let item of alertList" >
    <span class="context" *ngIf="item.content != 'notifications.alert.you_have_received_request_to_be_trusted_third_party_from'
     && item.content != 'notifications.alert.trusted_third_party_requested_the_communication_list_of_your_accounts'">
      <b>{{"notifications.alert.title" | translate}}</b> - {{item.content | translate}}
    </span>

  <span class="context" *ngIf="item.content == 'notifications.alert.you_have_received_request_to_be_trusted_third_party_from'">
      <b>{{"notifications.alert.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{currentUser}",  item.params.currentUser)'></span>
    </span>
  <span class="context" *ngIf="item.content == 'notifications.alert.trusted_third_party_requested_the_communication_list_of_your_accounts'">
      <b>{{"notifications.alert.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{trustedTp}",  item.params.currentUser)'></span>
    </span>
</div>
<div class="alert alert-success" *ngFor="let item of notificationList" >
    <i class="material-icons close" data-dismiss="alert" aria-label="Close" (click)="updateNotification(item.id)" >close</i>
    <span class="context" *ngIf="item.content == 'notifications.info.list_of_your_accounts_is_available_at'">
      <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{dateDispo}",  item.params.dateDispo)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.first_trustedtp_downloaded_file_of_account'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{trustedTp}",  item.params.trustedTp).replace("{date}", item.params.date)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.second_trustedtp_of_user_downloaded_file_of_account'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{user}",  item.params.user).replace("{date}", item.params.date)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.list_account_is_deleted_after_auto_expire_delay'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{autoExpireInterval}",  item.params.autoExpireInterval).replace("{currentUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.you_are_no_longer_a_trusted_third_party_to'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{currentUser}",  item.params.currentUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.you_are_no_longer_trusted_second_party_for'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{currentUser}",  item.params.currentUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.our_partner_company_who_offers_you_3wlife_services'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{company}",  item.params.company)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.list_of_your_relatives_accounts_is_available_at'">
      <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{dateDispo}",  item.params.dateDispo).replace("{currentUser}",  item.params.currentUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.you_have_confirmed_your_agreement_to_make_acount_list_of_available'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{forUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.your_relative_refused_request_to_make_list_accounts_available'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{currentUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.the_second_trusted_third_party_refused_your_request'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{trustedTp}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.you_are_refused_request_to_make_list_accounts_of_available'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{forUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.your_trustedtp_refused_request_to_make_list_accounts_available'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{trustedTp}",  item.params.currentUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.request_to_make_relatives_accounts_list_available_has_been_cancled_by_trustedtp_who_initiated_request'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{forUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.you_have_cancled_your_request_to_make_list_of_your_relatives_available'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{forUser}",  item.params.forUser)'></span>
    </span>
    <span class="context" *ngIf="item.content == 'notifications.info.is_no_longer_your_trustedtp_we_recommend_to_appoint_a_new_one'">
        <b>{{"notifications.info.title" | translate}}</b> - <span [innerHTML]='(item.content | translate).replace("{trustedtp}",  item.params.trustedtp)'></span>
    </span>
    <span class="context" *ngIf="item.content != 'notifications.info.list_of_your_accounts_is_available_at'
     && item.content != 'notifications.info.first_trustedtp_downloaded_file_of_account'
     && item.content != 'notifications.info.second_trustedtp_of_user_downloaded_file_of_account'
     && item.content != 'notifications.info.list_account_is_deleted_after_auto_expire_delay'
     && item.content != 'notifications.info.you_are_no_longer_a_trusted_third_party_to'
     && item.content != 'notifications.info.you_are_no_longer_trusted_second_party_for'
     && item.content != 'notifications.info.our_partner_company_who_offers_you_3wlife_services'
     && item.content != 'notifications.info.list_of_your_relatives_accounts_is_available_at'
     && item.content != 'notifications.info.you_have_confirmed_your_agreement_to_make_acount_list_of_available'
     && item.content != 'notifications.info.your_relative_refused_request_to_make_list_accounts_available'
     && item.content != 'notifications.info.the_second_trusted_third_party_refused_your_request'
     && item.content != 'notifications.info.you_are_refused_request_to_make_list_accounts_of_available'
     && item.content != 'notifications.info.your_trustedtp_refused_request_to_make_list_accounts_available'
     && item.content != 'notifications.info.request_to_make_relatives_accounts_list_available_has_been_cancled_by_trustedtp_who_initiated_request'
     && item.content != 'notifications.info.you_have_cancled_your_request_to_make_list_of_your_relatives_available'
     && item.content != 'notifications.info.is_no_longer_your_trustedtp_we_recommend_to_appoint_a_new_one'">
        <b>{{"notifications.info.title" | translate}}</b> - {{item.content | translate}}
    </span>
</div>

