<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-body">
            <mat-horizontal-stepper  #stepper [linear]="true">
              <mat-step [stepControl]="checkContractForm">
                <ng-template matStepLabel>{{"forms.steps.contract_verification" |translate}}</ng-template>
                <form [formGroup]="firstFormGroup" >
                  <div class="row" *ngIf="contract" style="margin-bottom: 25px;">
                    <div class="col-md-6">
                      <div class="full-width">
                        <div style="margin: 5px 0">
                          <div class="form-label">{{'forms.champs.group'|translate}} :</div>
                          <i>{{ contract ? contract.group : '' }}</i>
                        </div>
                      </div>
                      <div class="full-width">
                        <div style="margin: 5px 0">
                          <div class="form-label">{{'forms.champs.company'|translate}} :</div>
                          <i>{{ contract ? contract.company : '' }}</i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="full-width">
                        <div style="margin: 5px 0">
                          <div class="form-label">{{"forms.champs.contract_code"|translate}} :</div>
                          <i>{{ contract ? contract.codeContract : ''}}</i>
                        </div>
                      </div>
                      <div class="full-width">
                        <div style="margin: 5px 0">
                          <div class="form-label">{{"forms.champs.label"|translate}} :</div>
                          <i>{{ contract ? contract.label : ''}}</i>
                        </div>
                      </div>
                      <div class="full-width">
                        <div style="margin: 5px 0">
                          <div class="form-label">{{"forms.champs.criticality"|translate}} : </div>
                          <i>{{ contract ? contract.criticality : '' }}</i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="!contract">
                    <div class="col-md-6">
                      <mat-form-field class="full-width">
                        <input matInput placeholder='{{"forms.champs.group"|translate}}' formControlName="group" required >
                        <mat-hint *ngIf="firstFormGroup.controls.group.dirty && firstFormGroup.controls.group.hasError('required')">
                          <p class="red-text">{{"forms.alerte.group_is"|translate}} <strong>{{"forms.alerte.required" |translate}}</strong></p>
                        </mat-hint>
                      </mat-form-field>
                      <mat-form-field class="full-width">
                        <input matInput placeholder='{{"forms.champs.company"|translate}}' formControlName="company" required >
                        <mat-hint *ngIf="firstFormGroup.controls.company.dirty && firstFormGroup.controls.company.hasError('required')">
                          <p class="red-text">{{"forms.alerte.company_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="full-width">
                        <input matInput placeholder='{{"forms.champs.contract_code"|translate}}' formControlName="code_contract" required >
                        <mat-hint *ngIf="firstFormGroup.controls.code_contract.dirty && firstFormGroup.controls.code_contract.hasError('required')">
                          <p class="red-text">{{"forms.alerte.contract_code_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                        </mat-hint>
                      </mat-form-field>
                      <mat-form-field class="full-width">
                        <input matInput placeholder='{{"forms.champs.password"|translate}}' [type]="hide ? 'password' : 'text'" formControlName="secret_contract" required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint *ngIf="firstFormGroup.controls.secret_contract.dirty && firstFormGroup.controls.secret_contract.hasError('required')">
                          <p class="red-text">{{"forms.alerte.password_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
                <div align="end">
                  <button mat-raised-button color="warn" (click)="logoutContract(stepper)" *ngIf="contract" >{{"forms.button.logout"|translate}}</button>
                  <button mat-raised-button color="basic" matStepperNext *ngIf="contract">{{"forms.button.contract_management"|translate}}</button>
                  <button mat-raised-button color="primary" (click)="verifyContract(stepper)" *ngIf="!contract">{{"forms.button.login"|translate}}</button>
                </div>
              </mat-step>
              <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>{{"forms.steps.loading_file.title_step"|translate}}</ng-template>
                <form [formGroup]="secondFormGroup" >
                  <div class="row">
                    <div class="col-md-6 ">
                      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                                     (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
                                     multiple="false" >
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                          <div class="upload-box">
                            <i class="fa fa-upload" aria-hidden="true" style="font-size: 50px;" (click)="openFileSelector()" ></i>
                            <p class="pointer-none" *ngIf="!relativePath"><b>{{"forms.steps.loading_file.slide"|translate}}</b> {{"forms.steps.loading_file.file_csv_here"|translate}} <br /> {{"forms.steps.loading_file.or"|translate}} <a (click)="openFileSelector()">{{"forms.steps.loading_file.browse"|translate}}</a> {{"forms.steps.loading_file.to_start_loading"|translate}}</p>
                            <p class="pointer-none" *ngIf="relativePath" style=" font-size: 11px ;">{{relativePath}}</p>
                          </div>
                        </ng-template>
                      </ngx-file-drop>
                      <br>
                      <mat-form-field *ngIf="contract? contract.criticality == 1 : true">
                        <textarea matInput
                        class="textarea"
                           placeholder='{{"forms.champs.description_contract_partner"| translate}}'
                           [formControl]="commentFC"
                           (keyup)="autoGrowTextZone($event)"
                           formControlName="description"
                           >
                        </textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 ">
                      <div class="upload-box" style="padding: 10px">
                        <i class="fa fa-download" aria-hidden="true" style="font-size: 50px;" (click)="downloadFile()" ></i>
                        <p class="pointer-none"> {{"forms.click_her_for"|translate }}  <a (click)="downloadFile()"> {{"forms.steps.loading_file.download"|translate }} </a><br /> {{"forms.steps.loading_file.last_update"|translate }}</p>
                      </div>
                    </div>
                  </div>
                </form>
                <div align="end">
                  <button mat-raised-button color="basic" matStepperPrevious>{{"forms.button.previous"|translate}}</button>
                  <button mat-raised-button color="primary" (click)="uploadContract()">{{"forms.button.to_validate"|translate}}</button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>
      </div>
      <div class=" col-md-12">
        <app-contract-account-list></app-contract-account-list>
      </div>
    </div>
  </div>
</div>
