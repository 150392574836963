import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TrustedTpService} from '../../../shared/services/trusted-tp.service';
import {ToastrService} from 'ngx-toastr';
import {saveAs} from 'file-saver-es';
import {AccountService} from '../../../shared/services/account.service';
import {NotificationsService} from 'src/app/shared/services/notifications.service';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ITrustedTP} from 'src/app/shared/models/i-trustedTP';
import {MatDialog} from '@angular/material/dialog';
import {DescriptionComponent} from '../description/description.component';
import {TranslateService} from '@ngx-translate/core';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import { DatePipe } from '@angular/common';

export interface ITtpRole {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roleAcceptedDate: string;
  status: string;
  isRecoveryRequestAccepted?: boolean|null;
  haveRecoveryRequest?: boolean|null;
  haveAnyRecoveryRequest?: boolean|null;
  forUser: string;
}

@Component({
  selector: 'app-your-ttp-role',
  templateUrl: './your-ttp-role.component.html',
  styleUrls: ['./your-ttp-role.component.scss'],
  providers: [DatePipe]
})
export class YourTtpRoleComponent implements OnInit , AfterViewInit {

  constructor(private accountService: AccountService,
              private trustedTpService: TrustedTpService,
              private toastr: ToastrService,
              private notificationService: NotificationsService,
              private registrationService: AuthenticationService,
              public dialog: MatDialog, public translate: TranslateService, private datePipe: DatePipe) { }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['contract', 'firstName', 'lastName', 'email', 'phone', 'roleAcceptedDate'  , 'status', 'actions'];
  dataSource = new MatTableDataSource<ITtpRole>();
  invitationList: any = [];
  content;
  currentUser;
  trustedTp2;
  trustedTp1;
  CriticalityContracts;
  roleTrustedTPRecoveryRequestList: any = [];
  forUser;
  hide = true;
  ngOnInit(): void {
    this.getYourTrustedTP();
    this.getYourTTPInvitation();
    this.getAllRoleTrustedTPRecoveryRequest();
    this.registrationService.getCurrentUser().subscribe(
      (result: any) => {
          this.currentUser = result.data;
      }, );
  }

  getYourTrustedTP(): void{
    this.trustedTpService.getYourTrustedTPList().subscribe(
      (result: any) => {
        this.dataSource.data = result.data;
        this.CriticalityContracts = result.data;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  getYourTTPInvitation(): void{
    this.trustedTpService.getYourTTPInvitations().subscribe(
      (result: any) => {
        this.invitationList = result.data;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  roleTrustedTPAccepted(data ): void{
    if (data.confirmationCode){
      this.trustedTpService.acceptedTrustedTP(data).subscribe(
      (result: any) => {
          const msg = this.translate.get('toastr.info.you_have_accepted_role_trusted_third_party');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          this.getYourTrustedTP();
          this.getYourTTPInvitation();
          this.notificationService.updateAlerte({id: null, isReaded: true, trustedTp: data.id, params: {}}).subscribe((res ) => {

          });
        },
      (error) => {
          const msg = this.translate.get('toastr.error.wrong_confirmation_code');
          msg.subscribe(value => {
            this.toastr.error(value);
          });
        }
      );
    } else {
      const msg = this.translate.get('toastr.info.contact_your_relatives_to_retrieve_confirmation_code');
      msg.subscribe(value => {
        this.toastr.info(value);
      });
    }
  }

  roleTrustedTPRefused(data ): void{
    this.trustedTpService.refusedTrustedTP(data).subscribe(
      (result: any) => {
        const msg = this.translate.get('toastr.info.you_have_declined_role_of_trusted_third_party');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        this.getYourTrustedTP();
        this.getYourTTPInvitation();
        this.notificationService.updateAlerte({id: null, isReaded: true, trustedTp: data.id, params: {}}).subscribe((res ) => {
        });
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  RecoveryRequest(data): void{
    this.trustedTpService.RecoveryRequest(data).subscribe(
      (result: any) => {
        const msg = this.translate.get('toastr.info.your_request_retrieve_user_accounts_being_processed');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        this.getYourTrustedTP();
        this.getYourTTPInvitation();
        this.content = 'notifications.alert.trusted_third_party_requested_the_communication_list_of_your_accounts';
        const param = {currentUser: this.currentUser.firstName + ' ' + this.currentUser.lastName};
        this.notificationService.addAlertes({user: data.forUser, content: this.content, trustedTp: null, params: param}).subscribe(
          (data) => {});
        this.trustedTpService.getTrustedTpByForUser(data.forUser).subscribe((res) => {
          this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2 : res.data.TP1) : null;
          this.trustedTp1 = res.data.TP2 ? (res.data.TP2.trustedTPUser == this.currentUser.id ? res.data.TP2 : res.data.TP1) : res.data.TP1;

          this.content = 'notifications.info.you_requested_list_accounts_of_your_relatives';
          this.notificationService.addNotifications({trustedTp: null, content: this.content, user: this.trustedTp1.trustedTPUser,
            params: {}}).subscribe(
            (data) => {});
          if (this.trustedTp2 != null || this.trustedTp2.length > 0) {
            this.content = 'notifications.alert.a_trusted_third_party_requested_communication_of_list_accounts_of_one_of_your_relatives';

            this.notificationService.addAlertes({trustedTp: null, content: this.content, user: this.trustedTp2.trustedTPUser, params: {}}).subscribe(
              (data) => {});
          }
        });
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  RecoveryRequestCanceled(data ): void{
    this.trustedTpService.RecoveryRequestCanceled(data).pipe(
      switchMap((res) => {
        const msg = this.translate.get('toastr.info.cancled_data_recovery_request');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        return this.trustedTpService.getTrustedTpById(data.id)
          .pipe(
            switchMap((res) => {
              this.dataSource.data.forEach(element => {
                if (element.forUser == res.data.forUser.id) {
                  this.forUser = res.data.forUser;
                }
              });
              this.content = 'notifications.info.request_to_make_your_accounts_list_available_is_cancled_by_trustedtp_initiated_request';
              return this.notificationService.addNotifications({
                user: this.forUser.id,
                content: this.content,
                trustedTp: null,
                params: {}
              });
            }),
            switchMap(() =>
              this.notificationService.updateAlerte({
                id: null,
                isReaded: true,
                trustedTp: null,
                user: this.forUser.id,
                params: {}
              })
            ),
            switchMap(() => {
                this.content = 'notifications.info.you_have_cancled_your_request_to_make_list_of_your_relatives_available';
                return this.notificationService.addNotifications({
                  user: this.currentUser.id,
                  content: this.content,
                  trustedTp: null,
                  params: {forUser: this.forUser.name}
                });
              }
            ),
            switchMap(() =>
              this.notificationService.updateAlerte({
                id: null,
                isReaded: true,
                trustedTp: null,
                user: this.currentUser.id,
                params: {}
              })
            ),
            switchMap(() => {
              return this.trustedTpService.getTrustedTpByForUser(this.forUser.id)
                .pipe(
                  filter((res) => {
                    this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2.trustedTPUser : res.data.TP1.trustedTPUser) : null;
                    return this.trustedTp2 != null;
                  }),
                  switchMap(() =>
                    this.notificationService.updateAlerte({
                      id: null,
                      isReaded: true,
                      trustedTp: null,
                      user: this.trustedTp2,
                      params: {}
                    })
                  ),
                  switchMap(() => {
                    this.content = 'notifications.info.request_to_make_relatives_accounts_list_available_has_been_cancled_by_trustedtp_who_initiated_request';
                    return this.notificationService.addNotifications({
                      user: this.trustedTp2,
                      content: this.content,
                      trustedTp: null,
                      params: {forUser: this.forUser.name}
                    });
                  })
                );
            })
          );
      })
    ) .subscribe(
      (result: any) => {
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  public removeTrustedTP(data): void {
    if (data.isSecondTrustedPartner) {
      this.trustedTpService.removeTrustedTP2(data.forUser).pipe(
        switchMap((res) => {
            this.getYourTrustedTP();
            const msg = this.translate.get('toastr.info.your_second_trusted_third_party_has_been_withdrawn');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.content = 'notifications.info.is_no_longer_your_trustedtp_we_recommend_to_appoint_a_new_one';
            return this.notificationService.addNotifications({
            user: data.forUser,
            content: this.content,
            trustedTp: null,
            params: {trustedtp: this.currentUser.firstName + ' ' + this.currentUser.lastName}
          });
        }),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: data.forUser,
            params: {}
          })
        ),
        switchMap(() => {
          return this.trustedTpService.getTrustedTpByForUser(data.forUser)
            .pipe(
              filter((res) => {
                this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2.trustedTPUser : res.data.TP1.trustedTPUser) : null;
                return this.trustedTp2 != null;
              }),
              switchMap(() =>
                this.notificationService.updateAlerte({
                  id: null,
                  isReaded: true,
                  trustedTp: null,
                  user: this.trustedTp2,
                  params: {}
                })
              )
            );
        })
      ).subscribe(
        (result: any) => {
        },
        (error) => {
          console.log('erreur : ' + error);
        }
      );
    }else{
      this.trustedTpService.removeTrustedTP1(data.forUser).pipe(
        switchMap((res) => {
          this.getYourTrustedTP();
          const msg = this.translate.get('toastr.info.your_first_trusted_third_party_has_been_withdrawn');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          this.content = 'notifications.info.is_no_longer_your_trustedtp_we_recommend_to_appoint_a_new_one';
          return this.notificationService.addNotifications({
            user: data.forUser,
            content: this.content,
            trustedTp: null,
            params: {trustedtp: this.currentUser.firstName + ' ' + this.currentUser.lastName}
          });
        }),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: data.forUser,
            params: {}
          })
        ),
        switchMap(() => {
          return this.trustedTpService.getTrustedTpByForUser(data.forUser)
            .pipe(
              filter((res) => {
                this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2.trustedTPUser : res.data.TP1.trustedTPUser) : null;
                return this.trustedTp2 != null;
              }),
              switchMap(() =>
                this.notificationService.updateAlerte({
                  id: null,
                  isReaded: true,
                  trustedTp: null,
                  user: this.trustedTp2,
                  params: {}
                })
              )
            );
        })
      ).subscribe(
        (result: any) => {
        },
        (error) => {
          console.log('erreur : ' + error);
        }
      );
    }
  }

  getAllRoleTrustedTPRecoveryRequest(): void{
    this.trustedTpService.getAllRoleTrustedTPRecoveryRequest().subscribe(
      (result: any) => {
        this.roleTrustedTPRecoveryRequestList = result.data;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  RecoveryRequestAccepted(data): void {
    this.trustedTpService.setHaveRecoveryRequest({id: data.id, recoveryRequestAcceptedByTrusted: true})
      .pipe(
        switchMap((res) => {
          this.getAllRoleTrustedTPRecoveryRequest();
          const msg = this.translate.get('toastr.info.accepted_data_recovery_request');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          return this.trustedTpService.getTrustedTpById(data.id)
            .pipe(
              switchMap((res) => {
                this.forUser = res.data.forUser;
                this.content = 'notifications.info.you_have_confirmed_your_agreement_to_make_acount_list_of_available';
                return this.notificationService.addNotifications({
                  user: this.currentUser.id,
                  content: this.content,
                  trustedTp: null,
                  params: {forUser: this.forUser.name}
                });
              }),
              switchMap( () =>
                this.notificationService.updateAlerte({
                  id: null,
                  isReaded: true,
                  trustedTp: null,
                  user: this.currentUser.id,
                  params: {}})
              )
            );
        })
      ).subscribe(
      (result: any) => {
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  RecoveryRequestRefused(data ): void{
    this.trustedTpService.RecoveryRequestRefused(data).pipe(
      switchMap((res) => {
        this.getAllRoleTrustedTPRecoveryRequest();
        const msg = this.translate.get('toastr.info.rejected_data_recovery_request');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        return this.trustedTpService.getTrustedTpById(data.id)
          .pipe(
            switchMap((res) => {
              this.dataSource.data.forEach(element => {
                if (element.forUser == res.data.forUser.id) {
                  this.forUser = res.data.forUser;
                }
              });
              this.content = 'notifications.info.your_trustedtp_refused_request_to_make_list_accounts_available';
              return this.notificationService.addNotifications({
                trustedTp: null,
                content: this.content,
                user: this.forUser.id,
                params: {currentUser: this.currentUser.firstName + ' ' + this.currentUser.lastName}
              });

            }),
            switchMap(() =>
              this.notificationService.updateAlerte({
                id: null,
                isReaded: true,
                trustedTp: null,
                user:  this.forUser.id,
                params: {}
              })
            ),
            switchMap(() => {
              this.content = 'notifications.info.you_are_refused_request_to_make_list_accounts_of_available';
              return this.notificationService.addNotifications({
                trustedTp: null,
                content: this.content,
                user: this.currentUser.id,
                params: {forUser: this.forUser.name}
              });
            }),
            switchMap(() =>
              this.notificationService.updateAlerte({
                id: null,
                isReaded: true,
                trustedTp: null,
                user: this.currentUser.id,
                params: {}
              })
            ),
            switchMap(() => {

              return this.trustedTpService.getTrustedTpByForUser(this.forUser.id)
                .pipe(
                  filter((res) => {
                    this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2.trustedTPUser : res.data.TP1.trustedTPUser) : null;
                    return this.trustedTp2 != null;
                  }),
                  switchMap(() => {
                    this.content = 'notifications.info.the_second_trusted_third_party_refused_your_request';
                    return this.notificationService.addNotifications({
                      trustedTp: null,
                      content: this.content,
                      user: this.trustedTp2,
                      params: {forUser: this.currentUser.firstName + ' ' + this.currentUser.lastName}
                    });
                  }),
                  switchMap(() =>
                    this.notificationService.updateAlerte({
                      id: null,
                      isReaded: true,
                      trustedTp: null,
                      user: this.trustedTp2,
                      params: {}
                    })
                  )
                );
            })
          );
      })
    )
    .subscribe(
      (result: any) => {
      },
    (error) => {
      console.log('erreur : ' + error);
      }
    );
  }

  exportCSV(data): void{
    const payload = {
      userId : data.forUser
    };
    let param = {};

    this.accountService.exportCSV(payload).subscribe(
      (result: any) => {
        const today = new Date();
        console.log(data);
        // notification affiché chez l'utilisateur
        this.content = 'notifications.info.first_trustedtp_downloaded_file_of_account';
        param = {trustedTp : this.currentUser.firstName + ' ' + this.currentUser.lastName, date: this.datePipe.transform(today, 'dd/MM/yyyy') };
        this.notificationService.addNotifications({trustedTp: null, content: this.content, user: data.forUser, params: param}).subscribe(
          (data: any) => {
          }
        );
        // notification affiché chez le 2eme tiers de confiance
        this.content = 'notifications.info.second_trustedtp_of_user_downloaded_file_of_account';
        param = {user : data.firstName + ' ' + data.lastName, date: this.datePipe.transform(today, 'dd/MM/yyyy') };
        this.trustedTpService.getTrustedTpByForUser(data.forUser).subscribe((res) => {
          this.trustedTp2 = res.data.TP2 ? (res.data.TP2.trustedTPUser != this.currentUser.id ? res.data.TP2 : res.data.TP1) : null;
          if (this.trustedTp2 != null || this.trustedTp2.length > 0) {
            this.notificationService.addNotifications({trustedTp: null, content: this.content, user: this.trustedTp2.trustedTPUser, params: param}).subscribe(
              (data: any) => {
              }
            );
          }
        });

        saveAs(result, 'export.csv', { autoBom: true });
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

  }
  openInfoContract(trustedTp: ITrustedTP){
    this.dialog.open(DescriptionComponent, {
      data : {
        mode: 'info',
        trustedTp,
      }
    });
  }

}
