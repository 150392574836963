<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <form [formGroup]="addBlockFormGroup">

             <h4>Administration du bloc marketing</h4>

              <mat-form-field appearance="fill">
                <mat-label>Page</mat-label>
                <mat-select formControlName="page" (selectionChange)="loadData($event)" required>
                  <mat-option *ngFor="let page of pages" [value]="page.value" >
                    {{page.viewValue | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field> <br>

              <mat-form-field appearance="fill">
                <mat-label>{{'header.profil' | translate}}</mat-label>
                <mat-select (selectionChange)="loadData($event)" formControlName="profile" required>
                  <mat-option *ngFor="let profile of profiles" [value]="profile.value" >
                    {{profile.viewValue | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>



              <div class="mat-form-field-wrapper">
              <span> <b>{{'header.french_language' | translate}}</b> </span> :  <editor
                  apiKey="bauj4qpl6zcfee6ke3uy5nzo136t5o6pph0ecn852vehwd1t"
                  formControlName="langueFr"
                  class="mat-elevation-z1"
                  (onInit)="handleEditorInit($event)"
                  [init]="{
                    icons: 'material',
                    skin: 'borderless',
                    plugins: 'wordcount',
                    menubar: false,
                    min_height: 100
                  }"
                ></editor>

                  </div>

                  <div class="mat-form-field-wrapper">
                    <span> <b>{{'header.english_language' | translate}}</b> </span> :
                    <editor
                      apiKey="bauj4qpl6zcfee6ke3uy5nzo136t5o6pph0ecn852vehwd1t"
                      formControlName="langueEng"
                      class="mat-elevation-z1"
                      placeholder="Language englais"
                      (onInit)="handleEditorInit($event)"
                      [init]="{
                        icons: 'material',
                        skin: 'borderless',
                        plugins: 'wordcount',
                        menubar: false,
                        min_height: 100
                      }"
                    ></editor>
                      </div>



              <div align="end">
                <button mat-raised-button color="warn" mat-dialog-close>{{"forms.button.cancel"|translate}}</button>
                <button mat-raised-button color="primary" (click)="save()" >{{"forms.button.to_validate"|translate}}</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
