import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/shared/services/account.service';
import {IAccount} from '../../../shared/models/i-account';
import {TranslateService} from "@ngx-translate/core";

export interface DialogData {
  account: IAccount;
  mode: string;
}

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any ,
      private accountService: AccountService,
      private toastr: ToastrService,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<AccountDeleteComponent>
    ) {}

  ngOnInit(): void {
  }

  public delete(): void {
    this.accountService.deleteAccount(this.data.account.id).subscribe(
      (result) => {
        let msg = this.translate.get('toastr.info.account_has_been_deleted');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error(error );
      }
    );
  }

}
