import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { IContract } from 'src/app/shared/models/i-contract';
import { ContractService } from 'src/app/shared/services/contract.service';

@Component({
  selector: 'app-contract-show',
  templateUrl: './contract-show.component.html',
  styleUrls: ['./contract-show.component.scss']
})
export class ContractShowComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private contractService: ContractService,

    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ContractShowComponent>,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}
  dataSource = new MatTableDataSource<IContract>();

  contracts: IContract[] = [];
 public contractData: IContract[] = [];
  contractGroup: FormGroup;
  hide = true;
  ngOnInit(): void {
    this.initForm();
  }

  getContract(): void{
    this.contractService.getContract().subscribe(
      (result: any) => {
        this.contracts = result;
        this.contractData = result.data;
        this.dataSource.data = this.contractData;

    },
    (error) => {
      console.log('erreur : ' + error);
    });
  }
  initForm(): void{
    this.contractGroup = this.formBuilder.group({
      categorie: [{value: this.data.contract.categorie, disabled: true}, Validators.required],
      group: [{value: this.data.contract.group, disabled: true}, Validators.required],
      company: [{value: this.data.contract.company, disabled: true}, Validators.required],
      codecontract: [{value: this.data.contract.codecontract, disabled: true}, Validators.required],
      label: [{value: this.data.contract.label, disabled: true}, Validators.required],
      criticality: [{value: this.data.contract.criticality, disabled: true}, Validators.required],
    });

  }
  closediag(): void {
    this.dialogRef.close(ContractShowComponent);
  }

}
