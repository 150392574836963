import {Component, OnInit, ViewChild} from '@angular/core';
import {ContractAccountService} from '../../../shared/services/contract-account.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {IContractFile} from '../../../shared/models/i-contractFile';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contract-account-repport',
  templateUrl: './contract-account-repport.component.html',
  styleUrls: ['./contract-account-repport.component.scss']
})
export class ContractAccountRepportComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractAccountService: ContractAccountService,
              private toastr: ToastrService,
              public translate: TranslateService  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'phone' , 'address', 'city', 'country', 'dueDate', 'errors'];
  dataSource = new MatTableDataSource<IContractFile>();
  rowColumns: any;
  fileIinfo: any;
  fileIid: any;
  validRec: number;
  invalidRec: number;
  accountAdded: number;
  accountDeleted: number;


  ngOnInit(): void {
    this.fileIid = this.route.snapshot.params;
    this.getContractReport(this.fileIid);
  }

  getContractReport(fileIinfo: any): void{
    this.contractAccountService.loadedFileDetail( fileIinfo.fileId).subscribe(
      (result: any) => {
        this.validRec = result.data.filter((rec) => {
          return !rec.hasError;
        }).length;
        this.invalidRec = result.data.filter((rec) => {
          return rec.hasError;
        }).length;
        this.accountAdded = result.file.addUsersCount;
        this.accountDeleted = result.file.deletedUsersCount;
        this.fileIinfo = result.file;

        console.log(result.data);

        this.rowColumns = result.data;
        this.rowColumns.forEach((element, index) => {
          if (element.hasError === false) { this.rowColumns.splice(index); }
        });
        this.dataSource = this.rowColumns;
        console.log(this.dataSource);
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  changeStatusOfLoading(status: boolean): void {
    const payload = {status};
    this.contractAccountService.changeStatusOfLoading(this.fileIinfo.id , payload).subscribe(
      (result: any) => {
        this.fileIinfo.status = status;
        switch (status) {
          case true :
            this.translate.get('toastr.info.file_validated').subscribe(value => {
              this.toastr.info(value);
            });
            this.router.navigate(['/contract-account/upload'] );
            break;
          case false :
            this.translate.get('toastr.info.archived_file').subscribe(value => {
              this.toastr.info(value);
            });
            this.router.navigate(['/contract-account/upload'] );
            break;
        }
      },
      (error) => {
        this.translate.get(error.error.message).subscribe(value => {
          this.toastr.error(value);
        });
      }
    );
  }
  log(val) { console.log(val); }

}
