<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"list.titles.list_partner"|translate}}
            <button mat-raised-button color="primary" (click)="openAddPartnerDialog()"  style="float: right">{{"forms.titles.add_partner"|translate}}</button>
            </h5>
          </div>
          <div class=" card-body">
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortDirection="desc" >

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef  mat-sort-header > {{"forms.champs.mail"|translate}}  </th>
                  <td mat-cell *matCellDef="let element"> {{element.email }} </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.nom"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.prenom" | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.phone"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="birthDate">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.birth_date"|translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.birthDate}} </td>
                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.adress"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                </ng-container>

                <ng-container matColumnDef="postalCode">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.postal_code"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.postalCode}} </td>
                </ng-container>

                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.city"|translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                </ng-container>

                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.country"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.country}} </td>
                </ng-container>

                <ng-container matColumnDef="communicationPartenaire">
                  <th mat-header-cell *matHeaderCellDef> {{"list.columns.communication_partner"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.communicationPartenaire}} </td>
                </ng-container>

                <ng-container matColumnDef="lang">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.langue"|translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.lang}} </td>
                </ng-container>

                <ng-container matColumnDef="actions" >
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let element" class="options">
                    <button color="basic" mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="openEditPartnerDialog(element)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"list.button.update"|translate}}</span>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



