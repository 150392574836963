import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

/*  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }*/

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.auth.isAuthenticated()) {
      const userRoles = this.auth.readToken().roles;
      const allowedRole = route.data.roles ;
      if (allowedRole){
        const intersection = userRoles.filter(value => allowedRole.includes(value));
        if (intersection.length){
          return  true;
        }
        this.router.navigate(['/Unauthorized']);
        return false;
      }
      return true;
    }else {
      this.router.navigate(['auth/login']);
      return false;
    }
  }


}
