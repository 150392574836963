import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import {HeaderService} from "../../services/header.service";
import {TrustedTpService} from "../../services/trusted-tp.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(private notificationServie: NotificationsService, private headerService: HeaderService, private trustedTpService: TrustedTpService) { }
  public isOpen = false;
  public lang = sessionStorage.getItem('lang');

   dropshow = '';
   notificationList = [];
   alertList = [];

  ngOnInit(): void {
    this.headerService.languageChange.subscribe((value) => {
      this.lang = value;
    });
    this.getNotifications();
    this.getAlert();
  }

  handleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  dropdownToggle(): void {
    this.dropshow = this.dropshow === '' ? 'show' : '';
  }

  getNotifications(): void{
    this.notificationServie.getNotification().subscribe(
      (result: any) => {
        this.notificationList = result.data;
        console.log(this.notificationList);
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
  getAlert(): void{
    this.notificationServie.getAlert().subscribe(
      (result: any) => {
        this.alertList = result.data;
        console.log(this.alertList);
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
  updateNotification(id): void{
      this.notificationServie.updateNotifications({id, isReaded: true}).subscribe(
        (data: any) => {
          this.getNotifications();
          this.getAlert();
        }
      );
  }
}
