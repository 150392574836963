<i class="material-icons close" data-dismiss="alert" aria-label="Close" (click)="closediag()">close</i>
<h2 mat-dialog-title >{{"forms.titles.view_contract"|translate}}</h2>

<form [formGroup]="contractGroup">
  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.category"|translate}}' formControlName="categorie"  >
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.group"|translate}}' formControlName="group"  >
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.company"|translate}}' formControlName="company"  >
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.contract_code"|translate}}' formControlName="codecontract"  >
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.contract_label"|translate}}' formControlName="label"  />
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.criticality"|translate}}' type='number' formControlName="criticality"  />
  </mat-form-field>
</form>
