<h2 mat-dialog-title *ngIf="data.mode === 'Add'">{{"forms.titles.add_account" |translate}}</h2>
<h2 mat-dialog-title *ngIf="data.mode === 'Edit'">{{"forms.titles.edit_account" | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="box-container">


    <mat-vertical-stepper [linear]="isLinear" #stepper >
<!--      <mat-step [stepControl]="firstFormGroup" [editable]="true" *ngIf="data.mode === 'Edit'">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Verification d'identiter</ng-template>
          <mat-form-field class="full-width">
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="current-password" required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint *ngIf="firstFormGroup.controls.password.dirty && firstFormGroup.controls.password.hasError('required')">
              <p class="red-text">Le mot de passe est <strong>obligatoire</strong></p>
            </mat-hint>
            <mat-hint *ngIf="firstFormGroup.controls.password.dirty && firstFormGroup.controls.password.hasError('pattern')">
              <p class="red-text">Mot de passe invalide.</p>
            </mat-hint>
          </mat-form-field>
          <div align="end">
            <button mat-raised-button color="warn" mat-dialog-close>Annuler</button>
            <button mat-raised-button color="primary" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>{{"forms.steps.enter_your_information"|translate}}</ng-template>
          <mat-form-field class="full-width">
            <mat-label>{{"forms.champs.category"|translate}}</mat-label>
            <mat-select formControlName="category" required>
              <mat-option *ngFor="let categorie of categories" [value]="categorie.name">
                {{categorie.name | translate}}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="secondFormGroup.controls.category.dirty && secondFormGroup.controls.category.hasError('required')">
              <p class="red-text">{{"forms.alerte.category_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput placeholder="{{'forms.champs.company'|translate}}" formControlName="company" required >
            <mat-hint *ngIf="secondFormGroup.controls.company.dirty && secondFormGroup.controls.company.hasError('required')">
              <p class="red-text">{{"forms.alerte.company_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput placeholder="{{'forms.champs.account'|translate}}" formControlName="account" required>
            <mat-hint *ngIf="secondFormGroup.controls.account.dirty && secondFormGroup.controls.account.hasError('required')">
              <p class="red-text" >{{"forms.alerte.account_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width">
            <textarea matInput placeholder="{{'forms.champs.personal_notes'|translate}}" formControlName="note" ></textarea>
            <mat-hint *ngIf="secondFormGroup.controls.note.dirty && secondFormGroup.controls.note.hasError('required')">
              <p class="red-text">{{"forms.alerte.personal_notes_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
            </mat-hint>
          </mat-form-field>

          <mat-form-field  class="full-width">
            <mat-label>{{"forms.champs.opening_date"|translate}}</mat-label>
            <input matInput [matDatepicker]="picker" (click)="picker.open()" [max]="today" formControlName="dueDate" readonly >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="secondFormGroup.controls.dueDate.dirty && secondFormGroup.controls.dueDate.hasError('required')">
              <p class="red-text">{{"forms.alerte.opening_date_format"|translate}} mm/jj/yyyy</p>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width" >
            <textarea matInput placeholder="{{'forms.champs.info_crypted'|translate}}" formControlName="information" ></textarea>
          </mat-form-field>

          <div align="end">
            <button mat-raised-button color="warn" mat-dialog-close>{{"forms.button.cancel"|translate}}</button>
            <!--<button mat-raised-button color="basic" matStepperPrevious *ngIf="data.mode === 'Edit'" >Precedent</button>-->
            <button mat-raised-button color="primary" (click)="save()">{{"forms.button.to_validate"|translate}}</button>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</mat-dialog-content>
