import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import {TrustedTpService} from '../../../shared/services/trusted-tp.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-trusted-tp-remove',
  templateUrl: './trusted-tp-remove.component.html',
  styleUrls: ['./trusted-tp-remove.component.scss']
})
export class TrustedTpRemoveComponent implements OnInit {
  content: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private trustedTpService: TrustedTpService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<TrustedTpRemoveComponent >,
    private notificationService: NotificationsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
  }

  public delete(): void {
    switch (this.data.mode) {
      case 'tp1':
        this.trustedTpService.removeTrustedTP1(this.data.id).subscribe(
          (result: any) => {
            const msg = this.translate.get('toastr.info.your_first_trusted_third_party_has_been_withdrawn');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.content = 'notifications.info.you_are_no_longer_a_trusted_third_party_to';
            this.notificationService.addNotifications(
              {user: this.data.trustedTP1.trustedTPUser, content: this.content, trustedTp: null,
                    params: {currentUser: this.data.currentUser}})
              .subscribe(
                (data) => {
                  const uuid = this.data.notificationIdtp;
                  this.notificationService.updateNotifications({id: uuid, isReaded: true})
                    .subscribe(
                      (res: any) => {
                        this.notificationService.getNotification();
                      }
                    );
                  this.notificationService.updateAlerte({
                    id: null,
                    isReaded: true,
                    trustedTp: null,
                    user: this.data.trustedTP1.trustedTPUser,
                    params: {}
                  });
                });
            this.dialogRef.close('removeTP1');
          },
          (error) => {
            console.log('erreur : ' + error);
          }
        );
        break;
      case 'tp2':
        this.trustedTpService.removeTrustedTP2(this.data.id).subscribe(
          (result: any) => {
            const msg = this.translate.get('toastr.info.your_second_trusted_third_party_has_been_withdrawn');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.content = 'notifications.info.you_are_no_longer_trusted_second_party_for';

            this.notificationService.addNotifications(
              {user: this.data.trustedTP2.trustedTPUser, content: this.content, trustedTp: null,
                params: {currentUser: this.data.currentUser}})
              .subscribe(
              (data) => {
                console.log(this.data.notificationIdtp2);
                this.notificationService.updateNotifications({id: this.data.notificationIdtp2, isReaded: true}).subscribe(
                  (res: any) => {
                    this.notificationService.getNotification();
                  }
                );
                this.notificationService.updateAlerte({
                  id: null,
                  isReaded: true,
                  trustedTp: null,
                  user: this.data.trustedTP2.trustedTPUser,
                  params: {}
                });
              });
            this.dialogRef.close('removeTP2');
          },
          (error) => {
            console.log('erreur : ' + error);
          }
        );
        break;
    }
  }
}
