<div class="container">
  <div class="header">
    <div class="title">   <h1>Conditions Générale d’Utilisation du service 3wlife</h1> </div>
    <div class="btn-close">
      <button (click)="closeModale()">
        <svg-icon src="assets/svg/closeCookies.svg" [svgStyle]="{ 'height.px':30, 'width.px':30 }"></svg-icon>
      </button>
    </div>
  </div>
  <div class="content">

<h3>Concernant le service « 3wlife»</h3>
<h4>Date de prise d’effet  : JJ mois AAAA </h4>

<b>1 Champ d’application</b> <br/>
<b>1.1</b> <br/>

Les présentes conditions d’utilisation (ci-après les « Conditions d’Utilisation ») s’appliquent à l’utilisation du service 3wlife accessible à travers une plate-forme internet disponible à l’adresse https://3wlife.com (ci-après « le service »), tels que mis à disposition par Ananke SAS, 293 boulevard Saint Denis, 92400 à Courbevoie France, représentée par Christophe KORFER et tous deux, dénommé ci-après 3wlife. 
 <b>1.2</b><br/>

L’utilisation du Service est exclusivement régie par les présentes Conditions d’Utilisation. Aucune dérogation aux présentes Conditions d’Utilisation ne peut s’appliquer sans le consentement écrit de 3wlife.

<b>2 Conclusion du contrat ; absence de rémunération</b> <br/>

<b>2.1</b> <br/>
Le Service ne peut être utilisé que par des personnes dûment enregistrés pour l’utilisation du Service (ci-après les « Utilisateurs Enregistrés »).
<b>2.2</b> <br/>

La connexion réussie au service en tant qu’utilisateur et ou en tant que tiers de confiance vaut conclusion d’un contrat Utilisateurs Enregistrés et de l’acceptation de l’intégralités des clauses du présent contrat. 
<br/>2.2.1<br/>
L’enregistrement pour le service par une personne, est le résultat d’une souscription préalable à une offre de service auprès d’un de nos partenaires et à l’activation du service 3wlife par celui-ci ou d’une invitation générée par un utilisateur enregistré, cas des tiers de confiances.  
<br/>2.2.2<br/>
Les données personnelles demandées par le service doivent être fournies de façon exhaustive et exacte et validée par l’utilisateur ; les erreurs peuvent cependant être corrigées à tout moment. Afin de détecter les erreurs pendant la saisie des données, 3wlife met à disposition de l’utilisateur des moyens techniques sous la forme d’un contrôle général d’exhaustivité (vérification de ce que tous les champs obligatoires ont été remplis).
<br/>2.2.3<br/>
Si l’activation par l’utilisateur est réussie, 3wlife crée au nom de ce dernier un compte utilisateur individuel et l’en informe par une notification par courriel ou une information sur le site. Cette information sur la création effective d’un compte utilisateur met fin à la procédure d’enregistrement et vaut conclusion entre 3wlife et l’Utilisateur Enregistré d’un contrat d’utilisateur pour l’utilisation des Services.
<br/><b>2.3</b><br/>

Le texte de l’accord contractuel est archivé par 3wlife. L’utilisateur peut à tout moment récupérer et télécharger le texte de l’accord contractuel constitué par les présentes Conditions d’Utilisation via le menu correspondant dans l’Application ou, le cas échéant, sur le Portail.
<br/><b>2.4</b><br/>

3wlife ne demande aucune rémunération pour l’utilisation du Service. Les utilisateurs peuvent, toutefois, être amenés à supporter des frais de connexion conformément aux accords tarifaires existant entre l’Utilisateur Enregistré et son opérateur de téléphone mobile ou son fournisseur Internet.
<br/><b>2.5</b><br/>

Les Utilisateurs Enregistrés doivent s’assurer que les informations fournies sont à jour. Les identifiants de connexion personnels (tels que les mots de passe) ne doivent pas être communiqués à des tiers et doivent être gardés à l’abri de tout risque d’accès par des tiers. Le mot de passe doit être changé régulièrement pour des raisons de sécurité. Lorsqu’il y a lieu de supposer que des personnes non autorisées ont eu accès aux identifiants de connexion, l’utilisateur doit en informer 3wlife par écrit et modifier immédiatement ses identifiants de connexion.

<br/><b>3 Prérequis techniques pour l’utilisation des Services</b><br/>

 <b>3.1</b><br/>

Pour pouvoir utiliser le Service, l’Utilisateur Enregistré doit disposer ou d’un appareil mobile (téléphone ou tablette) ou d’un ordinateur, connecté et y accèdent au moyen de leur navigateur habituel 
<br/><b>3.2</b><br/>

L’utilisation des Services suppose, en tout état de cause, une connexion Internet avec un débit de transmission de données suffisant.
<br/><b>3.3</b><br/>

3wlife se réserve le droit de faire à tout moment évoluer le Service en fonction des conditions du marché. Pour une bonne utilisation du Service, il est ainsi notamment nécessaire d’effectuer les mises à jour du système d’exploitation ou, le cas échéant, de l’Application. Les évolutions peuvent en outre conduire à ce que les appareils plus anciens ne soient plus conformes aux prérequis, ou ne le soient plus que de manière limitée.

<br/><b>4 Services fournis par 3wlife </b><br/>
<b>4.1</b> <br/>
Les Services fournis par 3wlife permettent notamment aux Utilisateurs Enregistrés :
    • D’établir une liste de leurs comptes en lignes et d’y enregistrer leurs coordonnées et cela sans limitation 
    • De supprimer ou de modifier les comptes 
    • D’ajouter une « note secrète » pour chacun des comptes
    • De nommer deux « tiers de confiance » de son choix, qui le cas échéant auront accès à la liste des comptes 
    • De supprimer ou de changer de « tiers de confiance » 
    • Les « tiers de confiance » nommé et accédant au service pour la première fois peuvent avoir un accès limité au service. 
    • Les « tiers de confiance » nommés peuvent accepter ou refuser la charge qui leurs est confié à tout moment. Ils peuvent demander la liste des comptes de leurs proches suivant les conditions définies dans les instructions d’utilisation  

À cette fin, 3wlife fournit diverses informations pouvant être récupérées ou téléchargées dans l’Application.
<br/><b>4.2</b><br/>
3wlife entend fournir les Services 24 heures sur 24 et 365 jours par an, mais ne donne toutefois aucune garantie contre les risques d’interruption éventuelle des Services. La disponibilité des Services peut être perturbée du fait de cas de force majeure, tels que grèves, lockouts ou injonctions administratives, ou du fait d’interventions techniques ou autres devant par exemple être réalisées sur les systèmes de 3wlife, du fournisseur de services ou du fournisseur de réseau pour assurer le bon fonctionnement ou l’amélioration des Services (par ex. maintenance, réparation, mises à jour logicielles liées au système, extensions). Une perturbation des Services peut également résulter de pénuries de capacité de courte durée en période de pointe d’utilisation des Services ou de perturbations des systèmes de télécommunications de tiers. 3wlife fera ses meilleurs efforts pour remédier immédiatement à ces perturbations ou pour prendre des mesures à cet effet. Pour tout travail de maintenance programmé, 3wlife tiendra compte des intérêts légitimes des utilisateurs, en particulier en réalisant les travaux de maintenance à des moments où l’utilisation est en principe faible.
<br/><b>4.3 </b><br/>
L’Utilisateur Enregistré ne se voit accorder qu’un droit non exclusif, non transférable et non susceptible de sous-licence d’utiliser les Services conformément aux dispositions des présentes Conditions d’Utilisation, à l’exclusion de tout autre droit d’usage.

<br/><b>5 Compte utilisateur ; Prohibition des abus</b><br/>
<b>5.1 </b><br/>
Après conclusion du contrat d’utilisateur, un compte utilisateur personnel est ouvert au nom de l’Utilisateur Enregistré. L’Utilisateur Enregistré doit veiller à ce que les informations qu’il a communiquées soient maintenues en permanence à jour, à préserver la confidentialité de ses données de connexion et à protéger notamment son appareil mobile d’un accès non autorisé, au moyen, par exemple, d’une protection par mot de passe.
<br/><b>5.2 </b><br/>
L’Utilisateur Enregistré ne doit pas utiliser les Services de manière abusive. Une utilisation abusive des Services est notamment caractérisée lorsque l’Utilisateur Enregistré :
<br/>   1. Enfreint une interdiction légale ou une stipulation des présentes conditions d’utilisation,
<br/> 2. Fournit des informations qu’il sait inexactes, ou
<br/>  3. Prend des mesures délibérées pour échapper aux dispositifs de protection technique.
    <br/><b>5.3</b><br/>
L’utilisateur s’engage à ne pas lancer d’attaque électronique de quelque nature que ce soit. Une attaque électronique s’entend notamment de toute tentative d’échapper aux dispositifs de sécurité de 3wlife ou de les contourner ou de les rendre inopérants de quelque manière que ce soit, de l’utilisation de programmes informatiques pour récupérer automatiquement des données, de l’utilisation et/ou la diffusion de virus, de vers, de chevaux de Troie, de toute attaque de force, de l’utilisation de spam ou de l’utilisation d’autres liens, programmes ou procédures susceptibles d’être préjudiciables à 3wlife.

<br/><b>6 Responsabilité</b><br/>
<b>6.1 </b><br/>
3wlife s’engage à fournir le service selon les règles de l’art, étant précisé qu’il ne pèse sur 3wlife une obligation de moyens à l’exclusion de toute obligation de résultat ou de garantie ce que les utilisateurs enregistrés reconnaissent expressément. 
<br/><b>6.2 </b><br/>
En cas de manquement par 3wlife, ses représentants légaux ou ses préposés à des obligations contractuelles majeures, dont l’exécution conditionne la réalisation effective de l’objet du contrat et au respect desquelles le cocontractant est légitimement en droit de s’attendre (obligations essentielles), la responsabilité de 3wlife sera limitée au montant des préjudices directs et prévisibles lors de la conclusion du contrat.
<br/><b>6.3</b><br/>
La responsabilité de 3wlife ne peut être engagée au-delà des limites résultant des stipulations qui précèdent.

<br/><b>7 Durée et résiliation ; Blocage </b><br/>
<b>7.1 </b><br/>
Le contrat d’utilisateur est conclu pour une durée indéterminée.
<br/><b>7.2 </b><br/>
L’Utilisateur Enregistré peut résilier le contrat d’utilisateur à tout moment sans préavis en utilisant la fonction correspondante dans l’Application ou sur le Portail. 3wlife a le droit de résilier le contrat d’utilisateur à tout moment, sous réserve du respect d’un préavis de trois (3) mois.
<br/> <b>7.3</b><br/>
Les stipulations qui précèdent ne font pas obstacle à une possibilité de résiliation immédiate et sans préavis pour motif légitime. Constitue notamment pour 3wlife un motif légitime de résiliation la violation par l’Utilisateur d’une interdiction stipulée aux articles 5.2 et 5.3.
<b>7.4 </b>
En cas de violation significative de ses obligations par l’utilisateur ou d’existence de raisons sérieuses de soupçonner une telle violation tel que stipulée aux articles 5.2 et 5.3, 3wlife est autorisée à bloquer l’accès de l’utilisateur aux Services. 3wlife informera par courriel l’utilisateur des raisons du blocage. Ce blocage sera maintenu jusqu’à ce qu’il soit remédié à la violation concernée et/ou que l’utilisateur se soit engagé de manière crédible à s’abstenir de toute violation ultérieure de ses obligations.

<br/><b>8 Protection des données </b><br/>
 <b>8.1</b><br/>
3wlife s’engage à respecter la législation en vigueur relative à la protection de la vie privée eu égard au traitement automatisé des données à caractère personnel. 
L’utilisateur consent explicitement à cette politique en cochant la case correspondante du formulaire d’inscription. Si l’utilisateur s’oppose à cette politique, il ne peut pas bénéficier des services proposés par la Plateforme. 
<br/><b>8.2 </b><br/>
Collecte des données personnelles 
Vos données personnelles nominatives, (Nom, prénoms, adresse postales, adresse électronique, numéro de téléphone) nous seront sont fournis par un de nos partenaires auquel vous avez souscrit un contrat de prestation de service. La connexion à 3wlife approuve ce transfert de données. C’est seulement à la première connexion et après avoir accepté les conditions d’utilisation de 3wlife que vos données se trouve alors transféré dans notre base de données. Il vous est demandé de les vérifier et de les modifier le cas échéant.
Ces données sont nécessaires à la bonne utilisation de la plate-forme, a la bonne exécution des missions que vous avez assignés à vos « tiers de confiance » 
En utilisant le service 3wlife, vous avez l’opportunité d’archiver des données supplémentaires personnelles tels que les coordonnés de vos comptes en ligne coordonnés de vos comptes en ligne. En désignant vos « tiers de confiance » vous acceptez de manière irrévocable que 3wlife transmette l’ensemble de vos données au(x) « tiers de confiance » que vous avez préalablement désigné et cela tel que définis dans le descriptif d’utilisation de la plate-forme disponible dans la rubrique « support ».   
A tout moment vous pouvez révoquer cet engagement, simplement en supprimant cette nomination ou en ne nommant pas de tiers de confiance.
<br/><b>8.3 </b><br/>
Traitement de vos données personnelles / cookies
Les données collectées et leurs véracités sont nécessaires à la bonne exécution du service que vous décidé d’utiliser. 
De plus, vos données personnelles nous servent à vous faire parvenir des lettres d’information sur le service et notamment les nouveaux développements mis en place afin de vous offrir le meilleur service possible et de nouvelles fonctionnalités 
3wlife aussi
Lorsque vous naviguez sur la Plateforme, nous collectons des données techniques (adresse IP de l’ordinateur ou de l’appareil mobile utilisé, version du navigateur, système utilisé, résolution) et des informations relatives à la consultation et l’utilisation que vous faites des pages de la Plateforme et des fonctionnalités des services de la Plateforme, pour nous communiquer des informations sur la qualité et la fiabilité de nos services et améliorer l’expérience Membre. Cela nous permet aussi de contrôler d’éventuelles attaques de la Plateforme. Toutes les informations demandées sont recueillies à des fins d’utilisation normale des services de la Plateforme, et sont sauvegardées dans un système sécurisé. 
Ces données brutes sont rendues anonymes au bout de 36 mois et conservées cinq ans au plus. Leur valorisation sous forme de tendances pourra toutefois être conservée plus longtemps. 
Comme tout site internet, la Plateforme peut utiliser des cookies afin de stocker des données permettant d’identifier l’Utilisateur. L’utilisateur pourra cependant empêcher l’utilisation de cookie par 3wlife depuis la Plateforme en sachant que l’absence de cookie de 3wlife peut altérer le fonctionnement de la Plateforme pour l’utilisateur. 
<br/><b>8.4 </b><br/>
Accessibilité et destinataire des données 
3wlife est destinataire exclusif des données à caractère personnel recueillies par l’intermédiaire de la Plateforme. Elle s’engage à mette tous les moyens en œuvre pour assurer la sécurité́ et la confidentialité́ de ces données. Les données sont traitées par 3wlife pour améliorer l’expérience utilisateur, la qualité́ et la performance du Service. 
Toutes les données personnelles et données d’utilisation sont hébergées chez notre hébergeur. Celui-ci a été sélectionné́ pour la sécurité portée aux données et il adhère au code de conduite sur le traitement des données, c’est-à-dire qu’il n’effectue pas de traitement des données personnelles de nos utilisateurs. 
Nous ne communiquons pas les données personnelles aux tiers, sans votre accord, sauf mention contraire des CGU, et notamment auprès d’autorités administratives en cas de requête nominative conformément à la règlementation en vigueur. 

<br/><b>9 Procédure de réclamation </b><br/>
Nous faisons toujours de notre mieux pour vous offrir un service clientèle agréable, mais si vous n'êtes pas satisfait de notre service, n'hésitez pas à nous contacter et à nous le faire savoir.
Contactez notre équipe chargée de l'expérience client par téléphone ou par courriel. Notre équipe sera heureuse de vous aider à résoudre le problème. Si nous ne pouvons pas résoudre le problème ensemble, vous pourrez nous faire part de votre réclamation par courriel.
Faites votre réclamation à notre équipe chargée de l'expérience client. Ils sont joignables en semaine entre 9 h et 18 h au 0801 840505. Vous pouvez également envoyer un courriel à réclamatio@3wlife.io 

<br/><b>10 Mise à jour, Evolution, modification des Conditions Générale d’Utilisation du service (CGU)</b><br/>
<b>10.1 </b><br/>
Les modifications ou ajouts apportés aux présentes Conditions d’Utilisation ne seront juridiquement valable que pour autant qu’ils résultent d’un écrit accepté par les deux parties : au moins huit 8 jours avant l’entrée en vigueur des dites modifications un avis sera adressé par Courriel à l’adresse de l’utilisateur. L’utilisation continue de la plateforme implique l’acceptation par l’utilisateur des nouvelles CGU. La dernière version des CGU avec la date de mise en application sera toujours disponible sur le site 
<br/> <b>10.2</b><br/>
Si une des clauses des présentes Conditions d’Utilisation est ou devient invalide ou nulle ou inopposable, cela n’affectera pas la validité des autres clauses et elles continueraient à produire tous leurs effets.  Le droit commun trouvera à s’appliquer aux lieu et place des stipulations non valides des présentes Conditions d’Utilisation, de même que pour les situations ne faisant pas l’objet de stipulations spécifiques dans les présentes Conditions d’Utilisation. Lorsqu’aucune règle de droit commun ne régit une stipulation particulière (lacune réglementaire) ou que l’application de la règle de droit commun conduirait à un résultat déraisonnable, les parties se rapprocheront pour convenir du remplacement de la stipulation nulle ou invalide par une stipulation valable qui lui soit aussi proche que possible d’un point de vue économique.

<br/><b>11. Langage</b><br/>
La plate-forme, les communications, divers documents et les CGU sont disponibles en plusieurs langues. En cas de dispute seule la version en Français sera prise en considération. 

<br/><b>12. Force Majeure</b><br/>
3wlife ne pourra être tenue pour responsable de l’inexécution de ses obligations due à un cas de force majeure au sens généralement défini et accepté par les tribunaux Français ou pour des causes échappant à son contrôle. 

<br/><b>13. Droit applicable, juridictions, compétences</b><br/>
Les présentes Conditions d’Utilisation sont régies par la loi française. Tous litiges auxquels pourraient donner lieu l’utilisation de la plateforme seront soumis aux tribunaux compètent du ressort de la cours d’appel de Versailles. 

  </div>
</div>
