import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-politique-cookies',
  templateUrl: './politique-cookies.component.html',
  styleUrls: ['./politique-cookies.component.scss']
})
export class PolitiqueCookiesComponent implements OnInit {

  constructor(private matDialogRef: MatDialogRef<PolitiqueCookiesComponent>) { }

  ngOnInit(): void {
  }

  public closeModale(): void {
    this.matDialogRef.close();
  }

}
