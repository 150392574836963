import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public basePath: string;
  public token: string ;

  constructor() {
    this.basePath = environment.apiUrl;
    this.token = sessionStorage.getItem('token');
  }
  public newHeader(): HttpHeaders {
      return new HttpHeaders({'Content-Type': 'application/json'});
  }
}
