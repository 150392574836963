import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {YourTtpRoleComponent} from './containers/your-ttp-role/your-ttp-role.component';

const routes: Routes = [
  {path: 'view', component: YourTtpRoleComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  TtpRoleRoutingModule { }
