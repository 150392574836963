import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INotifications } from '../models/i-notifications';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends HttpService {

  constructor(protected http: HttpClient)
  {
    super();
  }

  getNotification(): Observable<INotifications>{
    return this.http.get<INotifications>(this.basePath + '/notification/');
  }
  updateNotifications(data: any): Observable<any>{
    return this.http.put( this.basePath + '/notification/', data);
  }
  updateAlerte(data: any): Observable<any>{
    return this.http.put( this.basePath + '/notification/alerte', data);
  }
  addNotifications(data: any): Observable<any>{
    return this.http.post( this.basePath + '/notification/', data);
  }
  addAlertes(data: any): Observable<any>{
    return this.http.post( this.basePath + '/notification/alerte', data);
  }
  getAlert(): Observable<INotifications>{
    return this.http.get<INotifications>(this.basePath + '/notification/alerte');
  }
}
