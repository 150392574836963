import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TrustedTpComponent} from './containers/trusted-tp/trusted-tp.component';

const routes: Routes = [
  {path: 'view', component: TrustedTpComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  TrustedTpRoutingModule { }
