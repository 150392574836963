import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {IUser} from '../../../shared/models/i-user';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {mergeMap, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-link-user',
  templateUrl: './link-user.component.html',
  styleUrls: ['./link-user.component.scss']
})
export class LinkUserComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private registrationService: AuthenticationService,
              private router: Router ,
              private toastr: ToastrService,
              public dialogRef: MatDialogRef<LinkUserComponent>,
              public translate: TranslateService) { }

  linkUser: FormGroup ;
  hide: boolean ;


  private initForm(user: IUser = null): any {
    this.linkUser = this.formBuilder.group({
      username: [ null , [Validators.required, Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]],
      password: [null, Validators.required]
    });
  }

public login(): void {
    if (this.linkUser.valid) {
      const payload = {
        accessToken : this.data.accessToken
      };
      this.registrationService.login(this.linkUser.getRawValue()).pipe(
        switchMap((result: any) => {
          let msg = this.translate.get('toastr.info.you_are_connected');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          sessionStorage.setItem('token' , result.token);
          sessionStorage.setItem('username' , result.data.fullName);
          sessionStorage.setItem('lang', result.data.lang);
          this.translate.use(sessionStorage.getItem('lang'));
          return this.registrationService.linkUser(payload);
        }))
        .subscribe(
          (res) => {
            this.router.navigate(['/dashboard']);
            this.dialogRef.close();
            let msg = this.translate.get('toastr.info.your_account_has_been_linked_to_success');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          },
        (err) => {
            console.log(err);
          }
        );

    }else{
      ( Object as any).values(this.linkUser.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
    sessionStorage.clear();
  }

}
