import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/shared/models/i-user';
import { PartnerService } from 'src/app/shared/services/partner.service';
import { PartnerAddComponent } from '../partner-add/partner-add.component';
import { PartnerEditComponent } from '../partner-edit/partner-edit.component';
const ELEMENT_DATA: IUser[] = [];
@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent implements OnInit , AfterViewInit {

  constructor(private partnerService: PartnerService, public dialog: MatDialog,  private toastr: ToastrService , public translate: TranslateService ) { }


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'email', 'firstName', 'lastName', 'phone' , 'actions' ];
  dataSource = new MatTableDataSource<IUser>();
  private partnersData: IUser[] = [];

  partner: IUser[] = [];
  partnerGroup: FormGroup;
  hide = true;


  ngOnInit(): void {
    this.getPartner();
  }
  openAddPartnerDialog(): void{
    const dialogRef = this.dialog.open( PartnerAddComponent );
    dialogRef.afterClosed().subscribe(result => {
      this.getPartner();
    });
  }
  openEditPartnerDialog(partner: IUser): void{
    const dialogRef = this.dialog.open(PartnerEditComponent, {
      data: {
        mode: 'Edit',
        partner
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPartner();
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.data = ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   }
   getPartner(): void{
    this.partnerService.getPartner().subscribe(
      (result: any) => {
        this.partnersData = result.data;
        this.dataSource.data = this.partnersData;

      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
}
