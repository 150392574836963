<h2 mat-dialog-title>{{'modal_delete.delete_trustedtp.title' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="box-container">
    <span class="box-icon material-icons">warning_amber</span>
    <p>{{'modal_delete.delete_trustedtp.content' | translate}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="delete()">{{'modal_delete.delete_trustedtp.button.yes' | translate}}</button>
  <button mat-raised-button color="warn" mat-dialog-close>{{'modal_delete.delete_trustedtp.button.no' | translate}}</button>
</mat-dialog-actions>
