import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AccountService} from '../../../shared/services/account.service';
import {ICategorie} from '../../../shared/models/i-categorie';
import { ToastrService } from 'ngx-toastr';
import {formatDate} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any ,
      private formBuilder: FormBuilder,
      private accountService: AccountService,
      private toastr: ToastrService,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<AccountEditComponent>,
      @Inject(LOCALE_ID) private readonly locale: string
    ) {}

  isLinear = true;
  hide = true;

  categories: ICategorie[] = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  today = new Date();

  ngOnInit(): void {
    this.initForm();
    this.getCategorie();
  }

  initForm(): void{
    if (this.data.mode === 'Edit'){
/*      this.firstFormGroup = this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]]
      });*/
      if (this.data.account.criticality){
        this.secondFormGroup = this.formBuilder.group({
          category: [{value: this.data.account.category, disabled: false}, Validators.required],
          account: [{value: this.data.account.account, disabled: true}, Validators.required],
          company: [{value: this.data.account.company, disabled: true}, Validators.required],
          note: [{value: this.data.account.note, disabled: false}],
          information: [{value: this.data.account.information, disabled: false}],
          dueDate: [{value: this.data.account.dueDate, disabled: true}],
        });
      }else {
        this.secondFormGroup = this.formBuilder.group({
          category: [{value: this.data.account.category, disabled: false}, Validators.required],
          account: [{value: this.data.account.account, disabled: false}, Validators.required],
          company: [{value: this.data.account.company, disabled: false}, Validators.required],
          note: [{value: this.data.account.note, disabled: false}],
          information: [{value: this.data.account.information, disabled: false}],
          dueDate: [{value: this.data.account.dueDate, disabled: false}],
        });
      }
    }else {
      this.secondFormGroup = this.formBuilder.group({
        category: [{value: '', disabled: false}, Validators.required],
        account: [{value: '', disabled: false}, Validators.required],
        company: [{value: '', disabled: false}, Validators.required],
        note: [{value: '', disabled: false}],
        dueDate: [{value: '', disabled: false}],
        information: [{value: '', disabled: false}],
      });
    }
  }

  getCategorie(): void{
    this.accountService.getCategories().subscribe(
      (result: any) => {
        console.log(result);
        this.categories = result;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  public save(): void {
    if (this.secondFormGroup.valid) {
      const payload: any = this.secondFormGroup.getRawValue();
      payload.dueDate = payload.dueDate ? formatDate(payload.dueDate, 'yyyy-MM-dd', this.locale) : null;
      if (this.data.mode === 'Edit'){
        /*payload.password = this.firstFormGroup.getRawValue().password;*/
        this.accountService.editAccount(payload , this.data.account.id).subscribe(
          (result) => {
            let msg = this.translate.get('toastr.info.account_has_been_modified');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.dialogRef.close();
          },
          (error) => {
            this.toastr.error(error.error.message);
          }
        );
      }else {
        this.accountService.addAccount(payload).subscribe(
          (result) => {
            let msg = this.translate.get('toastr.info.account_has_been_added');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.dialogRef.close();
          },
          (error) => {
            this.translate.get(error.error.message).subscribe(value => {
              this.toastr.error(value);
            });
          }
        );
      }
    } else {
      ( Object as any).values(this.secondFormGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }
}
