<div class="wrapper">
    <div class="sidebar" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" ></div>
    </div>
    <div class="main-panel">
        <app-header (toggle)="toggle()"></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>

