import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService{

  constructor(protected http: HttpClient) {
    super();
  }

  public changePassword(data: any): any{
    const header = this.newHeader();
    return this.http.post(this.basePath + '/user/change_password', data, {headers: header});
  }
}
