import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import {MarketingBlocService} from '../../../shared/services/marketing-bloc.service';
import {IMarketing} from "../../models/i-marketing";
import {SafeHtmlPipe} from "../../pipes/safe-html.pipe";
import {HeaderService} from "../../services/header.service";

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
  providers: [
    SafeHtmlPipe
  ]
})
export class MarketingComponent implements OnInit {
  public isUser: boolean;
  public isTrustedTp: boolean;
  public page;
  public lang = sessionStorage.getItem('lang');
  @Input() public pageName !: string;
  public marketingBlocs: IMarketing;
  constructor(public auth: AuthenticationService,
              private router: Router, private marketingBlocService: MarketingBlocService, private headerService: HeaderService) { }
  ngOnInit(): void {
    this.headerService.languageChange.subscribe((value)=>{
      this.lang = value;
    });
    this.page = this.router.url.trim().split('/')[1];

    const userRoles = this.auth.readToken().roles;
    userRoles.indexOf('ROLE_3W_USER') === -1 ? this.isUser = false : this.isUser = true;
    userRoles.indexOf('ROLE_TRUSTED_TP') === -1 ? this.isTrustedTp = false : this.isTrustedTp = true;

    this.marketingBlocService.getBlocByUserAndProfile(this.page, this.isUser? 'ROLE_3W_USER': 'ROLE_TRUSTED_TP').subscribe(
      (result: any) => {
        this.marketingBlocs = result.data;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
}
