<div class="content" xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-xl-9">
              <div class=" card card-user welcome">
                  <p class=" card-text"></p>
                  <div class=" author">
                    <div class=" block block-one"></div>
                    <div class=" block block-two"></div>
                    <div class=" block block-three"></div>
                    <div class=" block block-four"></div>
                  </div>
                  <div class=" card-description">
                    <div class="col-12">
                      <h4 class="bienvenue">{{"dashboard.welcome_msg"|translate}} 3wlife</h4>
                    </div>
                    <div class="row left-text-style body">
                      <div class="col-md-5">
                        <ul>
                          <li><span> {{"dashboard.your_last_connexion"|translate}} {{loginDate}} <br></span></li>
                          <li *ngIf="countComptes > 1 && isUser"> <span> <span>{{"dashboard.you_have_listed"|translate}}</span>  {{countComptes}} <span>{{"dashboard.accounts"|translate}}</span>
                              <br></span></li>
                              <li *ngIf="countComptes == 1 && isUser"> <span> {{"dashboard.you_have_listed"|translate}} {{countComptes}} {{"dashboard.account"|translate}}
                                <br></span></li>
                          <li *ngIf="countComptes > 1 && isUser"><span> {{"dashboard.your_account_added_at"|translate}} {{
                              dateAccount}} <br></span></li>
                        </ul>
                      </div>
                      <div class="col-md-7">
                        <ul>
                          <li *ngIf="countComptes == 0 && isUser "><span>{{"dashboard.not_yet_listed_account"|translate}}
                              <br></span>
                          </li>
                          <li *ngIf="havingTrustedTp >1 && isTrustedTp"><span> {{havingTrustedTp}} {{"dashboard.user_have_appointed_trustedtp"|translate}}</span>
                          </li>
                          <li *ngIf="havingTrustedTp ==1 && isTrustedTp"><span> {{havingTrustedTp}} {{"dashboard.user_have_appointed_trustedtp"|translate}}</span>
                        </li>
                          <li *ngIf="countTrustedTp <1 && isUser"><span>{{"dashboard.you_have_not_named_trusted_party_accepted_office"|translate}}
                              {{"dashboard.recommend_to_nominate_one"|translate}}</span></li>
                          <li *ngIf="countTrustedTp== 1 && isUser"><span>{{"dashboard.you_have_named_one_trustedtp_recommend_you_abboint_second_one"|translate}}</span>
                          </li>
                          <li *ngIf="countTrustedTp> 1 && isUser"><span>{{"dashboard.you_have_appointed_tow_trustedtp"|translate}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="col-md-12">
              <div>
                <span>
                  <app-notifications></app-notifications>
                </span>
              </div>
            </div>
            <div class="col-md-12 wlife-description" *ngIf="isTrustedTp">

              <h4><span [innerHTML]="'dashboard.you_have_accepted_responsability_of_trusted_third_party'|translate"></span><span [innerHTML]="'dashboard.that_one_of_your_relatives_told_you' | translate"></span>.</h4>
              <p><span [innerHTML]="'dashboard.trusted_tp.p1'|translate"></span></p>
              <p><span [innerHTML]="'dashboard.trusted_tp.p2'|translate"></span></p>
              <p><span [innerHTML]="'dashboard.trusted_tp.p3'|translate"></span></p>
              <p><span [innerHTML]="'dashboard.trusted_tp.p4'|translate"></span></p>
              <p><span [innerHTML]="'dashboard.trusted_tp.p5'|translate"></span></p>
            </div>
        </div>
        <div class="col-xs-12 col-xl-3">
          <app-marketing></app-marketing>
        </div>

      </div>
  </div>
</div>
