import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { compareValidator } from 'src/app/shared/directives/compare.directive';
import { IUser } from 'src/app/shared/models/i-user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  constructor(
    private router: Router ,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private registrationService: AuthenticationService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  userId: string;

  recoveryPassword: FormGroup ;
  public hide = [true, true];
  private initForm(): any {
    this.recoveryPassword = this.formBuilder.group({
        password: [null, [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{7,30}$/)]],
        confirmPassword: [null, [Validators.required, compareValidator('password')]],
    });

  }
  ngOnInit(): void {
    this.userId = this.route.snapshot.params.userId;
    this.getUser();
    this.initForm();
  }
  getUser(): void {
    this.registrationService.userInfo(this.userId).subscribe(
      (result: any) => {
        if (!result.data.havePasswordRecovery && !result.data.havePasswordRecovery) {
          this.translate.get('invalid_url').subscribe(value => {
            this.toastr.error(value);
          });
          this.router.navigate(['/auth/']);
        }
      },
      (error: any) => {
        this.translate.get('invalid_url').subscribe(value => {
          this.toastr.error(value);
        });
        this.router.navigate(['/auth/']);
      }
    );
  }
  public recoverypassword(): void {
    if (this.recoveryPassword.valid) {
      this.registrationService.recoverypasword(this.recoveryPassword.getRawValue(), this.userId).subscribe(
        (result: any) => {
          this.router.navigate(['/auth/login']);
        },
        (error) => {
          this.translate.get('mail_invalid').subscribe(value => {
            this.toastr.error(value);
          });
        }
      );
    }else{
      ( Object as any).values(this.recoveryPassword.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

}
