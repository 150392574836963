import {AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {ToastrService} from 'ngx-toastr';
import {ContractAccountService} from '../../../shared/services/contract-account.service';
import {MatStepper} from '@angular/material/stepper';
import {ContractAccountListComponent} from '../contract-account-list/contract-account-list.component';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver-es';
import {ContractService} from '../../../shared/services/contract.service';
import {IContract} from '../../../shared/models/i-contract';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import {of} from "rxjs";

@Component({
  selector: 'app-contract-account-loader',
  templateUrl: './contract-account-loader.component.html',
  styleUrls: ['./contract-account-loader.component.scss']
})
export class ContractAccountLoaderComponent implements OnInit, AfterViewInit {

  public descption: string;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild(ContractAccountListComponent) ContractFilesListe: ContractAccountListComponent;
  checkContractForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public files: NgxFileDropEntry[] = [];
  public file: File;
  public relativePath: string;
  public hide = true;
  public contract: IContract = null;
  public islinear = true;
  commentFC = new FormControl();

  constructor(private formBuilder: FormBuilder,
              private contractAccountService: ContractAccountService,
              private contractService: ContractService,
              private toastr: ToastrService,
              public translate: TranslateService,
              private _ngZone: NgZone
  ) {
  }

  dropped(files: NgxFileDropEntry[]): void {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
          this.relativePath = droppedFile.relativePath;
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        let msg = this.translate.get('toastr.error.invalid_file_format');
        msg.subscribe(value => {
          this.toastr.error(value);
        });
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  fileOver(event): void {
    console.log(event);
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  fileLeave(event): void {
    console.log(event);
  }

  initForm(): void {
    this.firstFormGroup = this.formBuilder.group({
      group: [{value: '', disabled: false}, Validators.required],
      company: [{value: '', disabled: false}, Validators.required],
      code_contract: [{value: '', disabled: false}, Validators.required],
      secret_contract: [{value: '', disabled: false}, Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      description: [{value: this.contract?.description != null? this.contract.description : "", disabled: false}],
    });
    this.checkContractForm = this.formBuilder.group({
        form: [this.firstFormGroup, null, this.checkedContract.bind(this)]
      }
    )
  }

  checkedContract(control: AbstractControl): ValidationErrors | null {
    return of(!!this.contract ? null : {hasError: 'no contract found'})
  }

  verifyContract(stepper: MatStepper): void {
    if (this.firstFormGroup.valid) {
      const payload = this.firstFormGroup.getRawValue();
      this.contractAccountService.verifyContract(payload).subscribe(
        (result) => {
          if (result.data.accept) {
            this.contract = result.data.contract;
            this.contract.password = payload.secret_contract;
            sessionStorage.setItem('contract', JSON.stringify(this.contract));
            this.ContractFilesListe.getContractFiles(this.contract.id);
            this.initForm();
          } else {
            let msg = this.translate.get('toastr.error.invalid_data');
            msg.subscribe(value => {
              this.toastr.error(value);
            });
          }
        },
        (error) => {
          this.toastr.error(error.error.message);
        }
      );
    } else {
      (Object as any).values(this.firstFormGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }


  upload(payload): void {
    this.contractAccountService.uploadContract(payload, this.file, this.relativePath).subscribe(
      (result) => {
        if (result.data.success) {
          let msg = this.translate.get('toastr.info.file_loaded');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          this.ContractFilesListe.getContractFiles(this.contract.id);
        } else {
          let msg = this.translate.get('toastr.error.error_occurred_while_downloading_try_again');
          msg.subscribe(value => {
            this.toastr.error(value);
          });
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  uploadContract(): void {
    let payload;
    if (this.contract) {
      payload = {
        group: this.contract.group,
        company: this.contract.company,
        code_contract: this.contract.codeContract,
        secret_contract: this.contract.password,
        description: this.secondFormGroup.get('description')?.value
      };
      this.upload(payload);
      const contrat = this.contract;
      contrat.description = this.secondFormGroup.get('description')?.value;
      sessionStorage.setItem('contract', JSON.stringify(this.contract));

    } else {
      if (this.firstFormGroup.valid) {
        payload = this.firstFormGroup.getRawValue();
        this.upload(payload);
      } else {
        (Object as any).values(this.firstFormGroup.controls).forEach(control => {
          control.markAsDirty();
        });
      }
    }
  }

  downloadFile(): void {
    this.contractAccountService.downloadFileCSV(this.contract.id).subscribe(
      (result: any) => {
        const today = new Date().toISOString().slice(0, 10);
        saveAs(result, 'contract-' + this.contract.codeContract + '-' + today + '.csv', {autoBom: true});
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onCommentChange(): void {
    console.log(this.commentFC.value);
  }

  logoutContract(stepper): void {
    sessionStorage.removeItem('contract');
    this.contract = null;
    this.ContractFilesListe.clearListe();
    stepper.reset();
  }

  autoGrowTextZone(e) {
    e.target.style.height = '0px';
    e.target.style.height = (e.target.scrollHeight + 25) + 'px';
  }

  isFileAllowed(fileName: string): boolean {
    let isFileAllowed = false;
    const allowedFiles = ['.csv'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  ngOnInit(): void {
    const item = sessionStorage.getItem('contract');
    this.contract = item ? JSON.parse(item) : null;
    this.initForm();
  }

  ngAfterViewInit(): void {
    if (this.contract) {
      this.ContractFilesListe.getContractFiles(this.contract.id);
      this.islinear = false;
    }
  }

}
