import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from './http.service';
import {IUser} from '../models/i-user';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpService {

  public refreshUser$ = new BehaviorSubject<boolean>(false);

  constructor(protected http: HttpClient, protected jwtHelper: JwtHelperService) {
    super();
  }

  public getToken(): string {
    return sessionStorage.getItem('token');
  }

  public readToken(): any {
    const token = sessionStorage.getItem('token');
    return this.jwtHelper.decodeToken(token);
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  public registrateUser(data: any, userId: any = null): any {
    if (userId) {
      return this.postRegistrationUser(data, userId);
    } else {
      const header = this.newHeader();
      return this.http.post(this.basePath + '/auth/registration', data, {headers: header});
    }
  }

  public login(data: any): any {
    const header = this.newHeader();
    return this.http.post(this.basePath + '/auth/login_check', data, {headers: header});
  }

  public forgetpasword(data: any): any {
    return this.http.put(this.basePath + '/auth/resetpassword', data);
  }

  public recoverypasword(data: any, id: string): any {
    return this.http.put(this.basePath + '/auth/recoverypassword/' + id, data);
  }

  public userInfo(userId: string): any {
    return this.http.get(this.basePath + '/auth/userinfo/' + userId);
  }

  public updateUser(data: any): any {
    const header = this.newHeader();
    return this.http.post(this.basePath + '/profil/user', data, {headers: header});
  }

  public postRegistrationUser(data: any, userId: number): any {
    const header = this.newHeader();
    return this.http.post(this.basePath + '/auth/postregistration/' + userId, data, {headers: header});
  }

  public getUser(userId: string): Observable<IUser> {
    const header = this.newHeader();
    return this.http.get<IUser>(this.basePath + '/auth/user/' + userId);
  }

  public getPostRegistrationPartner(accessToken: string): Observable<IUser> {
    const header = this.newHeader();
    return this.http.get<IUser>(this.basePath + '/auth/partner/postregistration/' + accessToken);
  }

  public getCurrentUser(): Observable<{ data: IUser }> {
    const header = this.newHeader();
    return this.http.get<{ data: IUser }>(this.basePath + '/currentUser');
  }

  public linkUser(data): Observable<any> {
    return this.http.post(this.basePath + '/account/associate/user', data);
  }

  public emailCheck(email: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/auth/checkemail/${email}`);
  }

  public getdashboard(): Observable<any> {
    return this.http.get(this.basePath + '/dashboard/');
  }
}
