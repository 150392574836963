import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/shared/models/i-user';
import { PartnerService } from 'src/app/shared/services/partner.service';
import {IPays} from '../../../shared/models/i-pays';
import * as countries from '../../../../assets/data/Countries.json';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.scss']
})
export class PartnerAddComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ,
               private partnerService: PartnerService,
               private toastr: ToastrService,
               private translate: TranslateService,
               public dialogRef: MatDialogRef<PartnerAddComponent>,
               private formBuilder: FormBuilder,
  ) { }
  public partner: IUser[] = [];
  public partnerGroup: FormGroup;
  public hide = true;
  public listCountries: IPays[] = countries.list;
  public listLang: string[] = [ 'fr' , 'en' , 'es', 'de' , 'it'];

  ngOnInit(): void {
    this.initForm();
  }

  public save(): void {
    if (this.partnerGroup.valid) {
      const payload: any = this.partnerGroup.getRawValue();
      this.partnerService.addPartner(payload).subscribe(
        (result) => {
          this.translate.get('toastr.info.partner_has_been_added').subscribe(value => {
            this.toastr.info(value);
          });
           this.dialogRef.close();
        },
        (error) => {
          const err = error.error.errors[0].code ;
          switch (err) {
            case 'email.erreur.existant':
              this.translate.get('toastr.info.partner_already_existing').subscribe(value => {
                this.toastr.info(value);
              });
              break;
            default:
              break;
          }
        }
      );
    }else {
      ( Object as any).values(this.partnerGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  initForm(): void{
    this.partnerGroup = this.formBuilder.group({
      email: [{value: '', disabled: false}, [Validators.required,
        Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]],
      firstName: [{value: '', disabled: false}, Validators.required],
      lastName: [{value: '', disabled: false}, Validators.required],
      password: [{value: '', disabled: false}, Validators.required],
      phone: ['', [Validators.pattern(/^\d{10}$/)]],
      address: [{value: '', disabled: false}],
      postalCode: [{value: '', disabled: false}],
      city: [{value: '', disabled: false}],
      country: [{value: '', disabled: false}],
      lang : [{value: '', disabled: false}],
    });

  }
}
