import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {IAccount} from '../models/i-account';

@Injectable({
  providedIn: 'root'
})
export class ContractAccountService extends HttpService{

  constructor(protected http: HttpClient) {
    super();
  }


  verifyContract(data: any ): Observable<any>{
    return this.http.post( this.basePath + '/partner/contract/verify', data);
  }

  public uploadContract(data: any , file: File , name: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    const formData = new FormData();
    formData.append('file', file, name);
    formData.append('group', data.group);
    formData.append('company', data.company);
    formData.append('code_contract', data.code_contract);
    formData.append('secret_contract', data.secret_contract);
    formData.append('description', data.description);
    return this.http.post(this.basePath + '/partner/contract/load', formData, {params, headers});
  }

  getContractLoadingList(contractId: string): Observable<any>{

    return this.http.get<any>(this.basePath + '/partner/contract/' + contractId + '/loading-list');
  }

  loadedFileDetail(id: string ): Observable<any>{
    return this.http.get( this.basePath + '/partner/contract/loading/' + id );
  }

  changeStatusOfLoading(id: string, data: any): Observable<any>{
    return this.http.put( this.basePath + '/partner/contract/loading/' + id , data);
  }

  downloadFileCSV(contractId: string ): Observable<any>{
    return this.http.post( this.basePath + '/partner/contarct/' + contractId + '/export', {}, { responseType: 'blob' });
  }

}
