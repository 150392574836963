import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ContractAccountLoaderComponent } from './containers/contract-account-loader/contract-account-loader.component';
import {ContractAccountRoutingModule} from './contract-account-routing.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxFileDropModule} from 'ngx-file-drop';
import { ContractAccountListComponent } from './containers/contract-account-list/contract-account-list.component';
import { ContractAccountRepportComponent } from './containers/contract-account-repport/contract-account-repport.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';




@NgModule({
  declarations: [
    ContractAccountLoaderComponent,
    ContractAccountListComponent,
    ContractAccountRepportComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ContractAccountRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSortModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FormsModule,
    NgxFileDropModule,
    MatStepperModule,
    MatTooltipModule,
    MatListModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
  })
  ]
})
export class ContractAccountModule { }
export function HttpLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
