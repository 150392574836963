<div class="content account-list">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-xs-12 col-xl-9">
        <div>
          <div class="home" >
            <nav mat-tab-nav-bar mat-align-tabs="center">
              <a mat-tab-link
                 (click)="applyGeneralFilter()"
                 [active]="activeGeneral"
              >
                <span class="material-icons md-50" style="color: #033c75" >folder</span>
                <div class="folderTitel">{{"list.titles.my_accounts"|translate}}</div>
              </a>
            </nav>
          </div>
          <div >
            <nav mat-tab-nav-bar >
              <a mat-tab-link *ngFor="let filter of filters"
                 (click)="applyCategorieFilter(filter)"
                 [active]="activefilter == filter">
                <span class="material-icons md-50" [style]="filter.color">{{filter.icon}}</span>
                <div class="folderTitel">{{filter.name | translate}}</div>
              </a>
            </nav>
          </div>
        </div>
        <div class="card">
          <div class=" card-header">
            <h5 class=" title">
              <button mat-raised-button color="primary" (click)="openAddAccountDialog()" style="float: right">{{"forms.titles.add_account"|translate}}</button>
            </h5>
          </div>
          <div class=" card-body">

            <div class="table-container">
              <table mat-table [dataSource]="dataSource"  matSort matSortActive="updatedAt" matSortDirection="desc" >

                <ng-container matColumnDef="category" >
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"forms.champs.category"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.category |translate}} </td>
                </ng-container>

                <ng-container matColumnDef="account">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"forms.champs.account"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.account}} </td>
                </ng-container>

                <ng-container matColumnDef="company">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"forms.champs.company"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.company}} </td>
                </ng-container>

                <ng-container matColumnDef="note">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.personal_notes"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                </ng-container>

                <ng-container matColumnDef="dueDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"forms.champs.opening_date"|translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.dueDate |date: 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="information">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.info_crypted"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.information}} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"list.columns.updated_at"|translate}}  </th>
                  <td mat-cell *matCellDef="let element"> {{element.updatedAt |date: 'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let element" class="options">
                    <button color="basic" mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="openEditAccountDialog(element)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"list.button.update"|translate}} </span>
                      </button>
                      <button mat-menu-item (click)="openDeleteAccountDialog(element)" *ngIf="!element.linkedToContract">
                        <mat-icon>delete</mat-icon>
                        <span>{{"list.button.delete"|translate}}</span>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'criticality': row.linkedToContract}"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-xl-3">
        <app-marketing></app-marketing>
      </div>
    </div>
  </div>
</div>


