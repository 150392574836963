<div class="logo">
    <a routerLink="dashboard" >
        <span>3w</span>life
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title | translate}}</p>
            </a>
        </li>
    </ul>
</div>

