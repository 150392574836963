import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IPays} from '../../../shared/models/i-pays';
import * as countries from '../../../../assets/data/Countries.json';
import {IUser} from '../../../shared/models/i-user';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {formatDate} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import {HeaderService} from "../../../shared/services/header.service";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  public form: FormGroup;
  public listCountries: IPays[] = countries.list;
  public userId: number;
  public user: IUser;
  public currentEmail: string;
  public listLang: string[] = [ 'fr' , 'en' , 'es', 'de' , 'it'];
  public header: HeaderComponent;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: AuthenticationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private readonly locale: string,
    public translate: TranslateService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.registrationService.getCurrentUser().subscribe(
      (result: any) => {
        this.userId = result.data.id;
        this.user = result.data as IUser;
        this.currentEmail = this.user.email;
        this.initForm(this.user);
      },
      (error) => {
        let msg = this.translate.get('toastr.error.username_invalid');
        msg.subscribe(value => {
          this.toastr.error(value);
        });
      });
  }
  public refrechNameAndLanguage(): void
  {
    const payload = this.form.getRawValue();
    const fullName = payload.firstName + ' ' + payload.lastName;
    sessionStorage.setItem('username', fullName);
    this.headerService.toggleLanguageChange(payload.lang)
    this.translate.use(payload.lang);
    sessionStorage.setItem('lang', payload.lang);
  }

  public save(): void {
    if (this.form.valid){
      if (this.currentEmail !== this.form.controls.email.value) {
        this.form.controls.emailIsModified.setValue(true);
      }
      const payload = this.form.getRawValue();
      payload.birthDate = formatDate(payload.birthDate, 'yyyy-MM-dd', this.locale);
      this.registrationService.updateUser(payload).subscribe(
        (result) => {
          console.log(result);
          if (result.data?.token) {
            sessionStorage.setItem('token' , result.data.token);
            sessionStorage.setItem('lang' , result.data.token);
          }
          if (this.currentEmail !== this.form.controls.email.value) {
            let msg = this.translate.get('toastr.info.profil_has_been_modified_will_use_new_email_adress');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          } else {
            let msg = this.translate.get('toastr.info.profil_has_well_been_modified');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          }
          this.refrechNameAndLanguage();
          this.registrationService.refreshUser$.next(true);
        },
        (error) => {
          if (error.status === 400 && error.error.errors) {
            const errors = error.error.errors;
            for (let i = 0; i < errors.length; i++) {
              if (errors[i].code === 'email.erreur.existant') {
                let msg = this.translate.get('toastr.error.mail_adress_already_used');
                msg.subscribe(value => {
                  this.toastr.error(value);
                });
                return;
              }
            }
          }
          this.toastr.error(error.error.message);
        }
      );
     }else {
      ( Object as any).values(this.form.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  private initForm(user: IUser): any {
    this.form = this.formBuilder.group({
      email: [user ? user.email : null, [Validators.required,
        Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]],
      firstName: [user ? user.firstName : null, Validators.required],
      lastName: [user ? user.lastName : null, Validators.required],
      phone: [user ? user.phone : null, [Validators.pattern(/^\d{10}$/)]],
      birthDate: [user ? user?.birthDate : null],
      address: [user ? user?.address : null],
      postalCode: [user ? user.postalCode?.toString() : null],
      city: [user ? user.city : null],
      country: [user ? user.country : null],
      lang: [user ? user.lang : null],
      communicationPartenaire: [user?.communicationPartenaire],
      emailIsModified: [false]
    });
  }


}
