<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"forms.titles.change_your_password"|translate}} </h5>
          </div>
          <div class=" card-body">
            <div>
              <form *ngIf="form" [formGroup]="form" (submit)="save()">
                <mat-form-field class="full-width">
                  <input matInput placeholder='{{"forms.champs.your_current_password"|translate}}' [type]="hide[0] ? 'password' : 'text'"
                         formControlName="oldPassword" autocomplete="current-password" required>
                  <mat-icon matSuffix (click)="hide[0] = !hide[0]">{{hide[0] ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-hint *ngIf="form.controls.oldPassword.dirty && form.controls.oldPassword.hasError('pattern')">
                    <p class="red-text">{{"forms.alerte.invalid_password"|translate}}.</p>
                  </mat-hint>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <input matInput placeholder='{{"forms.champs.your_new_password"|translate}}' [type]="hide[1] ? 'password' : 'text'"
                         formControlName="newPassword"
                         matTooltip='{{"forms.alerte.tooltip_password"|translate}}'
                         required>
                  <mat-icon matSuffix (click)="hide[1] = !hide[1]">{{hide[1] ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-hint *ngIf="form.controls.newPassword.dirty && form.controls.newPassword.hasError('pattern')">
                    <p class="red-text">{{"forms.alerte.invalid_password"|translate}}.</p>
                  </mat-hint>

                </mat-form-field>

                <mat-form-field class="full-width">
                  <input matInput placeholder='{{"forms.champs.confirm_your_new_password"|translate}}'
                         [type]="hide[2] ? 'password' : 'text'"
                         autocomplete="confirm-new-password"
                         formControlName="confirmNewPassword" required>
                  <mat-icon matSuffix (click)="hide[2] = !hide[2]">{{hide[2] ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-hint *ngIf="form.controls.confirmNewPassword.dirty && form.controls.confirmNewPassword.hasError('compare')">
                    <p class="red-text">{{"forms.alerte.passwords_not_indentique"|translate}}</p>
                  </mat-hint>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class=" card-footer">
            <div class=" button-container">
              <button class=" btn btn-fill btn-primary" (click)="save()" type="submit">{{"forms.button.save"|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

