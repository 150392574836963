import { HttpClient } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContract } from '../models/i-contract';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends HttpService {

  constructor(protected http: HttpClient)
  {
    super();
  }
  getContract(): Observable<IContract>{
    return this.http.get<IContract>(this.basePath + '/contract/');
  }
  getContractById(id: string): Observable<IContract>{
    return this.http.get<IContract>(this.basePath + '/contract/' + id);
  }
  addContract(data: any): Observable<any>{
    return this.http.post( this.basePath + '/contract/', data);
  }
  isActivated(data: any, id: string): Observable<any>{
    return this.http.post( this.basePath + '/contract/isactivated/' + id, data);
  }
  editContract(data: any , contractId): Observable<any>{
    return this.http.put( this.basePath + '/contract/' + contractId, data);
  }
}
