<div class=" card">
  <div class=" card-header">
    <h5 class=" title">{{"list.titles.list_contract_load_files" | translate}} </h5>
  </div>
  <div class=" card-body">

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="loadedAt" matSortDirection="desc">
        <div class="table-contain">
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef> {{"list.columns.file_name" |translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
        </ng-container>

        <ng-container matColumnDef="contract">
          <th mat-header-cell *matHeaderCellDef> {{"list.columns.contract"|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.contract}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> {{"list.columns.statut" |translate}} </th>
          <td mat-cell *matCellDef="let element">
            <i *ngIf="element.status" class="fa fa-circle" aria-hidden="true" style="color: #00b290" matTooltip="{{'state.valid'|translate}}"></i>
            <i *ngIf="!element.status"  class="fa fa-circle" aria-hidden="true" style="color: #fb6340" matTooltip="{{'state.in_progress'|translate}}"></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="loadedAt" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"list.columns.loaded_in" |translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.loadedAt}} </td>
        </ng-container>

        <ng-container matColumnDef="actions" >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="options">
            <button color="basic" mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="viewRepport(element)">
                <mat-icon>get_app</mat-icon>
                <span>{{"list.columns.report" |translate}}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </div>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
