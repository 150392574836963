import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountListComponent } from './containers/account-list/account-list.component';
import { MatTabsModule} from '@angular/material/tabs';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule} from '@angular/material/icon';
import { MatTableModule} from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatMenuModule} from '@angular/material/menu';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { MatSortModule } from '@angular/material/sort';
import { AccountEditComponent } from './containers/account-edit/account-edit.component';
import { AccountDeleteComponent } from './containers/account-delete/account-delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import {ReactiveFormsModule} from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarketingModule } from '../shared/components/marketing/marketing.module';




@NgModule({
  declarations: [
    AccountListComponent,
    AccountEditComponent,
    AccountDeleteComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    AccountRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSortModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  MatButtonModule,
  MarketingModule
  ]
})
export class AccountModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader
{
    return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
