<h2 mat-dialog-title>{{"modal_delete.delete_account.title"|translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="box-container">
    <span class="box-icon material-icons">warning_amber</span>
    <p>{{"modal_delete.delete_account.content"|translate}}?</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{"modal_delete.delete_account.button.no"|translate}}</button>
  <button mat-raised-button color="warn" (click)="delete()"   >{{"modal_delete.delete_account.button.yes"|translate}}</button>
</mat-dialog-actions>
