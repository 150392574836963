import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, Subject } from 'rxjs';
import { IMarketing } from 'src/app/shared/models/i-marketing';
import { MarketingBlocService } from 'src/app/shared/services/marketing-bloc.service';
import {TranslateService} from "@ngx-translate/core";
interface Page {
  value: string;
  viewValue: string;
}
interface Profile {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-addbloc',
  templateUrl: './addbloc.component.html',
  styleUrls: ['./addbloc.component.scss'],
})
export class AddblocComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private MarketingBlocService: MarketingBlocService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}
  pages: Page[] = [
    { value: 'dashboard', viewValue: 'sidebar.home' },
    { value: 'account', viewValue: 'sidebar.your_account' },
    { value: 'trusted-tp', viewValue: 'sidebar.your_trusted_third_party' },
    { value: 'ttp-role',  viewValue: 'sidebar.you_are_trusted_third_party'},
    { value: 'help',  viewValue: 'help.title'}
  ];
  blocId;
  profiles: Profile[] = [
    { value: 'ROLE_3W_USER', viewValue: 'sidebar.user' },
    { value: 'ROLE_TRUSTED_TP', viewValue: 'sidebar.trusted_third_party' },
  ];
  private editorSubject: Subject<any> = new AsyncSubject();
  public addBlockFormGroup :FormGroup;
  public marketing: IMarketing[] = [];

  initForm(): void {
    this.addBlockFormGroup = this.formBuilder.group({
      page: [{ value: '', disabled: false }, Validators.required],
      profile: [{ value: '', disabled: false }, Validators.required],
      langueFr: [{ value: '', disabled: false }],
      langueEng: [{ value: '', disabled: false }],
    });
  }

  ngOnInit(): void {
    this.initForm();
  }
  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  public save(): void {
    if (this.addBlockFormGroup.valid) {
      const payload: any = this.addBlockFormGroup.getRawValue();
      if (this.blocId) {
        this.MarketingBlocService.updateMarketingBloc(payload, this.blocId).subscribe(
          (result) => {
            let msg = this.translate.get('toastr.info.marketing_bloc_has_been_modified');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          }
        );
      } else {
        this.MarketingBlocService.addMarketingBloc(payload).subscribe(
          (result) => {
            let msg = this.translate.get('toastr.info.marketing_bloc_has_been_added');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          }
        );
      }

    } else {
      (Object as any)
        .values(this.addBlockFormGroup.controls)
        .forEach((control) => {
          control.markAsDirty();
        });
    }
  }
  public loadData(event): void {
    if (this.addBlockFormGroup.valid){
      this.fetchLanguages(this.addBlockFormGroup.controls.page?.value, this.addBlockFormGroup.controls.profile?.value);
    }

  }
  public fetchLanguages(page: string, profile: string) {

    this.MarketingBlocService.getBlocByUserAndProfile(page, profile).subscribe((result: any) => {
      if (result) {
        this.addBlockFormGroup.patchValue({
          langueFr: result.data?.langueFr,
          langueEng: result.data?.langueEng
        });
        this.blocId = result.data.id;
      }
    });
  }
}
