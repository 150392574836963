<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">
              {{"list.titles.contract_list"|translate}}
              <button mat-raised-button color="primary" (click)="openAddAccountDialog()" style="float: right">{{"forms.titles.add_contract"|translate}}</button>
            </h5>
          </div>
          <div class=" card-body">
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" >

                <ng-container matColumnDef="categorie">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.category"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.categorie | translate}} </td>
                </ng-container>

                <ng-container matColumnDef="group">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.group"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.group }} </td>
                </ng-container>

                <ng-container matColumnDef="company">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.company"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.company}} </td>
                </ng-container>

                <ng-container matColumnDef="codecontract">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.contract_code"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.codecontract}} </td>
                </ng-container>

                <ng-container matColumnDef="label">
                  <th mat-header-cell *matHeaderCellDef>  {{"forms.champs.label"|translate}}  </th>
                  <td mat-cell *matCellDef="let element"> {{element.label}} </td>
                </ng-container>

                <ng-container matColumnDef="criticality">
                  <th mat-header-cell *matHeaderCellDef > {{"forms.champs.criticality"|translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.criticality}} </td>
                </ng-container>

                <ng-container matColumnDef="activate" >
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle [(ngModel)]="element.isActivated" (click)="isActivated(!element.isActivated,element.id)" color="primary">
                      <span *ngIf="!element.isActivated">{{"list.button.activate"|translate}}</span>
                      <span *ngIf="element.isActivated">{{"list.button.deactivate"|translate}}</span>
                    </mat-slide-toggle>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions" >
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let element" class="options">
                    <button color="basic" mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="openEditAccountDialog(element)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"list.button.update"|translate}}</span>
                      </button>
                      <button mat-menu-item (click)="openShowAccountDialog(element)">
                        <mat-icon>list</mat-icon>
                        <span>{{"list.button.visualization"|translate}}</span>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
