import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {compareValidator} from '../../../shared/directives/compare.directive';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;
  public hide = [true, true, true];
  constructor(
    private formBuilder: FormBuilder,
    private registrationService: AuthenticationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  public save(): void {
    if (this.form.valid) {
      this.userService.changePassword(this.form.getRawValue()).subscribe(
        (result: any) => {
          this.translate.get("toastr.info.password_has_been_modified").subscribe(value => {
            this.toastr.info(value);
          });
          console.log('sucsses : ' + result);
          sessionStorage.removeItem('token' );
          sessionStorage.removeItem('username');
          sessionStorage.removeItem('lang');
        },
        (error) => {

          if (error.status === 400 && error.error) {
            this.translate.get(error.error.message).subscribe(value => {
              this.toastr.error(value);
            });
            return;
          }
          this.translate.get("toastr.error.password_incorrect").subscribe(value => {
            this.toastr.error(value);
          });
          console.log('erreur : ' + error);
        }
      );
    }
  }

  private initForm(): any {
    this.form = this.formBuilder.group({
      oldPassword: [null, [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      newPassword: [null, [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmNewPassword: [null, [Validators.required, compareValidator('newPassword')]],
    });
  }

}
