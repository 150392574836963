import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {IPays} from '../../../shared/models/i-pays';
import * as countries from '../../../../assets/data/Countries.json';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {IUser} from '../../../shared/models/i-user';
import {compareValidator} from '../../../shared/directives/compare.directive';
import {dateNaissanceValidator} from '../../../shared/directives/date-naissance-validator.directive';
import {ConditionsGeneralesComponent} from '../../../shared/components/conditions-generales/conditions-generales.component';
import {LinkUserComponent} from '../link-user/link-user.component';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public form: FormGroup;
  public listCountries: IPays[] = countries.list;
  public userId: string;
  public hide = [true, true];
  public listLang: string[] = ['fr', 'en', 'es', 'de', 'it'];
  public isInvitationPartner = false;
  public checkEmail;
  public lang: string;
  constructor(
    private formBuilder: FormBuilder,
    private registrationService: AuthenticationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    @Inject(LOCALE_ID) private readonly locale: string,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params.userId || this.route.snapshot.params.accessToken;
    this.isInvitationPartner = !!this.route.snapshot.params.accessToken;
    if (this.userId) {
      if (this.isInvitationPartner) {
        this.getUserbyPartner();
      } else {
        this.getUser();
      }
    } else {
      this.router.navigate(['auth/login']);
    }
    this.lang = this.translate.getDefaultLang();
  }

  public getUser(): void {
    this.registrationService.getUser(this.userId).subscribe(
      (result: any) => {
        this.translate.use(result.data.lang);
        if (result.data.isActivated) {
          this.translate.get('toastr.info.your_account_already_activated').subscribe(value => {
            this.toastr.info(value);
          });
          this.router.navigate(['auth/login']);
        } else {
          this.initForm(result.data as IUser);
        }
      },
      (error) => {
        this.translate.get('toastr.error.username_invalid').subscribe(value => {
          this.toastr.error(value);
        });
        // this.initForm();
        this.router.navigate(['auth/login']);
      }
    );
  }

  public getUserbyPartner(): void {
    this.registrationService.getPostRegistrationPartner(this.userId).subscribe(
      (result: any) => {
        this.translate.use(result.data.lang);
        this.initForm(result.data as IUser);
      },
      (error) => {
        if (this.lang == 'fr') {
          this.toastr.info('Jeton d\'accès invalide !');
        } else if (this.lang == 'en') {
          this.toastr.info('Access Token invalide !');
        }
        this.router.navigate(['auth/login']);
      }
    );
  }

  public save(): void {

    if (this.form.valid) {
      const payload = this.form.getRawValue();
      if (this.isInvitationPartner) {
        payload.accessToken = this.userId;
      }
      const userID = this.isInvitationPartner ? null : this.userId;
      this.registrationService.registrateUser(payload, userID).subscribe(
        (result) => {
          this.translate.get('toastr.info.profil_has_well_been_created').subscribe(value => {
            this.toastr.info(value);
          });
          this.router.navigate(['auth/login']);
        },
        (error) => {
          if (error.status === 400 && error.error.errors) {
            const errors = error.error.errors;
            for (let i = 0; i < errors.length; i++) {
              if (errors[i].code === 'email.erreur.existant') {
                this.translate.get('toastr.error.mail_adress_already_used').subscribe(value => {
                  this.toastr.info(value);
                });
                return;
              }
            }
          }
          this.toastr.error(error.error.message);
          console.log(error);
        }
      );
    } else {
      if (this.form.controls.conditions.invalid) {
        this.translate.get('toastr.error.please_check_general_conditions').subscribe(value => {
          this.toastr.info(value);
        });
      }
      (Object as any).values(this.form.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  public resetForm(): void {
    this.router.navigate( ['auth/login']);
  }

  public showModal(event): void {
    event.preventDefault();
    this.dialog.open(ConditionsGeneralesComponent, {
      closeOnNavigation: true,
      width: '600px',
      maxHeight: '90vh',
      disableClose: false
    });
  }

  public openLoginModal(): void {
    this.dialog.open(LinkUserComponent, {
      closeOnNavigation: true,
      width: '400px',
      disableClose: false,
      data: {
        email: this.form.controls.email.value,
        accessToken: this.userId
      }
    });
  }
  private initForm(user: IUser = null): any {
    this.form = this.formBuilder.group({
      email: [user ? user.email : null, [Validators.required,
        Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')], []],
      password: [null, [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmPassword: [null, [Validators.required, compareValidator('password')]],
      firstName: [user ? user.firstName : null, Validators.required],
      lastName: [user ? user.lastName : null, Validators.required],
      phone: [user ? user.phone : null, [Validators.pattern(/^\d{10}$/)]],
      birthDate: [user ? user.birthDate : null, [dateNaissanceValidator(), Validators.required]],
      address: [user ? user.address : null],
      postalCode: [user ? user.postalCode : null],
      city: [user ? user.city : null],
      country: [user ? user.country : null],
      lang: [user ? user.lang : null],
      conditions: [null, [this.conditionsValidator()]],
      communicationPartenaire: [null],
    });
  }

  private conditionsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return {conditionsInvalide: true};
      }
      return null;
    };
  }
}
