import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ICategorie } from 'src/app/shared/models/i-categorie';
import { IContract } from 'src/app/shared/models/i-contract';
import { AccountService } from 'src/app/shared/services/account.service';
import { ContractService } from 'src/app/shared/services/contract.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contract-add',
  templateUrl: './contract-add.component.html',
  styleUrls: ['./contract-add.component.scss']
})
export class ContractAddComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ,
               private contractService: ContractService,
               private toastr: ToastrService,
               public dialogRef: MatDialogRef<ContractAddComponent>,
               private formBuilder: FormBuilder,
               private accountService: AccountService,
               private translate: TranslateService,
  ) { }
  categories: ICategorie[] = [];
  contracts: IContract[] = [];
  contractGroup: FormGroup;
  hide = true;


  ngOnInit(): void {
   this.initForm();
   this.getCategorie();
  }

  public save(): void {
    if (this.contractGroup.valid) {
      const payload: any = this.contractGroup.getRawValue();
      payload.criticality = parseInt(payload.criticality);
      this.contractService.addContract(payload).subscribe(
        (result) => {
          this.translate.get("toastr.info.contract_has_been_added").subscribe(value => {
            this.toastr.error(value);
          });
          this.dialogRef.close();
        },
        (error) => {
          this.translate.get(error.error.message).subscribe(value => {
            this.toastr.error(value);
          });
        }
      );
    }else{
      ( Object as any).values(this.contractGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  initForm(): void{
    this.contractGroup = this.formBuilder.group({
      categorie: [{value: '', disabled: false}, Validators.required],
      group: [{value: '', disabled: false}, Validators.required],
      company: [{value: '', disabled: false}, Validators.required],
      codecontract: [{value: '', disabled: false}, Validators.required],
      password: [{value: '', disabled: false}, Validators.required],
      label: [{value: '', disabled: false}, Validators.required],
      criticality: [{value: null, disabled: false}, Validators.required],
    });

  }
  getCategorie(): void{
    this.accountService.getCategories().subscribe(
      (result: any) => {
        this.categories = result;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
}


