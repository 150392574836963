import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'wlife-app';
  selectedLayout = 'main-layout';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public translate: TranslateService) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedLayout = this.activatedRoute.firstChild.snapshot.data.selectedLayout;
      }
    });
  }

}
