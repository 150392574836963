import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/shared/models/i-user';
import { PartnerService } from 'src/app/shared/services/partner.service';
import {IPays} from '../../../shared/models/i-pays';
import * as countries from '../../../../assets/data/Countries.json';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private partnerService: PartnerService,
              private toastr: ToastrService,
              public dialogRef: MatDialogRef<PartnerEditComponent>,
              private formBuilder: FormBuilder,
              @Inject(LOCALE_ID) private readonly locale: string

  ) { }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<IUser>();
  private partnersData: IUser[] = [];
  public listLang: string[] =  [ 'fr' , 'en' , 'es', 'de' , 'it'];


  partner: IUser[] = [];
  partnerGroup: FormGroup;
  public listCountries: IPays[] = countries.list;
  hide = true;


  ngOnInit(): void {
    this.initForm();
    this.getPartner();
  }

  public save( ): void {
    const payload: any = this.partnerGroup.getRawValue();
    payload.dueDate = payload.dueDate ? formatDate(payload.dueDate, 'yyyy-MM-dd', this.locale) : null;
    if (this.partnerGroup.valid) {
      this.partnerService.editPartner(payload, this.data.partner.id).subscribe(
        (result) => {
          if (sessionStorage.getItem('lang') == 'fr'){
            this.toastr.info('Le partenaire a été Modifié');
          }
          else if (sessionStorage.getItem('lang') == 'en')
          {
            this.toastr.info('The partner has been modified');
          }
          this.dialogRef.close();
        },
        (error) => {
          this.toastr.error(error.error.message);
        }
      );
    }
  }

  initForm(): void {
    this.partnerGroup = this.formBuilder.group({
      email: [{ value: this.data.partner.email, disabled: false },  [Validators.required,
        Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]],
      firstName: [{ value: this.data.partner.firstName, disabled: false }, Validators.required],
      lastName: [{ value: this.data.partner.lastName, disabled: false }, Validators.required],
      phone: [ this.data.partner.phone, [Validators.pattern(/^\d{10}$/)]],
      address: [{ value: this.data.partner.address, disabled: false }],
      postalCode: [{ value: this.data.partner.postalCode, disabled: false }],
      city: [{ value: this.data.partner.city, disabled: false }],
      country: [{ value: this.data.partner.country, disabled: false }],
      lang: [{ value: this.data.partner.lang, disabled: false }],
    });

  }

  getPartner(): void {
    this.partnerService.getPartner().subscribe(
      (result: any) => {
        this.partner = result;
        this.partnersData = result.data;
        this.dataSource.data = this.partnersData;

      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
}
