import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AccountEditComponent } from 'src/app/account/containers/account-edit/account-edit.component';
import { ICategorie } from 'src/app/shared/models/i-categorie';
import { IContract } from 'src/app/shared/models/i-contract';
import { AccountService } from 'src/app/shared/services/account.service';
import { ContractService } from 'src/app/shared/services/contract.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private contractService: ContractService,

    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ContractEditComponent>,
    private accountService: AccountService,
    private translate: TranslateService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}
  dataSource = new MatTableDataSource<IContract>();

  contracts: IContract[] = [];
  categories: ICategorie[] = [];
 public contractData: IContract[] = [];
  contractGroup: FormGroup;
  hide = true;
  ngOnInit(): void {
    this.initForm();
    this.getCategorie();
  }

  public save(): void {
    const payload: any = this.contractGroup.getRawValue();
    if (this.contractGroup.valid)
    {
      this.contractService.editContract(payload, this.data.contract.id).subscribe((result: any ) => {
        let msg = this.translate.get('toastr.info.contract_has_been_modified');
        msg.subscribe(value => {
          this.toastr.info(value);
        });
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error(error.error.message);
      });
    }
  }
  getCategorie(): void{
    this.accountService.getCategories().subscribe(
      (result: any) => {
        this.categories = result;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
  getContract(): void{
    this.contractService.getContract().subscribe(
      (result: any) => {
        this.contracts = result;
        this.contractData = result.data;
        this.dataSource.data = this.contractData;

    },
    (error) => {
      console.log('erreur : ' + error);
    });
  }
  initForm(): void{
    this.contractGroup = this.formBuilder.group({
      categorie: [{value: this.data.contract.categorie, disabled: false}, Validators.required],
      group: [{value: this.data.contract.group, disabled: false}, Validators.required],
      company: [{value: this.data.contract.company, disabled: false}, Validators.required],
      codecontract: [{value: this.data.contract.codecontract, disabled: true}, Validators.required],
      password: [{value: '', disabled: false}, ],
      label: [{value: this.data.contract.label, disabled: false}, Validators.required],
      criticality: [{value: this.data.contract.criticality, disabled: false}, Validators.required],
    });

  }

}
