<h2 mat-dialog-title >{{"forms.titles.edit_contract"|translate}}</h2>

<form [formGroup]="contractGroup">
  <mat-form-field class="full-width">
    <mat-label>{{"forms.champs.category"|translate}}</mat-label>
    <mat-select formControlName="categorie" required>
      <mat-option *ngFor="let categorie of categories" [value]="categorie.name">
        {{categorie.name | translate}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="contractGroup.controls.categorie.dirty && contractGroup.controls.categorie.hasError('required')">
      <p class="red-text">{{"forms.alerte.category_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.group"|translate}}' formControlName="group" required >
    <mat-hint *ngIf="contractGroup.controls.group.dirty && contractGroup.controls.group.hasError('required')">
      <p class="red-text">{{"forms.champs.group_is"|translate}} <strong>{{'forms.alerte.required'|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.company"|translate}}' formControlName="company" required >
    <mat-hint *ngIf="contractGroup.controls.company.dirty && contractGroup.controls.company.hasError('required')">
      <p class="red-text">{{"forms.alerte.company_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.contract_code"|translate}}' formControlName="codecontract" required >
    <mat-hint *ngIf="contractGroup.controls.codecontract.dirty && contractGroup.controls.codecontract.hasError('required')">
      <p class="red-text">{{"forms.alerte.contract_code_is"|translate}} <strong>{{'forms.alerte.required'|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.password"|translate}}' [type]="hide ? 'password' : 'text'" formControlName="password" >
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.contract_label"|translate}}' formControlName="label" required />
    <mat-hint *ngIf="contractGroup.controls.label.dirty && contractGroup.controls.label.hasError('required')">
      <p class="red-text">{{"forms.alerte.contract_label_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder='{{"forms.champs.criticality"|translate}}' type='text' formControlName="criticality" required />
    <mat-hint *ngIf="contractGroup.controls.criticality.dirty && contractGroup.controls.criticality.hasError('required')">
      <p class="red-text">{{"forms.alerte.criticality_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
    </mat-hint>
  </mat-form-field>

  <div align="end">
    <button mat-raised-button color="warn" mat-dialog-close>{{"forms.button.cancel"|translate}}</button>
    <button mat-raised-button color="primary" (click)="save()">{{"forms.button.to_validate"|translate}}</button>
  </div>
</form>
