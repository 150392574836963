import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DonneesPersonnelComponent } from '../donnees-personnel/donnees-personnel.component';
import { MentionsLegalesComponent } from '../mentions-legales/mentions-legales.component';
import { PolitiqueCookiesComponent } from '../politique-cookies/politique-cookies.component';
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  get session(){
    return this.authenticationService.isAuthenticated();
  }
  anakeGLobal = 'ananke.global';
  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
  }

  public showModalPersonnel(event): void {
    event.preventDefault();
    this.dialog.open(DonneesPersonnelComponent, {
      closeOnNavigation: true,
      width: '600px',
      maxHeight: '90vh',
      disableClose: false
    });
  }
  public showModalCookies(event): void {
    event.preventDefault();
    this.dialog.open(PolitiqueCookiesComponent, {
      closeOnNavigation: true,
      width: '600px',
      maxHeight: '90vh',
      disableClose: false
    });
  }
  public showModalMentions(event): void {
    event.preventDefault();
    this.dialog.open(MentionsLegalesComponent, {
      closeOnNavigation: true,
      width: '600px',
      maxHeight: '90vh',
      disableClose: false
    });
  }

}
