import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from './http.service';
import {ICategorie} from '../models/i-categorie';
import {Observable} from 'rxjs';
import {IAccount} from '../models/i-account';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends HttpService{

  constructor(protected http: HttpClient) {
    super();
  }
  getAccounts(): Observable<IAccount>{
    return this.http.get<IAccount>(this.basePath + '/account/');
  }

  getCategories(): Observable<ICategorie>{
    return this.http.get<ICategorie>( 'assets/data/account/categorie_list.json');
  }

  addAccount(data: any): Observable<any>{
    return this.http.post( this.basePath + '/account/', data);
  }

  editAccount(data: any , accountId): Observable<any>{
    return this.http.put( this.basePath + '/account/' + accountId, data);
  }

  exportCSV(data: any ): Observable<any>{
    return this.http.post( this.basePath + '/account/csv', data, { responseType: 'blob' });
  }

  deleteAccount(accountId: string): Observable<any>{
    return this.http.delete( this.basePath + '/account/' + accountId);
  }
}
