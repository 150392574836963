import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { ReactiveFormsModule} from '@angular/forms';
import { TrustedTpRoutingModule } from './trusted-tp-routing.module';
import {MatInputModule} from '@angular/material/input';
import {TrustedTpComponent} from './containers/trusted-tp/trusted-tp.component';
import {MatSelectModule} from '@angular/material/select';
import { TrustedTpRemoveComponent } from './containers/trusted-tp-remove/trusted-tp-remove.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MarketingModule } from '../shared/components/marketing/marketing.module';

@NgModule({
  declarations: [
    TrustedTpComponent,
    TrustedTpRemoveComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    TrustedTpRoutingModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  MarketingModule
  ]
})
export class TrustedTpModule { }
export function HttpLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
