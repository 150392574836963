import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SidebarService } from '../../services/sidebar.service';
import {AuthenticationService} from '../../services/authentication.service';
import {TranslateService} from "@ngx-translate/core";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    roles?: string[];
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'sidebar.home',  icon: 'dashboard', class: ''},
    { path: '/account/list', title: 'sidebar.your_account',  icon: 'account_tree', class: '' , roles: ['ROLE_TRUSTED_TP', 'ROLE_3W_USER']},
    { path: '/trusted-tp/view', title: 'sidebar.your_trusted_third_party',  icon: 'group', class: '', roles: ['ROLE_TRUSTED_TP', 'ROLE_3W_USER']},
    { path: '/ttp-role/view', title: 'sidebar.you_are_trusted_third_party',  icon: 'how_to_reg', class: '', roles: ['ROLE_TRUSTED_TP', 'ROLE_3W_USER']},
    { path: '/partner/view', title: 'sidebar.partners',  icon: ' assignment_ind', class: '', roles: ['ROLE_ADMIN'] },
    { path: '/contract/view', title: 'sidebar.contracts',  icon: ' file_copy', class: '', roles: ['ROLE_ADMIN'] },
    { path: '/contract-account/upload', title: 'sidebar.contract_management',  icon: ' upload', class: '', roles: ['ROLE_PARTNER']},
    { path: '/help', title: 'help.title',  icon: ' contact_support', class: '' , roles: ['ROLE_TRUSTED_TP', 'ROLE_3W_USER']},
    { path: '/marketing/add', title: 'sidebar.marketing_block',  icon: ' contact_support', class: '' , roles: ['ROLE_ADMIN']}
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('drawer') public drawer: MatDrawer;
  menuItems: any[];

  constructor(private sidebarService: SidebarService,
              private authService: AuthenticationService, public translate: TranslateService) { }

  ngAfterViewInit(): void {
    this.sidebarService.toggle.asObservable().subscribe(() => this.drawer.toggle());
  }

  ngOnInit(): void {
    let userRoles;
    this.translate.use(sessionStorage.getItem('lang'));
    if (this.authService.isAuthenticated()) {
      userRoles = this.authService.readToken().roles;
    }

    this.menuItems = ROUTES.filter((menuItem) => {
      if (menuItem.roles){
        const intersection = userRoles.filter(value => menuItem.roles.includes(value));
        if (intersection.length){
          return  menuItem;
        }
      }else{
        return menuItem;
      }
    });
  }

  isMobileMenu(): boolean {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  }

}
