import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChangePasswordComponent} from './containers/change-password/change-password.component';
import {EditUserComponent} from './containers/edit-user/edit-user.component';

const routes: Routes = [
  {path: 'edit', component: EditUserComponent},
  {path: 'change-password', component: ChangePasswordComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilRoutingModule {
}
