import { Injectable } from '@angular/core';
import { MatSidenav  } from '@angular/material/sidenav';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SidebarService {

  public toggle: Subject<boolean> = new Subject();
  constructor() { }

  private drawer: MatSidenav ;

  setDrawer(drawer: MatSidenav ) {
      this.drawer = drawer;
  }



  public open() {
      return this.drawer.open();
  }

  public close() {
      return this.drawer.close();
  }

}
