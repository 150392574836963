import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ITrustedTP} from '../models/i-trustedTP';

@Injectable({
  providedIn: 'root'
})
export class TrustedTpService extends HttpService{

  constructor(protected http: HttpClient) {
    super();
  }
  getTrustedTpById(trustedTPId: string): Observable<any>{
    return this.http.get<any>( this.basePath + '/trustedtp/trustedTp_by_idTp/' + trustedTPId);
  }
  getTrustedTpByForUser(forUserId: string): Observable<any>{
    return this.http.get<any>( this.basePath + '/trustedtp/trusted-by-for-user/' + forUserId);
  }
  getTrustedTP(): Observable<ITrustedTP>{
    return this.http.get<ITrustedTP>(this.basePath + '/trustedtp/trusted-partners');
  }

  saveTrustedTP(data: any): Observable<any>{
    return this.http.post( this.basePath + '/trustedtp/tp', data);
  }

  removeTrustedTP1(trustedTPId: string): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/tp1/' + trustedTPId , null);
  }

  removeTrustedTP2(trustedTPId: string): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/tp2/' + trustedTPId , null);
  }

  getYourTrustedTPList(): Observable<any>{
    return this.http.get<any>(this.basePath + '/trustedtp/yourtrustedtp');
  }

  updateStatusTP1(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/status/tp1/', data);
  }

  updateStatusTP2(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/status/tp2', data);
  }

  getYourTTPInvitations(): Observable<any>{
    return this.http.get( this.basePath + '/trustedtp/invitations');
  }

  acceptedTrustedTP(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/accepted', data);
  }

  refusedTrustedTP(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/refused', data);
  }

  RecoveryRequest(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/recovery', data);
  }

  RecoveryRequestAccepted(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/recovery/accepted', data);
  }
  setHaveRecoveryRequest(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/sethaverecovery', data);
  }
  RecoveryRequestRefused(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/recovery/refused', data);
  }

  RecoveryRequestCanceled(data: any): Observable<any>{
    return this.http.put( this.basePath + '/trustedtp/recovery/canceled', data);
  }

  getAllRoleTrustedTPRecoveryRequest(): Observable<any>{
    return this.http.get( this.basePath + '/trustedtp/role/recovery');
  }

}
