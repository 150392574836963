<div class="content">
  <div class="container-fluid">
    <div class="row">
    <div class="col-md-9">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"forms.titles.first_trusted_tp" | translate }}</h5>
          </div>
          <div class=" card-body">
            <div class="alert alert-success" *ngIf="trustedTP1 && trustedTP1.roleIsAccepted === null">
              <span><b> {{"notif_confirmation_trusted_tp.pending_to_accept" | translate}} - </b> {{"notif_confirmation_trusted_tp.communicate_confirmation_code"| translate}} {{trustedTP1.confirmationCode}} {{"notif_confirmation_trusted_tp.to_your_trusted_third_party" | translate}}</span><br/>
<!--              <span><b> {{"notif_confirmation_trusted_tp.registration_link" | translate }} : </b> {{trustedTP1url}}</span>-->
            </div>
            <div class="alert alert-danger" *ngIf=" trustedTP1 && trustedTP1.roleIsAccepted === false">
              <span [innerHTML]="'alerte_demande_refused.content' | translate"></span><br/>
            </div>
            <div class="alert alert-success" *ngIf=" trustedTP1 && trustedTP1.roleIsAccepted">
              <span [innerHTML]="'alerte_demande_accepted.content' | translate"></span><br/>
            </div>
            <div class="alert alert-warning" *ngIf=" trustedTP1 && trustedTP1.haveRecoveryRequest && !trustedTP1.recoveryRequestAcceptedByUser" >
              <button mat-button type="button" (click)="RecoveryRequestAccepted(trustedTP1id,trustedTP2id)" ><b>{{"list.button.accept" | translate}}</b></button>
              <button mat-button type="button" (click)="RecoveryRequestRefused(trustedTP1,trustedTP2)" ><b>{{"list.button.refuse" | translate}}</b></button>
              <span>  {{"alerte_demande_list_account.your_trusted_third_party" | translate}} {{ trustedTP1.firstName +" "+ trustedTP1.lastName }} {{"alerte_demande_list_account.request_access_to_your_account_list" | translate}} </span>
            </div>
            <form [formGroup]="trustedTP1FormGroup">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.nom' | translate}}" formControlName="lastName" required >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.firstName.dirty && trustedTP1FormGroup.controls.firstName.hasError('required')">
                      <p class="red-text">{{"forms.alerte.nom_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.prenom'|translate}}" formControlName="firstName" required >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.lastName.dirty && trustedTP1FormGroup.controls.lastName.hasError('required')">
                      <p class="red-text">{{'forms.alerte.firstname_is'|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.adress_mail'|translate}}" formControlName="email" required >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.email.dirty && trustedTP1FormGroup.controls.email.hasError('required')">
                      <p class="red-text">{{"forms.alerte.mail_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.email.dirty && trustedTP1FormGroup.controls.email.hasError('pattern')">
                      <p class="red-text">{{"forms.alerte.enter_valid_email"|translate}}</p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>{{'forms.champs.langue' | translate}}</mat-label>
                    <mat-select formControlName="lang" required>
                      <mat-option *ngFor="let lang of listLang" [value]="lang">
                        {{lang}}
                      </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.lang.dirty && trustedTP1FormGroup.controls.lang.hasError('required')">
                      <p class="red-text">{{"forms.alerte.langue_is"| translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-md-6 ">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.phone'|translate}}" formControlName="phone"  >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.phone.dirty && trustedTP1FormGroup.controls.phone.hasError('required')">
                      <p class="red-text">{{"forms.alerte.phone_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.phone.dirty && trustedTP1FormGroup.controls.phone.hasError('pattern')">
                      <p class="red-text">{{"forms.alerte.enter_valid_phone"|translate}}</p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.adress'|translate}}" formControlName="address"  >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.address.dirty && trustedTP1FormGroup.controls.address.hasError('required')">
                      <p class="red-text">{{"forms.alerte.adress_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field  class="full-width">
                    <input matInput placeholder="{{'forms.champs.postal_code' | translate}}" formControlName="postalCode"  >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.postalCode.dirty && trustedTP1FormGroup.controls.postalCode.hasError('required')">
                      <p class="red-text">{{"forms.alerte.postal_code_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.city'|translate}}" formControlName="city"  >
                    <mat-hint *ngIf="trustedTP1FormGroup.controls.city.dirty && trustedTP1FormGroup.controls.city.hasError('required')">
                      <p class="red-text">{{"forms.alerte.city_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>{{"forms.champs.country" | translate}}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let country of listCountries" [value]="country.name">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
          <div class=" card-footer">
            <div align="right">
              <button mat-raised-button color="warn" (click)="removeTrustedTP1()" type="submit" [disabled]="!removeTP1btn">{{"modal_delete.delete_trustedtp.title"| translate}}</button>
              <button mat-raised-button color="primary" (click)="saveTrustedTP1()" type="submit" [disabled]="trustedTP1 && (trustedTP1.roleIsAccepted === null || trustedTP1.roleIsAccepted === false)">{{"forms.button.save"|translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"forms.titles.second_trusted_tp" | translate}}</h5>
          </div>
          <div class=" card-body">
            <div class="alert alert-success" *ngIf="trustedTP2 && trustedTP2.roleIsAccepted === null">
              <span><b> {{"notif_confirmation_trusted_tp.pending_to_accept" | translate}} - </b> {{"notif_confirmation_trusted_tp.communicate_confirmation_code"| translate}} {{trustedTP2.confirmationCode}} {{"notif_confirmation_trusted_tp.to_your_trusted_third_party" | translate}}</span><br/>
<!--              <span><b> {{"notif_confirmation_trusted_tp.registration_link" | translate }} : </b> {{trustedTP2url}}</span>-->
            </div>
            <div class="alert alert-danger" *ngIf=" trustedTP2 && trustedTP2.roleIsAccepted === false">
              <span [innerHTML]="'alerte_demande_refused.content' | translate"></span><br/>
            </div>
            <div class="alert alert-success" *ngIf=" trustedTP2 && trustedTP2.roleIsAccepted">
              <span [innerHTML]="'alerte_demande_accepted.content' | translate"></span><br/>
            </div>
            <div class="alert alert-warning" *ngIf="trustedTP2 && trustedTP2.haveRecoveryRequest && !trustedTP2.recoveryRequestAcceptedByUser" >
              <button mat-button type="button" (click)="RecoveryRequestAccepted(trustedTP1id,trustedTP2id)" ><b>{{"list.button.accept" | translate}}</b></button>
              <button mat-button type="button" (click)="RecoveryRequestRefused(trustedTP2,trustedTP1)" ><b>{{"list.button.refuse" | translate}}</b></button>
              <span>  {{"alerte_demande_list_account.your_trusted_third_party" | translate}}{{ trustedTP2.firstName +" "+ trustedTP2.lastName }} {{"alerte_demande_list_account.request_access_to_your_account_list" | translate}} </span>
            </div>
            <form [formGroup]="trustedTP2FormGroup">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.nom' | translate}}"formControlName="lastName" required >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.firstName.dirty && trustedTP2FormGroup.controls.firstName.hasError('required')">
                      <p class="red-text">{{"forms.alerte.nom_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.prenom' | translate}}" formControlName="firstName" required >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.lastName.dirty && trustedTP2FormGroup.controls.lastName.hasError('required')">
                      <p class="red-text">{{"forms.alerte.firstname_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.adress_mail'|translate}}" formControlName="email" required >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.email.dirty && trustedTP2FormGroup.controls.email.hasError('required')">
                      <p class="red-text">{{"forms.alerte.mail_is" | translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.email.dirty && trustedTP2FormGroup.controls.email.hasError('pattern')">
                      <p class="red-text">{{"forms.alerte.enter_valid_email" | translate}}</p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>{{'forms.champs.langue' | translate}}</mat-label>
                    <mat-select formControlName="lang" required>
                      <mat-option *ngFor="let lang of listLang" [value]="lang">
                        {{lang}}
                      </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.lang.dirty && trustedTP2FormGroup.controls.lang.hasError('required')">
                      <p class="red-text">{{"forms.alerte.langue_is"|translate }} <strong> {{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-md-6 ">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.phone'|translate}}" formControlName="phone"  >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.phone.dirty && trustedTP2FormGroup.controls.phone.hasError('required')">
                      <p class="red-text">{{"forms.alerte.phone_is" | translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.phone.dirty && trustedTP2FormGroup.controls.phone.hasError('pattern')">
                      <p class="red-text">{{"forms.alerte.enter_valid_phone" | translate}}</p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.adress'|translate}}" formControlName="address"  >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.address.dirty && trustedTP2FormGroup.controls.address.hasError('required')">
                      <p class="red-text">{{"forms.alerte.adress_is"|translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field  class="full-width">
                    <input matInput placeholder="{{'forms.champs.postal_code' | translate}}" formControlName="postalCode"  >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.postalCode.dirty && trustedTP2FormGroup.controls.postalCode.hasError('required')">
                      <p class="red-text">{{"forms.alerte.postal_code_is" | translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input matInput placeholder="{{'forms.champs.city'|translate}}" formControlName="city"  >
                    <mat-hint *ngIf="trustedTP2FormGroup.controls.city.dirty && trustedTP2FormGroup.controls.city.hasError('required')">
                      <p class="red-text">{{"forms.alerte.city_is"| translate}} <strong>{{"forms.alerte.required" | translate}}</strong></p>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>{{"forms.champs.country" | translate}}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let country of listCountries" [value]="country.name">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
          <div class=" card-footer">
            <div align="right">
              <button mat-raised-button color="warn" (click)="removeTrustedTP2()" type="submit" [disabled]="!removeTP2btn">{{"modal_delete.delete_trustedtp.title"| translate}}</button>
              <button mat-raised-button color="primary" (click)="saveTrustedTP2()" type="submit" [disabled]="trustedTP2 && (trustedTP2.roleIsAccepted === null || trustedTP2.roleIsAccepted === false)">{{"forms.button.save"|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" col-md-3">
      <app-marketing></app-marketing>
    </div>
    </div>
  </div>
</div>
