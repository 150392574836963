import { Component, OnInit } from '@angular/core';
import {HeaderService} from "../shared/services/header.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public lang = sessionStorage.getItem('lang');
  constructor(private headerService: HeaderService) { }

  ngOnInit(): void {
    this.headerService.languageChange.subscribe((value)=>{
      this.lang = value;
    });
  }

}
