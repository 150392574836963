<h2 mat-dialog-title >{{"forms.titles.add_partner"|translate}}</h2>
<mat-dialog-content >
  <form [formGroup]="partnerGroup">
    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.mail'|translate}}" formControlName="email" required >
      <mat-hint *ngIf="partnerGroup.controls.email.dirty && partnerGroup.controls.email.hasError('pattern') && !partnerGroup.controls.email.hasError('required')">
        <p class="red-text">{{"forms.alerte.enter_valid_email"|translate}}</p>
      </mat-hint>
      <mat-hint *ngIf="partnerGroup.controls.email.dirty && partnerGroup.controls.email.hasError('required')">
        <p class="red-text">{{"forms.alerte.mail_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.nom'|translate}}" formControlName="lastName" required >
      <mat-hint *ngIf="partnerGroup.controls.lastName.dirty && partnerGroup.controls.lastName.hasError('required')">
        <p class="red-text">{{"forms.alerte.nom_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.prenom'|translate}}" formControlName="firstName" required >
      <mat-hint *ngIf="partnerGroup.controls.firstName.dirty && partnerGroup.controls.firstName.hasError('required')">
        <p class="red-text">{{"forms.alerte.firstname_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.password' |translate}}" [type]="hide ? 'password' : 'text'" formControlName="password" required>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-hint *ngIf="partnerGroup.controls.password.dirty && partnerGroup.controls.password.hasError('required')">
        <p class="red-text" >{{"forms.alerte.password_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.phone'|translate}}" formControlName="phone" />
      <mat-hint *ngIf="partnerGroup.controls.phone.dirty && partnerGroup.controls.phone.hasError('pattern')">
        <p class="red-text">{{'forms.alerte.enter_valid_phone'|translate}}</p>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.adress'|translate}}" formControlName="address"  >
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.postal_code'|translate}}" formControlName="postalCode"  >
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="{{'forms.champs.city'|translate}}"  formControlName="city" >
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{'forms.champs.country'|translate}}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of listCountries" [value]="country.name">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{'forms.champs.langue'|translate}}</mat-label>
      <mat-select formControlName="lang">
        <mat-option *ngFor="let lang of listLang" [value]="lang">
          {{lang}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>{{"forms.button.cancel"|translate}}</button>
  <button mat-raised-button color="primary" (click)="save()">{{"forms.button.to_validate"|translate}}</button>
</mat-dialog-actions>
