<div class="container-fluid">
    <div class="row">
      <div class=" col-md-12 ">
        <div class=" card card-user">
          <div class=" card-body">
            <p class=" card-text"></p>
            <div class=" author">
              <div class=" block block-one"></div>
              <div class=" block block-two"></div>
              <div class=" block block-three"></div>
              <div class=" block block-four"></div>
              <a href="javascript:void(0)">
                <span>3w</span>life
              </a>
            </div>

            <div class=" card-description">
              <p class=" card-title">Mot de passe oublié </p>
              <form [formGroup]="recoveryPassword" >
                <mat-form-field class="full-width">
                  <input matInput placeholder="Nouveau mot de passe" [type]="hide[0] ? 'password' : 'text'" formControlName="password"
                         matTooltip="Utilisez au moins 8 caractères avec des lettres en minuscule et en majuscule et des chiffres"
                         required>
                  <mat-icon matSuffix (click)="hide[0] = !hide[0]">{{hide[0] ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-hint *ngIf="recoveryPassword.controls.password.dirty && recoveryPassword.controls.password.hasError('required')">
                    <p class="red-text">Le mot de passe est <strong>obligatoire</strong></p>
                  </mat-hint>
                  <mat-hint *ngIf="recoveryPassword.controls.password.dirty && recoveryPassword.controls.password.hasError('pattern')">
                    <p class="red-text">Mot de passe invalide.</p>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Confirmer votre mot de passe"
                         [type]="hide[1] ? 'password' : 'text'"formControlName="confirmPassword" required>
                  <mat-icon matSuffix (click)="hide[1] = !hide[1]">{{hide[1] ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-hint *ngIf="recoveryPassword.controls.confirmPassword.dirty && recoveryPassword.controls.confirmPassword.hasError('required')">
                    <p class="red-text">Confirmer votre mot de passe</p>
                  </mat-hint>
                  <mat-hint *ngIf="recoveryPassword.controls.confirmPassword.dirty && recoveryPassword.controls.confirmPassword.hasError('compare')">
                    <p class="red-text">Les mots de passe saisis ne sont pas identiques</p>
                  </mat-hint>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class=" card-footer">
<!--            <div class=" card-description" routerLinkActive="active">-->
<!--              <div class=" text-info">-->
<!--                <a routerLink='/auth/login'>Connexion</a>-->
<!--              </div>-->
<!--              <div class=" text-info">-->
<!--                <a routerLink='/auth/sign-up' >Vous n'avez pas encore de compte? S'inscrire.</a>-->
<!--              </div>-->
<!--            </div>-->
            <div class=" button-container">
              <button class=" btn btn-fill btn-primary" (click)="recoverypassword()" type="submit">Valider</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

