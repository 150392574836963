<footer class="footer " xmlns="http://www.w3.org/1999/html">
  <div>
    <nav>
      <div class="disconnect" *ngIf="!session; else secondFooter">
        <a class="text-reset fw-bold"> <span>© 2021 Copyright: </span> ananke.global</a>
      </div>
      <ng-template #secondFooter>
        <div >
          <div class="center">
            <span class="copyright" > <span>© 2021 Copyright: </span> ananke.global</span>
            <span (click)="showModalPersonnel($event)" class="underline"> {{"footer.personal_data_policy"|translate}}</span>
            <span (click)="showModalCookies($event)" class="underline"> {{"footer.cookie_policy"|translate}}</span>
            <span (click)="showModalMentions($event)" class="underline mentions"> {{"footer.legal_notice"|translate}} </span>
          </div>
        </div>
      </ng-template>
    </nav>
  </div>
</footer>

