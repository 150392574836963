import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(private service: SidebarService) { }

  showFiller = false;
  ngOnInit(): void {
  }

  toggle(){
    this.service.toggle.next();
  }



}
