import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IUser} from '../../../shared/models/i-user';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signin: FormGroup;
  hide = true;

  constructor(
    private router: Router,
    private registrationService: AuthenticationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {
  }

  public login(): void {
    if (this.signin.valid) {
      this.registrationService.login(this.signin.getRawValue()).subscribe(
        (result: any) => {
          let msg = this.translate.get('toastr.info.you_are_connected');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          sessionStorage.setItem('token', result.token);
          sessionStorage.setItem('username', result.data.fullName);
          sessionStorage.setItem('lang', result.data.lang);
          sessionStorage.setItem('loginDate', result.data.logindate);
          this.translate.use(sessionStorage.getItem('lang'));
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          let msg = this.translate.get('toastr.error.username_or_login_invalid');
          msg.subscribe(value => {
            this.toastr.error(value);
          });
        }
      );
    } else {
      (Object as any).values(this.signin.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
    sessionStorage.clear();
  }

  private initForm(user: IUser = null): any {
    this.signin = this.formBuilder.group({
      username: [user ? user.email : null, [Validators.required, Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]],
      password: [null, Validators.required]
    });
  }

}
