<i class="material-icons close" data-dismiss="alert" aria-label="Close" (click)="closediag()">close</i>
<div class="container">
  <h2>{{"list.columns.contact_us"|translate}}</h2>

  <div class="contract">
    <div class="row name">
      <div class="col-md-4">
        <mat-label class="contract"><b>{{"forms.champs.prenom"|translate}} : </b></mat-label>{{userFirstName}}
      </div>
      <div class="col-md-4">
        <mat-label class="contract"><b>{{"forms.champs.nom"|translate}} : </b></mat-label>{{userLastName}}
      </div>
    </div>
    <div class="content row" *ngFor="let descrition of dataSource">
      <div class="col-md-4">
        <mat-label class="contract"><b>{{"list.columns.contract"|translate}} : </b></mat-label>{{descrition.label}}
      </div>
      <div class="col-md-4">
        <mat-label class="societe"><b>{{"forms.champs.company"|translate}} : </b></mat-label>{{descrition.company}}
      </div>
      <div class="col-md-12 description-label">
        <b>{{"forms.champs.description"|translate}} : </b>
      </div>
      <div class="col-md-12">
        <p class="description"><span [innerHTML]="descrition.description"></span></p>
      </div>
    </div>
  </div>
</div>
