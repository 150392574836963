<div class="content">
  <div class="container-fluid" *ngIf="user">
    <div class="row">
      <div class=" col-md-12 ">
        <div class=" card card-user">
          <div class=" card-header">
            <h5 class=" title">{{"forms.titles.change_your_personal_information"|translate}} </h5>
          </div>
          <div class=" card-body">
            <div class=" card-description">
              <form [formGroup]="form" (submit)="save()">
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.adress_mail'|translate}}" formControlName="email" required>
                  <mat-hint *ngIf="form.controls.email.dirty && form.controls.email.hasError('pattern') && !form.controls.email.hasError('required')">
                    <p class="red-text">{{"forms.alerte.enter_valid_email"|translate}}</p>
                  </mat-hint>
                  <mat-hint *ngIf="form.controls.email.dirty && form.controls.email.hasError('required')">
                    <p class="red-text">{{"forms.alerte.mail_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.prenom'|translate}}" formControlName="firstName" required>
                  <mat-hint *ngIf="form.controls.firstName.dirty && form.controls.firstName.hasError('required')">
                    <p class="red-text">{{"forms.alerte.firstname_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.nom'|translate}}" formControlName="lastName" required>
                  <mat-hint *ngIf="form.controls.lastName.dirty && form.controls.lastName.hasError('required')">
                    <p class="red-text">{{"forms.alerte.nom_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.phone'|translate}}" formControlName="phone">
                  <mat-hint *ngIf="form.controls.phone.dirty && form.controls.phone.hasError('pattern')">
                    <p class="red-text">{{"forms.alerte.enter_valid_phone"|translate}}</p>
                  </mat-hint>
                </mat-form-field>

                <mat-form-field  class="full-width">
                  <mat-label>{{"forms.champs.birth_date"|translate}}</mat-label>
                  <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="birthDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-hint *ngIf="form.controls.birthDate.dirty && form.controls.birthDate.hasError('dateNaissanceInvalide')">
                    <p class="red-text">{{"forms.alerte.invalid_birth_date"|translate}}</p>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.adress'|translate}}" formControlName="address">
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.postal_code'|translate}}" formControlName="postalCode">
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'forms.champs.city'|translate}}" formControlName="city">
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{"forms.champs.country"|translate}}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option *ngFor="let country of listCountries" [value]="country.name">
                      {{country.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{"forms.champs.langue"|translate}}</mat-label>
                  <mat-select formControlName="lang" >
                    <mat-option *ngFor="let lang of listLang" [value]="lang">
                      {{lang}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-checkbox formControlName="communicationPartenaire" class="full-width">
                  {{"forms.accept_communication_partner"|translate}}
                </mat-checkbox>

              </form>
            </div>
          </div>
          <div class=" card-footer">
            <div class=" button-container">
              <button class=" btn btn-fill btn-primary" (click)="save()" type="submit">{{"forms.button.save"|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



