<div *ngIf="form" class="container-fluid">
  <div class="row">
    <div class=" col-md-12 ">
      <div class=" card card-user">
        <div class=" card-body">
          <p class=" card-text"></p>
          <div class=" author">
            <div class=" block block-one"></div>
            <div class=" block block-two"></div>
            <div class=" block block-three"></div>
            <div class=" block block-four"></div>
            <a href="javascript:void(0)">
              <span>3w</span>life
            </a>
          </div>

          <div class="card-description">
            <p class="card-title">{{"forms.titles.register"|translate}}</p>
            <p class="align-center">{{"forms.you_have_profile_3wlife" | translate}}, <a (click)="openLoginModal()" style="cursor: pointer"><b>{{"forms.please_click_her"|translate}}</b></a></p>

            <form [formGroup]="form" (submit)="save()">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.adress_mail' | translate}}" formControlName="email" required readonly disabled>
                <mat-hint *ngIf="form.controls.email.dirty && form.controls.email.hasError('pattern') && !form.controls.email.hasError('required')">
                  <p class="red-text">{{"forms.alerte.enter_valid_email" | translate}}</p>
                </mat-hint>
                <mat-hint *ngIf="form.controls.email.dirty && form.controls.email.hasError('required')">
                  <p class="red-text">{{"forms.alerte.mail_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.password'|translate}}" formControlName="password"
                       [type]="hide[0] ? 'password' : 'text'"
                       matTooltip="{{'forms.alerte.tooltip_password'|translate}}"
                       required>
                <mat-icon matSuffix (click)="hide[0] = !hide[0]">{{hide[0] ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-hint *ngIf="form.controls.password.dirty && form.controls.password.hasError('required')">
                  <p class="red-text">{{"forms.alerte.password_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                </mat-hint>
                <mat-hint *ngIf="form.controls.password.dirty && form.controls.password.hasError('pattern')">
                  <p class="red-text">{{"forms.alerte.invalid_password"|translate}}</p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.confirm_password'|translate}}"
                       [type]="hide[1] ? 'password' : 'text'" formControlName="confirmPassword" required>
                <mat-icon matSuffix (click)="hide[1] = !hide[1]">{{hide[1] ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-hint *ngIf="form.controls.confirmPassword.dirty && form.controls.confirmPassword.hasError('required')">
                  <p class="red-text">{{'forms.alerte.confirm_password_is'|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                </mat-hint>
                <mat-hint *ngIf="form.controls.confirmPassword.dirty && form.controls.confirmPassword.hasError('compare')">
                  <p class="red-text">{{"forms.alerte.passwords_not_indentique"|translate}}</p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.prenom'|translate}}" formControlName="firstName" required>
                <mat-hint *ngIf="form.controls.firstName.dirty && form.controls.firstName.hasError('required')">
                  <p class="red-text">{{"forms.alerte.firstname_is"|translate}} <strong>{{"forms.required"|translate}}</strong></p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.nom'|translate}}" formControlName="lastName" required>
                <mat-hint *ngIf="form.controls.lastName.dirty && form.controls.lastName.hasError('required')">
                  <p class="red-text">{{"forms.alerte.nom_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.phone'|translate}}" formControlName="phone">

                <mat-hint *ngIf="form.controls.phone.dirty && form.controls.phone.hasError('pattern')">
                  <p class="red-text">{{"forms.alerte.enter_valid_phone"|translate}}</p>
                </mat-hint>
              </mat-form-field>

              <mat-form-field  class="full-width">
                <mat-label>{{'forms.champs.birth_date'|translate}}</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="birthDate" readonly required>
                <mat-hint *ngIf="form.controls.birthDate.dirty && form.controls.birthDate.hasError('required')">
                  <p class="red-text">{{"forms.alerte.birth_date_is"|translate}} <strong>{{"forms.alerte.required"|translate}}</strong></p>
                </mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="form.controls.birthDate.dirty && form.controls.birthDate.hasError('dateNaissanceInvalide')">
                  <p class="red-text">{{"forms.alerte.invalid_birth_date"|translate}}</p>
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.adress'|translate}}" formControlName="address">
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.postal_code'|translate}}" formControlName="postalCode">
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'forms.champs.city'|translate}}" formControlName="city">
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{'forms.champs.country'|translate}}</mat-label>
                <mat-select formControlName="country">
                  <mat-option *ngFor="let country of listCountries" [value]="country.name">
                    {{country.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{"forms.champs.langue"|translate}}</mat-label>
                <mat-select formControlName="lang">
                  <mat-option *ngFor="let lang of listLang" [value]="lang">
                    {{lang}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox formControlName="conditions" class="full-width" required>
                <span (click)="showModal($event)" class="underline">{{"forms.general_conditions"|translate}}</span>*
              </mat-checkbox>

              <mat-checkbox formControlName="communicationPartenaire" class="full-width">
                {{"forms.accept_communication_partner"|translate}}
              </mat-checkbox>

            </form>
          </div>
        </div>
        <div class=" card-footer">
          <div class=" button-container">
            <button class=" btn btn-fill btn-primary" (click)="save()" type="submit">{{"forms.button.send"|translate}}</button>
            <button class=" btn btn-secondary" (click)="resetForm()">{{"forms.button.cancel"|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

