import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {IContractFile} from '../../../shared/models/i-contractFile';
import {ToastrService} from 'ngx-toastr';
import {ContractAccountService} from '../../../shared/services/contract-account.service';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contract-account-list',
  templateUrl: './contract-account-list.component.html',
  styleUrls: ['./contract-account-list.component.scss']
})
export class ContractAccountListComponent implements OnInit , AfterViewInit  {

  constructor(private contractAccountService: ContractAccountService,
              private toastr: ToastrService,
              private router: Router,
              public translate: TranslateService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'status', 'fileName', 'contract', 'loadedAt' , 'actions'];
  dataSource = new MatTableDataSource<IContractFile>();
  contractUuid: string;
  fileList: IContractFile;

  ngOnInit(): void {
  }

  getContractFiles(contractUuid = null): void{
    this.contractAccountService.getContractLoadingList(contractUuid).subscribe(
      (result: any) => {
        this.contractUuid = contractUuid;
        this.dataSource.data = result.data;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  viewRepport(contractFile: IContractFile): void{
    this.router.navigate(['/contract-account/repport/' + contractFile.id ] );
  }

  clearListe(): void {
    this.dataSource.data = [];
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

}
