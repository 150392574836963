<div class="container">
    <div class="header">
      <div class="title">{{"footer.legal_notice" | translate}}</div>
      <div class="btn-close">
        <button (click)="closeModale()">
          <svg-icon src="assets/svg/closeCookies.svg" [svgStyle]="{ 'height.px':30, 'width.px':30 }"></svg-icon>
        </button>
      </div>
    </div>
    <div class="content"> <br>
      <b>Ananke SAS</b>  <br/><br/>
      Société anonyme à capital variable de 4000 euros <br>
      RCS Nanterre XXX XXX XXX <br>
      TVA : <br>
      SIRET : <br>
      Code NAF : <br>
      Siège social : <br>
      293 boulevard Saint Denis <br>
      92400 Courbevoie <br>
      France <br>
      Tel : XX XX XX XX XX <br>
      E-mail : bonjour@ananke.global <br>
      Directeur de la publication : Christophe Korfer <br>
      <br><br> <br>
      <b>Hebergeur du site :</b> <br> <br>
      OUTSCALE SAS <br>
1 Rue Royale <br>
92219 SAINT CLOUD <br>
Tel : 0826 206 307
    </div>
  </div>
