import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(
    private router: Router ,
    private formBuilder: FormBuilder,
    private registrationService: AuthenticationService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) { }

  emailIsSended = false;
  resetUrl: string;
  forgetPassword: FormGroup ;

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): any {
    this.forgetPassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]]
    });
  }
  public forgetpassword(): void {
    if (this.forgetPassword.valid) {
      this.registrationService.forgetpasword(this.forgetPassword.getRawValue()).subscribe(
        (result: any) => {
          this.emailIsSended = true;
        //  this.resetUrl = '/auth/recovery-password/' + result.data.uuid;
        },
        (error) => {
          const msg = this.translate.get('toastr.error.mail_invalid');
          msg.subscribe(value => {
            this.toastr.error(value);
          });
          console.log('erreur : ' + error);
        }
      );
    }else{
      ( Object as any).values(this.forgetPassword.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

}
