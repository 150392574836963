<div class="container-fluid">
    <div class="row">
      <div class=" col-md-12 ">
        <div class=" card card-user">
          <div class=" card-body">
            <p class=" card-text"></p>
            <div class=" author">
              <div class=" block block-one"></div>
              <div class=" block block-two"></div>
              <div class=" block block-three"></div>
              <div class=" block block-four"></div>
              <a href="javascript:void(0)">
                <span>3w</span>life
              </a>
            </div>

            <div class=" card-description">
              <p class=" card-title">Mot de passe oublié</p>
              <div *ngIf="!emailIsSended">
                <form [formGroup]="forgetPassword" >
                  <mat-form-field class="full-width">
                    <input matInput placeholder="email" formControlName="email" required>
                    <mat-hint *ngIf="forgetPassword.controls.email.dirty  && forgetPassword.controls.email.hasError('pattern') && !forgetPassword.controls.email.hasError('required')">
                      <p class="red-text">Veuillez saisir une adresse mail valide</p>
                    </mat-hint>
                    <mat-hint *ngIf="forgetPassword.controls.email.dirty && forgetPassword.controls.email.hasError('required')">
                      <p class="red-text">L'adresse mail est <strong>obligatoire</strong></p>
                    </mat-hint>
                  </mat-form-field>
                </form>
              </div>
              <div *ngIf="emailIsSended">
                <p>Un mail a été envoyé à votre adresse qui contient le lien de changement du mot de passe.</p>
              </div>
            </div>
          </div>
          <div class=" card-footer">
<!--            <div class=" card-description" routerLinkActive="active">-->
<!--              <div class=" text-info">-->
<!--                <a routerLink='/auth/login'>Connexion</a>-->
<!--              </div>-->
<!--              <div class=" text-info">-->
<!--                <a routerLink='/auth/sign-up' >Vous n'avez pas encore de compte ? S'inscrire.</a>-->
<!--              </div>-->
<!--            </div>-->
            <div *ngIf="!emailIsSended">
              <div class=" button-container">
                <button (click)="forgetpassword()" class=" btn btn-fill btn-primary" type="submit">Valider</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

