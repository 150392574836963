import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ContractRoutingModule } from './contract-routing.module';
import { ContractListComponent } from './containers/contract-list/contract-list.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ContractAddComponent } from './containers/contract-add/contract-add.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ContractEditComponent } from './containers/contract-edit/contract-edit.component';
import { ContractShowComponent } from './containers/contract-show/contract-show.component';


@NgModule({
  declarations: [
    ContractListComponent,
    ContractAddComponent,
    ContractEditComponent,
    ContractShowComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ContractRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSortModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    FormsModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ]
})
export class ContractModule { }
export function HttpLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
