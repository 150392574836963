import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {ROUTES} from '../sidebar/sidebar.component';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../services/authentication.service';
import {HeaderService} from "../../services/header.service";
import {environment} from "../../../../environments/environment";
import {IUser} from "../../models/i-user";
import {map} from "rxjs/operators";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  location: Location;
  mobile_menu_visible = 0;
  dropshow = '';
  public displayPartnerSection: boolean = false
  public currentPartner: string = '';
  public jwtUser: string;
  public currentUser: string;
  private language: string;
  const
  private listTitles: any[];
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(location: Location,
              private element: ElementRef,
              private router: Router,
              public translate: TranslateService,
              private registrationService: AuthenticationService, private headerService: HeaderService ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit(): void{
    this.language = this.headerService.langue;
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      let $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
    this.jwtUser = sessionStorage.getItem('username');

    this.registrationService.refreshUser$.subscribe((value: boolean) => {
      if (value) {
        this.refrechName();
      }
    });
    this.registrationService.getCurrentUser().pipe(map((result => result.data))).subscribe((user: IUser) => {
      this.currentUser = user.id;
      const roles = this.registrationService.readToken().roles;
      const intersection = roles.filter(value => ['ROLE_TRUSTED_TP', 'ROLE_3W_USER', 'ROLE_USER'].includes(value));
      this.displayPartnerSection = !!intersection.length && !!user.partners.length;
      this.changePartner(user.partners).then();
    });
  }

  @HostListener('document : click', ['$event'])
  onClickedOutside(event: any) {
    const clickedTargetParent = event.target.closest('.dropdown-menu, .dropdown-toggle, .navbar');
    if (!clickedTargetParent) {
      this.dropshow = '';
    }
  }

  refrechName(): void {
    this.jwtUser = sessionStorage.getItem('username');

    // this.registrationService.refreshUser$.next(true);
  }

  sidebarOpen(): void {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose(): void {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle(): void {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    let $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    if (this.mobile_menu_visible === 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      let $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
      setTimeout(() => {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(() => {
        $toggle.classList.add('toggled');
      }, 430);

      let $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(() => {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function (): void { // asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(() => {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  }

  dropdownToggle(): void {
    this.dropshow = this.dropshow === '' ? 'show' : '';
  }

  getTitle(): string {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    }
    for (const item1 of this.listTitles) {
      if (item1.path === title) {
        return item1.title;
      }
    }

    return '';
  }

  logout(): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('lang');
    sessionStorage.removeItem('contract');
    sessionStorage.removeItem('loginDate');
  }

  getLanguage(): string {
    this.language = this.headerService.langue;
    return this.language;
  }

  changeLanguage(lang) {
    this.headerService.toggleLanguageChange(lang)
    sessionStorage.setItem('lang', lang);
  }

  timer = ms => new Promise(res => setTimeout(res, ms));
  private async changePartner(parteners: Array<string>): Promise<void> {
    if (parteners.length <= 1) {
      this.currentPartner = parteners[0];
      return;
    }
    console.log(environment.partnerInterval);

    const currentIndex = parteners.indexOf(this.currentPartner);
    if (currentIndex === parteners.length - 1) {
      this.currentPartner = parteners[0];
    } else {
      this.currentPartner = parteners[currentIndex + 1];
    }
    await this.timer(environment.partnerInterval);
    await this.changePartner(parteners);
  }
}
