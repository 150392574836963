import { Directive } from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function dateNaissanceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    const today = new Date();
    const birthDate = new Date(control.value);
    if (control.value) {

      const todayMonth = today.getFullYear() * 12 + today.getMonth() + 1;
      const dayMonth = birthDate.getFullYear() * 12 + birthDate.getMonth() + 1;
      const day = birthDate.getDate();
      const difference = todayMonth - dayMonth;
      // Date de naissance supérieur à 18 ans et inférieur à 120 ans
      if (difference > 1440 || (difference === 1440 && day  < today.getDate()) ||
        difference < 216 || (difference === 216 && day > today.getDate())) {
        return {dateNaissanceInvalide: true};
      }
    }
    return null;
  };
}

@Directive({
  selector: '[appDateNaissanceValidator]'
})
export class DateNaissanceValidatorDirective {

  constructor() { }

}
