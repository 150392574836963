import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import 'rxjs/add/operator/do';
import {Router} from '@angular/router';
import {NgxSpinnerService} from "ngx-spinner";

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = sessionStorage.getItem('token');
    if (token) {
      request = request.clone({
        setHeaders: {
          /*'Content-Type': 'application/json',*/
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        () => {
          this.spinner.show();
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/auth/login']);
              sessionStorage.clear();
            }
          }
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      )
    );
  }
}
