<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-9">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" title">{{"list.titles.you_are_trustedtp"|translate}}</h5>
          </div>
          <div class=" card-body">
            <div class="alert alert-success" *ngFor="let invitation of invitationList; let i=index"  >
              <span> {{"list.alerte.invit_to_become_trusted_third_party_for" | translate}} <b>{{invitation.firstName}} {{invitation.lastName}} </b></span>
              <div class="alert-form">
                <mat-form-field class="full-width">
                  <input matInput placeholder='{{"list.columns.confidential_code"|translate}}' type="text" [(ngModel)]="invitationList[i].confirmationCode" required>
<!--                  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>-->
                </mat-form-field>
                <div class="alert-actions">
                  <button mat-button type="button" (click)="roleTrustedTPAccepted(invitation)"  ><b>{{"list.button.accept" | translate}}</b></button>
                  <button mat-button type="button" (click)="roleTrustedTPRefused(invitation)" ><b>{{"list.button.refuse"|translate}}</b></button>
                </div>
              </div>
            </div>
            <div *ngFor="let recoveryRequest of roleTrustedTPRecoveryRequestList">
              <div  class="alert alert-warning" *ngIf="!recoveryRequest.recoveryRequestAcceptedByTrusted">
                <button mat-button type="button" (click)="RecoveryRequestAccepted(recoveryRequest)" ><b>{{"list.button.accept" | translate}}</b></button>
                <button mat-button type="button" (click)="RecoveryRequestRefused(recoveryRequest)" ><b>{{"list.button.refuse" | translate}}</b></button>
                <span> {{"list.warning.damand_to_access_to_account_by_trusted_third_party" | translate}} {{ recoveryRequest.firstName +" "+ recoveryRequest.lastName }}. {{"list.warning.do_you_confirm_your_agreement" | translate}} </span>
              </div>
            </div>
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" >
                <ng-container matColumnDef="contract" >
                  <th mat-header-cell *matHeaderCellDef>{{"list.columns.contract"|translate}}</th>
                  <td mat-cell *matCellDef="let element" class="contract">
                    <a style="color: #030303FF;" [routerLink]="" (click)="openInfoContract(element)" *ngIf="element.CriticalityContracts[0]"><b style="background-color: #CDE4FF; text-decoration: underline;">{{"list.columns.contact_us"|translate}}</b></a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.nom"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.prenom"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.mail"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                  <th mat-header-cell *matHeaderCellDef> {{"forms.champs.phone"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="roleAcceptedDate">
                  <th mat-header-cell *matHeaderCellDef> {{"list.columns.accepted_rule_the"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.roleAcceptedDate.date | date: 'dd/MM/yyyy' }} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> {{"list.columns.statut"|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{(element.status == 'Demande de récupération en cours'?
                    'demande_state_trusted_tp.recovery_request_inprogress' : (element.status == 'Demande de récupération acceptée'?
                      'demande_state_trusted_tp.recovery_request_accepted' : (element.status == 'Demande de récupération expirée'?
                        'demande_state_trusted_tp.recovery_request_expired': (element.status == 'Demande de récupération déclinée'?
                          'demande_state_trusted_tp.recovery_request_declined': 'demande_state_trusted_tp.relationship_established')))) |translate}} </td>
                </ng-container>

                <ng-container matColumnDef="actions" >

                  <th mat-header-cell *matHeaderCellDef></th>

                  <td mat-cell *matCellDef="let element" class="options">
                    <button color="basic" mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="RecoveryRequest(element)" *ngIf="!element.haveAnyRecoveryRequest && !element.isRecoveryRequestAccepted">
                        <mat-icon>vpn_key</mat-icon>
                        <span>{{"list.button.recovery_for_request"|translate}}</span>
                      </button>
                      <button mat-menu-item (click)="exportCSV(element)" *ngIf="element.isRecoveryRequestAccepted" >
                        <mat-icon>get_app</mat-icon>
                        <span>{{"list.button.download"|translate}}</span>
                      </button>
                      <button mat-menu-item (click)="removeTrustedTP(element)">
                        <mat-icon>person_add_disabled</mat-icon>
                        <span>{{"list.button.stop_management" |translate}}</span>
                      </button>
                      <button mat-menu-item (click)="RecoveryRequestCanceled(element)" *ngIf="element.haveRecoveryRequest && !element.isRecoveryRequestAccepted" >
                        <mat-icon>close</mat-icon>
                        <span>{{"list.button.cancel_request"|translate}}</span>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>

        </div>
      </div>
      <div class=" col-md-3">
        <app-marketing></app-marketing>
      </div>

    </div>

  </div>
</div>
