import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelpComponent } from './help/help.component';
import { AuthenticationModule} from './authentication/authentication.module';
import { AuthLayoutComponent} from './shared/components/auth-layout/auth-layout.component';
import { MainLayoutComponent} from './shared/components/main-layout/main-layout.component';
import { ProfilModule} from './profil/profil.module';
import { AccountModule} from './account/account.module';
import { TrustedTpModule} from './trusted-tp/trusted-tp.module';
import { AuthGuard } from './shared/guard/auth.guard';
import {TtpRoleModule} from './ttp-role/ttp-role.module';
import {UnauthorizedComponent} from './shared/components/unauthorized/unauthorized.component';
import {ContractAccountModule} from './contract-account/contract-account.module';
import { ContractModule } from './contract/contract.module';
import { PartnerModule } from './partner/partner.module';
import { MarketingModule } from './shared/components/marketing/marketing.module';

const routes: Routes = [
  {path: '', component: MainLayoutComponent , canActivate: [AuthGuard] , children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'trusted-tp', canActivate: [AuthGuard] , data : {  roles: ['ROLE_3W_USER'] },  loadChildren: () => TrustedTpModule},
      {path: 'ttp-role', loadChildren: () => TtpRoleModule},
      {path: 'help', component: HelpComponent },
      {path: 'profil', loadChildren: () => ProfilModule},
      {path: 'contract-account', canActivate: [AuthGuard] , data : {  roles: ['ROLE_ADMIN', 'ROLE_PARTNER'] }, loadChildren: () => ContractAccountModule },
      {path: 'account', canActivate: [AuthGuard] , data : {  roles: ['ROLE_3W_USER'] },  loadChildren: () => AccountModule},
      {path: 'contract', canActivate: [AuthGuard] , data : {  roles: ['ROLE_ADMIN'] },  loadChildren: () => ContractModule},
      {path: 'partner', canActivate: [AuthGuard] , data : {  roles: ['ROLE_ADMIN'] },  loadChildren: () => PartnerModule},
      {path: 'marketing', canActivate: [AuthGuard] , data : {  roles: ['ROLE_ADMIN'] },  loadChildren: () => MarketingModule},
      {path: 'Unauthorized', component: UnauthorizedComponent },
    ]},
    {path: 'auth', component: AuthLayoutComponent, loadChildren: () => AuthenticationModule},
    {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
