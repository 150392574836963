import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { IAccount } from 'src/app/shared/models/i-account';
import { ICategorie } from 'src/app/shared/models/i-categorie';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AccountService} from '../../../shared/services/account.service';
import { MatSort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {AccountEditComponent} from '../account-edit/account-edit.component';
import {AccountDeleteComponent} from '../account-delete/account-delete.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit , AfterViewInit {

  constructor(private accountService: AccountService , public dialog: MatDialog, public translate: TranslateService) { }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private accountsData: IAccount[] = [];

  private filtersData: ICategorie[] = [];

  displayedColumns: string[] = ['category', 'account', 'company', 'note', 'dueDate' , 'information' , 'updatedAt' , 'actions'];
  dataSource = new MatTableDataSource<IAccount>();

  filters = this.filtersData;
  activefilter = null;
  activeGeneral = true;

  applyCategorieFilter(filter: ICategorie): void{
    this.activefilter = filter ;
    this.activeGeneral = false;
    this.dataSource.data = this.accountsData.filter( dataItem => dataItem.category === filter.name);
  }

  applyGeneralFilter(): void{
    this.activeGeneral = true;
    this.activefilter = null ;
    this.dataSource.data = this.accountsData;
  }

  getAccounts(): void{
    this.accountService.getAccounts().subscribe(
      (result: any) => {
        this.accountsData = result.data;
        this.dataSource.data = this.accountsData;
        this.activeGeneral ? this.applyGeneralFilter() : this.applyCategorieFilter(this.activefilter);

      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  getCategorie(): void{
    this.accountService.getCategories().subscribe(
      (result: any) => {
        this.filters = result;
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  openAddAccountDialog(): void{
    const dialogRef = this.dialog.open(AccountEditComponent , {
      data: {
        mode: 'Add'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAccounts();
    });
  }

  openEditAccountDialog(account: IAccount): void{
    const dialogRef = this.dialog.open(AccountEditComponent , {
      data: {
        mode: 'Edit',
        account
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAccounts();
    });
  }

  openDeleteAccountDialog(account: IAccount): void{
    const dialogRef = this.dialog.open(AccountDeleteComponent , {
      data: {
        account
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAccounts();
    });
  }

  ngOnInit(): void{
    this.getAccounts();
    this.getCategorie();
  }
  ngAfterViewInit(): void {
    this.dataSource.data = this.accountsData;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}

