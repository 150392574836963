import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {element} from "protractor";

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,
              public dialogRef: MatDialogRef<DescriptionComponent>,
  ) { }
  dataSource;
  userFirstName;
  userLastName;

  ngOnInit(): void {
    console.log(this.data.trustedTp);
    this.getDescription();
  }
  getDescription(): void
  {
    this.dataSource = this.data.trustedTp.CriticalityContracts;
    this.dataSource = this.dataSource.filter((elem, index, self) => self.findIndex(
      (t) => (t.id === elem.id)) === index);
    this.dataSource.forEach(element => {
      element.description = element.description.replace(/\n/g, '</p><p class="description">');
    });
    this.userFirstName = this.data.trustedTp.firstName;
    this.userLastName = this.data.trustedTp.lastName;
  }
  closediag(): void {
    this.dialogRef.close(DescriptionComponent);
  }

}
