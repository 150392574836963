import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-conditions-generales',
  templateUrl: './conditions-generales.component.html',
  styleUrls: ['./conditions-generales.component.scss']
})
export class ConditionsGeneralesComponent implements OnInit {

  constructor(private matDialogRef: MatDialogRef<ConditionsGeneralesComponent>) { }

  ngOnInit(): void {
  }

  public closeModale(): void {
    this.matDialogRef.close();
  }

}
