import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import { AuthLayoutComponent } from './shared/components/auth-layout/auth-layout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelpComponent } from './help/help.component';
import { ConditionsGeneralesComponent } from './shared/components/conditions-generales/conditions-generales.component';

import { CompareDirective } from './shared/directives/compare.directive';
import { DateNaissanceValidatorDirective } from './shared/directives/date-naissance-validator.directive';

import { SidebarService } from './shared/services/sidebar.service';
import { TokenInterceptorInterceptor } from './shared/services/token-interceptor.interceptor';
import { AuthGuard } from './shared/guard/auth.guard';
import { JwtModule } from '@auth0/angular-jwt';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { DonneesPersonnelComponent } from './shared/components/donnees-personnel/donnees-personnel.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PolitiqueCookiesComponent } from './shared/components/politique-cookies/politique-cookies.component';
import { MentionsLegalesComponent } from './shared/components/mentions-legales/mentions-legales.component';
import { MarketingModule } from './shared/components/marketing/marketing.module';

import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import {NgxSpinnerModule} from "ngx-spinner";

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        AuthLayoutComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        DashboardComponent,
        CompareDirective,
        HelpComponent,
        DateNaissanceValidatorDirective,
        ConditionsGeneralesComponent,
        UnauthorizedComponent,
        NotificationsComponent,
        DonneesPersonnelComponent,
        PolitiqueCookiesComponent,
        MentionsLegalesComponent,
        SafeHtmlPipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        MatListModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return sessionStorage.getItem('token');
                },
            },
        }),
        AngularSvgIconModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-center'
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MarketingModule,
      NgxSpinnerModule
    ],
    providers: [
        SidebarService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorInterceptor,
            multi: true
        },
      { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    DashboardComponent
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
