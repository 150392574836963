import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ContractAccountLoaderComponent} from './containers/contract-account-loader/contract-account-loader.component';
import {ContractAccountRepportComponent} from './containers/contract-account-repport/contract-account-repport.component';


const routes: Routes = [
  {path: 'upload', component: ContractAccountLoaderComponent},
  {path: 'repport/:fileId', component: ContractAccountRepportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  ContractAccountRoutingModule { }
