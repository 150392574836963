import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ICategorie } from 'src/app/shared/models/i-categorie';
import { IContract } from 'src/app/shared/models/i-contract';
import { AccountService } from 'src/app/shared/services/account.service';
import { ContractService } from 'src/app/shared/services/contract.service';
import { ContractAddComponent } from '../contract-add/contract-add.component';
import { ContractEditComponent } from '../contract-edit/contract-edit.component';
import { ContractShowComponent } from '../contract-show/contract-show.component';
import {TranslateService} from "@ngx-translate/core";

/* export interface IContract {
  id: string;
  group : string;
  company : string;
  codecontract : string;
  password : string;
  label : string;
  criticality : number;
  isActivated : boolean;
} */

const ELEMENT_DATA: IContract[] = []; /*
id: '1', group: "Hydrogen", company: "qsd", codecontract: 'H',password : "",label:"",criticality:1 , isActivated : true/*  */

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit , AfterViewInit {

               constructor(
                 private contractService: ContractService,
                 public dialog: MatDialog,
                 private toastr: ToastrService,
                 private translate: TranslateService,
                 private accountService: AccountService  ) { }


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'categorie', 'group', 'company', 'codecontract', 'label' , 'criticality', 'activate', 'actions' ];
  dataSource = new MatTableDataSource<IContract>();
  private contractsData: IContract[] = [];
  public categories: ICategorie[] = [];


  ngOnInit(): void {
    this.getContract();
    this.getCategorie();
  }

  isActivated(activated: boolean, id: string): void {
      const payload = {
        isActivated: activated
      };
      this.contractService.isActivated(payload, id).subscribe(
      (result) => {
        const message = activated ? 'your_contract_has_been_activated' : 'your_contract_has_been_deactivated';
        this.translate.get(message).subscribe((value: any) => this.toastr.info(value));
        this.getContract();
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.data = ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  public getContract(): void{
    this.contractService.getContract().subscribe(
      (result: any) => {
        this.contractsData = result.data;
        this.dataSource.data = this.contractsData;

      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
  openAddAccountDialog(): void{
    const dialogRef = this.dialog.open( ContractAddComponent );
    dialogRef.afterClosed().subscribe(result => {
      this.getContract();
    });
  }
  openEditAccountDialog(contract: IContract): void{
    const dialogRef = this.dialog.open( ContractEditComponent, {
    data: {
      mode: 'edit',
      contract
    }
  });
    dialogRef.afterClosed().subscribe(result => {
      this.getContract();
    });
  }
  openShowAccountDialog(contract: IContract): void{
    const dialogRef = this.dialog.open( ContractShowComponent, {
    data: {
      mode: 'show',
      contract
    }
  });
    dialogRef.afterClosed().subscribe(result => {
      this.getContract();
    });
  }
  getCategorie(): void{
    this.accountService.getCategories().subscribe(
      (result: any) => {
        this.categories = result;
        console.log(result);

      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }
}
