<h2 mat-dialog-title>Connecter-vous a votre compte</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="linkUser">
    <mat-form-field class="full-width">
      <input matInput placeholder="Email" formControlName="username" required>
      <mat-hint *ngIf="linkUser.controls.username.dirty && linkUser.controls.username.hasError('pattern') && !linkUser.controls.username.hasError('required')">
        <p class="red-text">Veuillez saisir une adresse mail valide</p>
      </mat-hint>
      <mat-hint *ngIf="linkUser.controls.username.dirty && linkUser.controls.username.hasError('required')">
        <p class="red-text">L'adresse mail est <strong>obligatoire</strong></p>
      </mat-hint>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" formControlName="password" required>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-hint *ngIf="linkUser.controls.password.dirty && linkUser.controls.password.hasError('required')">
        <p class="red-text">Le mot de passe est <strong>obligatoire</strong></p>
      </mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>{{"cancel"|translate}}</button>
  <button mat-raised-button color="primary" (click)="login()">{{"Valider"|translate}}</button>
</mat-dialog-actions>
