import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/services/authentication.service';
import {SafeHtmlPipe} from "../shared/pipes/safe-html.pipe";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(public auth: AuthenticationService) { }

  public isUser: boolean;
  public isTrustedTp: boolean;
  public dateAccount ;
  public countComptes;
  public countTrustedTp;
  public havingTrustedTp;
  public loginDate = sessionStorage.getItem('loginDate');
  public roleNames = {ROLE_3W_USER: 'USER', ROLE_ADMIN: 'ADMIN', ROLE_PARTNER: 'PARTNER', ROLE_TRUSTED_TP: 'Tiers de confiance'};
public roles = this.auth.readToken().roles[0];
  ngOnInit(): void {
const userRoles = this.auth.readToken().roles;
userRoles.indexOf('ROLE_3W_USER') === -1 ? this.isUser = false : this.isUser = true;
userRoles.indexOf('ROLE_TRUSTED_TP') === -1 ? this.isTrustedTp = false : this.isTrustedTp = true;
this.getInfo();
  }

  getInfo(): void{
    this.auth.getdashboard().subscribe(
      (result: any) => {
        this.dateAccount = result.data.lastAddAccount;
        this.countComptes = result.data.accountCount;
        this.countTrustedTp = result.data.trustedTpCount;
        this.havingTrustedTp = result.data.havingTrustedTp;

      });
  }
}
