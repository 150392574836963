<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h3 class="title">{{"help.title" | translate}} </h3>
          </div>
          <div class=" card-body" >
            <app-marketing></app-marketing>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
