
<div class="container-fluid">
  <div class="row">
    <div class=" col-md-12 ">
      <div class=" card card-user">
        <div class=" card-body">
          <p class=" card-text"></p>
          <div class=" author">
            <div class=" block block-one"></div>
            <div class=" block block-two"></div>
            <div class=" block block-three"></div>
            <div class=" block block-four"></div>
            <a href="javascript:void(0)">
              <span>3w</span>life
            </a>
          </div>

          <div class=" card-description">
            <p class=" card-title">Connexion</p>
            <form [formGroup]="signin">
              <mat-form-field class="full-width">
                <input matInput placeholder="Email" formControlName="username" required>
                <mat-hint *ngIf="signin.controls.username.dirty && signin.controls.username.hasError('pattern') && !signin.controls.username.hasError('required')">
                  <p class="red-text">Veuillez saisir une adresse mail valide</p>
                </mat-hint>
                <mat-hint *ngIf="signin.controls.username.dirty && signin.controls.username.hasError('required')">
                  <p class="red-text">L'adresse mail est <strong>obligatoire</strong></p>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" formControlName="password" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-hint *ngIf="signin.controls.password.dirty && signin.controls.password.hasError('required')">
                  <p class="red-text">Le mot de passe est <strong>obligatoire</strong></p>
                </mat-hint>
              </mat-form-field>
            </form>
          </div>
        </div>
        <div class=" card-footer">
          <div class=" card-description" routerLinkActive="active">
            <div class=" text-info">
              <a routerLink='/auth/forget-password' >Mot de passe oublié ?</a>
            </div>
          </div>
          <div class=" button-container">
            <button (click)="login()" class=" btn btn-fill btn-primary" type="submit">Se connecter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

