<div class="container">
    <div class="header">
      <div class="title">{{"politicy_cookies.title" | translate}}</div>
      <div class="btn-close">
        <button (click)="closeModale()">
          <svg-icon src="assets/svg/closeCookies.svg" [svgStyle]="{ 'height.px':30, 'width.px':30 }"></svg-icon>
        </button>
      </div>
    </div>
    <div class="content"> <br>
      <span [innerHTML]="'politicy_cookies.content' | translate"></span>
    </div>
  </div>
