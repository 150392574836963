import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../models/i-user';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends HttpService {

  constructor(protected http: HttpClient)
  {
    super();
  }
  getPartner(): Observable<IUser>{
    return this.http.get<IUser>(this.basePath + '/partner/');
  }
  addPartner(data: any): Observable<any>{
    return this.http.post( this.basePath + '/partner/', data);
  }
  editPartner(data: any , partnerId): Observable<any>{
    return this.http.put( this.basePath + '/partner/' + partnerId, data);
  }
}
