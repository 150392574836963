import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMarketing } from '../models/i-marketing';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingBlocService extends HttpService {

  constructor(protected http: HttpClient)
  {
    super();
  }
  getMarketing(): Observable<IMarketing>{
    return this.http.get<IMarketing>(this.basePath + '/marketingbloc/');
  }
  addMarketingBloc(data: any): Observable<any>{
    return this.http.post( this.basePath + '/marketingbloc/', data);
  }
  getBlocByUserAndProfile(page, profile): Observable<IMarketing>{
    return this.http.get<IMarketing>(this.basePath + '/marketingbloc/page/' + page + '/profile/' + profile);
  }
  updateMarketingBloc(data: any, blocId: string): Observable<any>{
    return this.http.put( this.basePath + '/marketingbloc/' + blocId, data);
  }
}
