import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractListComponent } from './containers/contract-list/contract-list.component';

const routes: Routes = [
  {path: 'view', component: ContractListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  ContractRoutingModule { }
