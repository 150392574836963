import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForgetPasswordComponent} from './containers/forget-password/forget-password.component';
import {LoginComponent} from './containers/login/login.component';
import {RecoveryPasswordComponent} from './containers/recovery-password/recovery-password.component';
import {RegistrationComponent} from './containers/registration/registration.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'sign-up', children: [
      {path: '', component: RegistrationComponent},
      {path: 'partner/:accessToken', component: RegistrationComponent},
      {path: ':userId', component: RegistrationComponent},
      {path: 'invitation-partner/:isInvitation', component: RegistrationComponent},
    ]
  },
  {path: 'recovery-password/:userId', component: RecoveryPasswordComponent},
  {path: 'forget-password', component: ForgetPasswordComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}
