import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TrustedTpService} from '../../../shared/services/trusted-tp.service';
import {ITrustedTP} from '../../../shared/models/i-trustedTP';
import * as countries from '../../../../assets/data/Countries.json';
import {IPays} from '../../../shared/models/i-pays';
import {MatDialog} from '@angular/material/dialog';
import {TrustedTpRemoveComponent} from '../trusted-tp-remove/trusted-tp-remove.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { IUser } from 'src/app/shared/models/i-user';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-trusted-tp',
  templateUrl: './trusted-tp.component.html',
  styleUrls: ['./trusted-tp.component.scss']
})
export class TrustedTpComponent implements OnInit {

   trustedTP1FormGroup: FormGroup;
   trustedTP2FormGroup: FormGroup;
   removeTP1btn = false;
   removeTP2btn = false ;
   trustedTP2url: string;
   trustedTP1url: string;
   content: string;
   notificationIdtp: string;
   userAssociedIdtp: string;
   notificationId2tp: string;
   userAssociedId2tp: string;
   notificationIdtp2: string;
   userAssociedIdtp2: string;
   notificationId2tp2: string;
   userAssociedId2tp2: string;
   listCountries: IPays[] = countries.list;
   listLang: string[] = [ 'fr' , 'en' , 'es', 'de' , 'it'];
  public currentEmail;
  public currentUser;
  public currentUuid;
  public user: IUser;
  trustedTP1: ITrustedTP;
  trustedTP2: ITrustedTP;
  trustedTP1id;
  trustedTP2id;

  constructor(private formBuilder: FormBuilder ,
              private trustedTpService: TrustedTpService ,
              private toastr: ToastrService ,
              public dialog: MatDialog,
              public translate: TranslateService,
              private registrationService: AuthenticationService,
              private notificationService: NotificationsService) {}

  ngOnInit(): void {
    this.initFormTrustedTP1(null);
    this.initFormTrustedTP2(null);
    this.getTrustedTP();

    this.registrationService.getCurrentUser().subscribe(
      (result: any) => {
        this.currentUuid = result.data.id;
        this.currentUser = result.data.firstName + ' ' + result.data.lastName;
        this.currentEmail = result.data.email;

      }, );
  }

  initFormTrustedTP1(trustedTP: ITrustedTP): void{
    this.trustedTP1FormGroup = this.formBuilder.group({
      id: [{value: trustedTP ? trustedTP.id : '', disabled: false}],
      email: [
        {value: trustedTP ? trustedTP.email : '', disabled: this.trustedTP1 && this.trustedTP1.email},
        [Validators.required, Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]
      ],
      firstName: [{value: trustedTP ? trustedTP.firstName : '', disabled: false}, Validators.required],
      lastName: [{value: trustedTP ? trustedTP.lastName : '', disabled: false}, Validators.required],
      lang: [{value: trustedTP ? trustedTP.lang : '', disabled: false}, Validators.required],
      phone: [{value: trustedTP ? trustedTP.phone : '', disabled: false}, [Validators.pattern(/^\d{10}$/)]],
      postalCode: [{value: trustedTP ? trustedTP.postalCode : '', disabled: false}],
      city: [{value: trustedTP ? trustedTP.city : '', disabled: false}],
      address: [{value: trustedTP ? trustedTP.address : '', disabled: false}],
      country: [{value: trustedTP ? trustedTP.country : '', disabled: false}],
    });
  }

  initFormTrustedTP2(trustedTP: ITrustedTP): void{
    this.trustedTP2FormGroup = this.formBuilder.group({
      id: [{value: trustedTP ? trustedTP.id : '', disabled: false}],
      email: [
        {value: trustedTP ? trustedTP.email : '', disabled: this.trustedTP2 && this.trustedTP2.email},
        [Validators.required, Validators.pattern('[(a-z,A-Z)0-9._%+-]+@[(a-z,A-Z)0-9.-]+\\.[(a-z,A-Z)]{2,}$')]
      ],
      firstName: [{value: trustedTP ? trustedTP.firstName : '', disabled: false}, Validators.required],
      lastName: [{value: trustedTP ? trustedTP.lastName : '', disabled: false}, Validators.required],
      lang: [{value: trustedTP ? trustedTP.lang : '', disabled: false}, Validators.required],
      phone: [{value: trustedTP ? trustedTP.phone : '', disabled: false}, [Validators.pattern(/^\d{10}$/)]],
      postalCode: [{value: trustedTP ? trustedTP.postalCode : '', disabled: false}],
      city: [{value: trustedTP ? trustedTP.city : '', disabled: false}],
      address: [{value: trustedTP ? trustedTP.address : '', disabled: false}],
      country: [{value: trustedTP ? trustedTP.country : '', disabled: false}],
    });
  }

  public saveTrustedTP1(): void {
    if (this.trustedTP1FormGroup.valid) {
      if (this.trustedTP1FormGroup.getRawValue().email != this.currentEmail) {

      const payload = this.trustedTP1FormGroup.getRawValue();
      payload.isSecondTrustedPartner = false;
      this.trustedTpService.saveTrustedTP(payload).subscribe(
        (result) => {
          this.getTrustedTP();
          if (this.trustedTP1 && (this.trustedTP1.roleIsAccepted == null || this.trustedTP1.roleIsAccepted == false)) {
            this.content =  'notifications.alert.you_have_received_request_to_be_trusted_third_party_from';
            const msg = this.translate.get('toastr.info.your_first_trusted_third_party_has_been_created');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            this.notificationService.addAlertes({user: result.data.uuid, content: this.content,
              trustedTp: result.data.uuid, params: {currentUser: this.currentUser}}).subscribe(
              (data) => {
                this.notificationIdtp =  data.data.uuid;
                this.userAssociedIdtp = result.data.uuid;
              });
          } else if (this.trustedTP1 && this.trustedTP1.roleIsAccepted == true) {
            const msg = this.translate.get('toastr.info.your_first_trusted_third_party_has_been_updated');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
          }
        },
        (error) => {
          this.toastr.error(error.error.message);
        }
      ); }
      else{
        const msg = this.translate.get('toastr.error.you_cant_add_yourself_as_trustedtp');
        msg.subscribe(value => {
          this.toastr.error(value);
        });
      }
    }else{
      ( Object as any).values(this.trustedTP1FormGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  public saveTrustedTP2(): void {
    if (this.trustedTP2FormGroup.valid) {
      if (this.trustedTP1FormGroup.getRawValue().email != this.trustedTP2FormGroup.getRawValue().email ){
        if (this.trustedTP2FormGroup.getRawValue().email != this.currentEmail){
          const payload = this.trustedTP2FormGroup.getRawValue();
          payload.isSecondTrustedPartner = true;
          this.trustedTpService.saveTrustedTP(payload).subscribe(
            (result) => {
              this.getTrustedTP();
              if (this.trustedTP2 && (this.trustedTP2.roleIsAccepted == null || this.trustedTP2.roleIsAccepted == false)) {
                const msg = this.translate.get('toastr.info.your_second_trusted_third_party_has_been_created');
                msg.subscribe(value => {
                  this.toastr.info(value);
                });
                this.content =  'notifications.alert.you_have_received_request_to_be_trusted_third_party_from';
                this.notificationService.addAlertes({user: result.data.uuid, content: this.content,
                  trustedTp: result.data.uuid, params: {currentUser: this.currentUser}}).subscribe(
                  (data) => {
                    this.notificationIdtp2 =  data.data.uuid;
                    this.userAssociedIdtp2 = result.data.uuid;
                  });
              } else if (this.trustedTP2 && this.trustedTP2.roleIsAccepted == true) {
                const msg = this.translate.get('toastr.info.your_second_trusted_third_party_has_been_updated');
                msg.subscribe(value => {
                  this.toastr.info(value);
                });
              }

            },
            (error) => {
              this.toastr.error(error.error.message);
            }
          );
        } else {
          const msg = this.translate.get('toastr.error.you_cant_add_yourself_as_trustedtp');
          msg.subscribe(value => {
            this.toastr.error(value);
          });
        }
    }else {
        const msg = this.translate.get('toastr.error.you_have_already_added_this_trusted_third_party');
        msg.subscribe(value => {
          this.toastr.error(value);
        });
      }
  }else{
      ( Object as any).values(this.trustedTP2FormGroup.controls).forEach(control => {
        control.markAsDirty();
      });
    }
  }

  getTrustedTP(): void{
    this.trustedTpService.getTrustedTP().subscribe(
      (result: any) => {
        this.trustedTP1id = result.data.TP1.id;
        this.trustedTP1 = result.data.TP1 as ITrustedTP;
        console.log(this.trustedTP1);
        this.trustedTP2 = result.data.TP2 as ITrustedTP;

        console.log(this.trustedTP2);

        this.trustedTP2id = result.data.TP2.id;
        this.trustedTP1 && this.trustedTP1.email ? this.removeTP1btn = true : this.removeTP1btn = false;
        this.trustedTP2 && this.trustedTP2.email ? this.removeTP2btn = true : this.removeTP2btn = false;
        this.trustedTP1url = `${window.location.origin}/auth/sign-up/` + this.trustedTP1.trustedTPUser;
        this.trustedTP2url = `${window.location.origin}/auth/sign-up/` + this.trustedTP2.trustedTPUser;
        this.initFormTrustedTP1(this.trustedTP1);
        this.initFormTrustedTP2(this.trustedTP2);
      },
      (error) => {
        console.log('erreur : ' + error);
      }
    );
  }

  removeTrustedTP1(): void{
    const dialogRef = this.dialog.open(TrustedTpRemoveComponent , {
      data: {
        mode: 'tp1',
        id: this.trustedTP1.user,
        currentUser: this.currentUser,
        trustedTP1: this.trustedTP1,
        notificationIdtp : this.notificationIdtp
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ( result === 'removeTP1'){
        this.getTrustedTP();
      }
    });
  }

  removeTrustedTP2(): void{
    const dialogRef = this.dialog.open(TrustedTpRemoveComponent , {
      data: {
        mode: 'tp2',
        currentUser: this.currentUser,
        trustedTP2: this.trustedTP2,
        id: this.trustedTP2.user,
        notificationIdtp2 : this.notificationIdtp2
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ( result === 'removeTP2'){
        this.getTrustedTP();
      }
    });
  }

  RecoveryRequestAccepted(data, dataTp2 ): void {
    if (dataTp2 == null){
      this.trustedTpService.setHaveRecoveryRequest({id: data, idTp2: 'null', recoveryRequestAcceptedByUser: true})
      .pipe(
        switchMap( () =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: this.currentUuid,
            params: {}})
        ),
        switchMap(() => {
          this.content = 'notifications.info.you_have_confirmed_your_agreement_to_make_your_account_list_available_to_your_trusted_third_party';
          return this.notificationService.addNotifications({
            user: this.currentUuid,
            content: this.content,
            trustedTp: null,
            params: {}
          });
        }),
        switchMap((res) => {
          const msg = this.translate.get('toastr.info.accepted_data_recovery_request');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          this.getTrustedTP();
          return res;
        })
      ).subscribe(
      (result: any) => {
      },
      (error) => {
        console.log('erreur : ' + error);
      });
    }
    else{
      this.trustedTpService.setHaveRecoveryRequest({id: data, idTp2: dataTp2, recoveryRequestAcceptedByUser: true})
        .pipe(
          switchMap( () =>
            this.notificationService.updateAlerte({
              id: null,
              isReaded: true,
              trustedTp: null,
              user: this.currentUuid,
              params: {}})
          ),
          switchMap(() => {
            this.content = 'notifications.info.you_have_confirmed_your_agreement_to_make_your_account_list_available_to_your_trusted_third_party';
            return this.notificationService.addNotifications({
              user: this.currentUuid,
              content: this.content,
              trustedTp: null,
              params: {}
            });
          }),
          switchMap((res) => {
            this.getTrustedTP();
            const msg = this.translate.get('toastr.info.accepted_data_recovery_request');
            msg.subscribe(value => {
              this.toastr.info(value);
            });
            return res;
          })
        )
        .subscribe(
          (result: any) => {
          },
          (error) => {
            console.log('erreur : ' + error);
          }
        );
    }
  }

  RecoveryRequestRefused(data, dataTp2): void {
    if (dataTp2.length == 0) {
      this.trustedTpService.RecoveryRequestRefused(data).pipe(
        switchMap(() => {
          this.getTrustedTP();
          const msg = this.translate.get('toastr.info.rejected_data_recovery_request');
          msg.subscribe(value => {
            this.toastr.info(value);
          });

          this.content = 'notifications.info.your_relative_refused_request_to_make_list_accounts_available';
          return this.notificationService.addNotifications({
            user: data.trustedTPUser,
            content: this.content,
            trustedTp: null,
            params: {forUser: this.currentUser}
          });
        }),
        switchMap(() => {
            this.content = 'notifications.info.you_are_refused_request_to_make_your_list_accounts_available';
            return this.notificationService.addNotifications({
              trustedTp: null,
              user: this.currentUuid,
              content: this.content,
              params: {}
            });
        }),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: this.currentUuid,
            params: {}
          })
        ),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: data.trustedTPUser,
            params: {}
          })
        )
      )
      .subscribe(
        (result: any) => {
        },
        (error) => {
          console.log('erreur : ' + error);
        }
      );
    }
  else {
      this.trustedTpService.RecoveryRequestRefused(data).pipe(
        switchMap(() => {
          this.getTrustedTP();
          const msg = this.translate.get('toastr.info.rejected_data_recovery_request');
          msg.subscribe(value => {
            this.toastr.info(value);
          });
          this.content = 'notifications.info.your_relative_refused_request_to_make_list_accounts_available';

          return this.notificationService.addNotifications({
            user: data.trustedTPUser,
            content: this.content,
            trustedTp: null,
            params: {forUser: this.currentUser}
          });
        }),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: data.trustedTPUser,
            params: {}
          })
        ),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: dataTp2.trustedTPUser,
            params: {}
          })
        ),
        switchMap(() => {
          this.getTrustedTP();
          this.content = 'notifications.info.your_relative_refused_request_to_make_list_accounts_available';

          return this.notificationService.addNotifications({
            user: dataTp2.trustedTPUser,
            content: this.content,
            trustedTp: null,
            params: {forUser: this.currentUser}
          });
        }),
        switchMap(() =>
          this.notificationService.updateAlerte({
            id: null,
            isReaded: true,
            trustedTp: null,
            user: this.currentUuid,
            params: {}
          })
        ),
        switchMap(() => {
          this.content = 'notifications.info.you_are_refused_request_to_make_your_list_accounts_available';
          return this.notificationService.addNotifications({
            trustedTp: null,
            user: this.currentUuid,
            content: this.content,
            params: {}
          });
        })
      )
      .subscribe(
        (result: any) => {
        },
        (error) => {
          console.log('erreur : ' + error);
        }
      );
    }
  }
}
