<div class="container">
    <div class="header">
      <div class="title">{{"data_personal.title" | translate}}</div>
      <div class="btn-close">
        <button (click)="closeModale()">
          <svg-icon src="assets/svg/closeCookies.svg" [svgStyle]="{ 'height.px':30, 'width.px':30 }"></svg-icon>
        </button>
      </div>
    </div>
    <div class="content"> <br>
        Effective le 26 aout 2021<br/><br/>
        Ananke SAS, domicilié 293 boulevard St Denis à 92400 Courbevoie France développe et commercialise la solution 3wlife.<br/><br/>

        <b>Le présent document défini les engagements d’Ananke en matière de données personnelles auprès de ses clients ou des utilisateurs de la plateforme 3wllife et vous informe de la façon dont ces données sont traités par Ananke et ses éventuels sous-traitants et partenaires.
            Délégué à la protection des données :</b> <br>
            Ananke a désigné Christophe KORFER, CEO de Ananke <br>
Email : dpo@ananke.global<br>
<b>Pourquoi 3wlife traite vos données ?</b>
3wlife ne traite les données personnelles que pour des finalités interne, explicites et légitimes.
<b>3wlife traite vos données dans le cadre de l’exécution du contrat que vous avez souscrit
A cet effet, les finalités poursuivies par 3wlife sont les suivantes :</b> <br>
<ul>
    <li>Administrer l'identité du client ou de l’utilisateur et l'authentifie</li>
    <li>Tenir à jour les données personnelles nécessaire au suivi et à la bonne exécution du contrat de service</li>
    <li>Facturer et encaisser les paiements</li>
    <li>Assurer le support technique au cours de l’utilisation du service par les utilisateurs du service.</li>
    <li>Traiter les résiliations</li>
    <li>Traiter les réclamations</li>
    <li>Communication aux utilisateurs dans le cadre de la gestion de la plate-forme</li>
    <li>Gérer les e-mails, et toutes les demandes des utilisateurs du service et leurs ayant droits </li>
    <li>Stocker des données des clients et des utilisateurs</li>
</ul>
Vos données sont conservées pour la durée nécessaire à l’accomplissement des finalités mentionnées ci-dessus.
S’agissant des traitements relatifs à l’exécution du contrat,
les données peuvent être conservées au maximum pour une durée de deux années à compter de la fin de la relation.
<br/>
<b>3wlife réalise également des traitements de données pour d’autres finalités que la stricte exécution de contrats.
    Dans ce cas, 3wlife s’assure d’avoir un intérêt légitime à réaliser le traitement.</b> <br>
    <b>A cet effet, les finalités poursuivies par 3wlife sont les suivantes :</b>
    <ul>
        <li>Planifier et organiser des opérations de marketing direct.</li>
        <li>Analyser les usages du service pour faire des recommandations aux utilisateurs </li>
        <li>Améliorer notre service</li>
        <li>Maintenir la sécurité des plateformes et services</li>
        <li>Contrôler le dispositif de facturation</li>
        <li>Eviter la fraude</li>
    </ul>
    Vos données sont conservées pour la durée nécessaire à l’accomplissement des finalités mentionnées ci-dessus. <br>
    <b>3wlife traite également vos données pour répondre à ses obligations légales ou règlementaires</b> <br>
    A cet effet, les finalités poursuivies par Orange sont les suivantes :
    <ul>
        <li>Répondre aux réquisitions des autorités et aux décisions de justice</li>
        <li>Répondre au droit de communication des autorités</li>
    </ul>
    Vos données peuvent être conservées le temps nécessaire pour permettre à 3wlife de répondre à ses obligations légales. <br>
    <b>3wlife est également susceptible de réaliser des traitements de vos données à des fins statistiques, l’ensemble des données est préalablement rendu anonyme.
      <br>  Quelles sont les données traitées ?</b>
      Selon le cas, 3wlife traite vos données personnelles, directement collectées auprès de vous <br>

      3wlife traite les catégories de données suivantes :
      <ul>
          <li>Données d’identification : Nom, prénom, identifiant</li>
          <li>Données de contact : adresse postale, email</li>
          <li>Type de compte utilisé</li>
          <li>Données de connexion</li>
      </ul>
        <b>Quels sont les destinataires de vos données ?</b> <br>
        Les données collectées sont destinées aux services internes de 3wlife en vue d’optimisation du service et les partenaires de 3wlife <br>
        <b>Vos données sont-elles traitées hors UE ?</b> <br>
        Les données collectées sont hébergées et traités dans l’union Européenne.
         Dans le cas où elles seraient susceptibles d’être traitées hors de l’Union Européenne,
        3wlife prendra les dispositions nécessaires avec ses sous-traitants et partenaires afin de garantir un niveau de protection de vos données adéquates et ce en toute conformité avec la règlementation applicable. <br>
    <br> <b>Quels sont vos droits ?</b> <br>
    Vous disposez d’un droit d’accès, de rectification et de suppression des données qui vous concernent. Vous pouvez demander la portabilité de ces dernières. Vous avez également le droit de vous opposer aux traitements réalisés ou d’en demander la limitation.
    <br>
    <b>Comment exercer vos droits ?</b><br>
    Vous pouvez exercer vos droits à tout moment, ainsi que contacter le Délégué à la Protection des Données personnelles par email ou par courrier à l’adresse ci-dessous
    <br>Ananke <br>
    Délégué à la protection des données : <br>
    Christophe KORFER, <br>
    293 boulevard Saint Denis <br>
    92400 Courbevoie <br>
    France <br>
    Email : dpo@ananke.global <br> <br>
    <b>Possibilité de saisir la CNIL</b><br>
    Si vous considérer que 3wlife n’a pas satisfait à vos demandes, vous avez la possibilité d’introduire une réclamation auprès de la CNIL. Par courrier postal en écrivant à : CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07. <br>
    <b>Comment vos données sont-elles sécurisées ?</b><br>
    3wlife s’assure que vos données sont traitées en toute sécurité et confidentialité, et cela dans le respect du meilleur état de l’art. A cet effet les mesures techniques et organisationnelles appropriées pour éviter la perte, la mauvaise utilisation, l’altération et la suppression des données personnelles vous concernant sont mises en place. Ces mesures sont adaptées selon le niveau de sensibilité des données traitées et selon le niveau de risque que présente le traitement ou sa mise en œuvre.
    </div>
  </div>
